import React from "react"
import styles from "./ProSettings.module.css"
import { SettingsCard } from "ui/SettingsCard"
import { routes } from "router/routes"

export const settingsMenuList = [
  {
    name: "Profile Information",
    id: "profile-information",
    iconName: "user",
    path: routes.proSettingsProfileInformation()
  },
  {
    name: "Login & Security",
    id: "login-security",
    iconName: "password",
    path: routes.proSettingsLoginAndSecurity()
  },
  {
    name: "Mode Preferences",
    id: "mode-preferences",
    iconName: "brush",
    path: routes.proSettingsAdventureMode()
  },
  {
    name: "Stripe Account",
    id: "stripe-account",
    iconName: "card",
    path: routes.proSettingsStripeAccount()
  },
  {
    name: "Social Networks",
    id: "social-networks",
    iconName: "link",
    path: "",
    comingSoon: true
  },
  {
    name: "Licenses",
    id: "licenses",
    iconName: "license",
    path: routes.proSettingsLicenses()
  },
  {
    name: "Billing",
    id: "billing",
    iconName: "investment",
    path: "",
    comingSoon: true
  }
]
export const ProSettings = () => {
  return (
    <div className={styles.wrapper}>
      {settingsMenuList.map((menu) => (
        <SettingsCard key={menu.id} title={menu.name} iconName={menu.iconName} path={menu.path} comingSoon={menu.comingSoon} />
      ))}
    </div>
  )
}
