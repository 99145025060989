import React from "react"
import PropTypes from "prop-types"
import { MediaPreview } from "ui/MediaPreview"
import { Row, Col } from "antd"

// styles
import styles from "./Gallery.module.css"

const renderMedia = (media, index, onImageClick) => {
  return <MediaPreview media={media} onClick={() => onImageClick?.(index)} />
}

const Gallery = ({ mediaItems = [], onImageClick }) => {
  if (mediaItems.length === 0) return null

  const gutter = [0, 0]

  if (mediaItems.length < 5) {
    return (
      <Row gutter={gutter}>
        {mediaItems.map((media, index) => (
          <Col key={index} span={24 / mediaItems.length}>
            {renderMedia(media, index, onImageClick)}
          </Col>
        ))}
      </Row>
    )
  }

  const renderGrid = (startIndex) => (
    <>
      <Col className={styles.heroImage} span={12}>
        {renderMedia(mediaItems[startIndex], startIndex, onImageClick)}
      </Col>
      {mediaItems[startIndex + 1] && (
        <Col span={12}>
          <Row gutter={gutter}>
            {Array.from({ length: 4 }).map(
              (_, i) =>
                mediaItems[startIndex + i + 1] && (
                  <Col key={i} span={12} className={styles.thumbnailImage}>
                    {renderMedia(mediaItems[startIndex + i + 1], startIndex + i + 1, onImageClick)}
                  </Col>
                )
            )}
          </Row>
        </Col>
      )}
      {mediaItems[startIndex + 5] && (
        <Col className={styles.heroImage} span={12}>
          {renderMedia(mediaItems[startIndex + 5], startIndex + 5, onImageClick)}
        </Col>
      )}
      {mediaItems[startIndex + 6] && (
        <Col className={styles.heroImage} span={12}>
          {renderMedia(mediaItems[startIndex + 6], startIndex + 6, onImageClick)}
        </Col>
      )}
      {Array.from({ length: 4 }).map(
        (_, i) =>
          mediaItems[startIndex + 7 + i] && (
            <Col key={i} span={6} className={styles.thumbnailImage}>
              {renderMedia(mediaItems[startIndex + 7 + i], startIndex + 7 + i, onImageClick)}
            </Col>
          )
      )}
    </>
  )

  return (
    <Row gutter={gutter}>
      {Array.from({ length: Math.ceil(mediaItems.length / 12) }).map((_, i) => (
        <React.Fragment key={i}>{renderGrid(i * 12)}</React.Fragment>
      ))}
    </Row>
  )
}

Gallery.propTypes = {
  mediaItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      placeholder_url: PropTypes.string
    })
  ).isRequired,
  onImageClick: PropTypes.func
}

export default Gallery
