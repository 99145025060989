import { notification as notificationAntd } from "antd"
import { Check, X, AlertCircle } from "react-feather"

// themes
import { colors, corners } from "themes/variables"
// ui
import { Text } from "ui/Typography"

const defaultProps = {
  placement: "bottom",
  closeIcon: <X size={24} color="white" />,
  style: {
    color: "white",
    borderRadius: corners.M
  },
  duration: 2,
  message: (message) => (
    <Text fw={400} color="white">
      {message}
    </Text>
  )
}
export const notification = {
  success: (props) => {
    notificationAntd.success({
      ...props,
      ...defaultProps,
      icon: <Check size={24} color="white" />,
      style: {
        ...defaultProps.style,
        backgroundColor: colors.success
      },
      message: defaultProps.message(props.message)
    })
  },
  error: (props) => {
    notificationAntd.error({
      ...props,
      ...defaultProps,
      icon: <AlertCircle size={24} color="white" className="my-auto" />,
      style: {
        ...defaultProps.style,
        backgroundColor: colors.error
      },
      message: defaultProps.message(props.message)
    })
  },
  info: notificationAntd
}
