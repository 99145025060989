import React from "react"
import { Form, Row, Col } from "antd"
import { CustomInput } from "ui/Input"
import { CustomForm } from "ui/Form"
import { Text } from "ui/Typography"
import { useTranslation } from "react-i18next"
import { Icon } from "ui/Icon"

export const CustomAddress = ({ backToSearch, form, initialValues }) => {
  const { t } = useTranslation()
  const requiredErrorMessage = t("form_validation.cannot_be_blank")

  return (
    <>
      <Text className="align-center mb-l" variant="link" onClick={backToSearch}>
        <Icon type="feather" name="ArrowLeft" className="mr-xs" width={16} height={16} /> {t("pro.addresses.back_to_search")}
      </Text>
      <CustomForm form={form} layout="vertical" initialValues={initialValues}>
        <Form.Item
          label={t("pro.addresses.street_address")}
          name="street_address"
          required={true}
          rules={[{ required: true, message: requiredErrorMessage }]}
        >
          <CustomInput />
        </Form.Item>

        <Row gutter={16}>
          <Col md={8} sm={24} xs={24}>
            <Form.Item
              label={t("pro.addresses.city")}
              name="city"
              required={true}
              rules={[{ required: true, message: requiredErrorMessage }]}
            >
              <CustomInput />
            </Form.Item>
          </Col>

          <Col md={8} sm={24} xs={24}>
            <Form.Item
              label={t("pro.addresses.state")}
              name="state"
              required={true}
              rules={[{ required: true, message: requiredErrorMessage }]}
            >
              <CustomInput />
            </Form.Item>
          </Col>

          <Col md={8} sm={24} xs={24}>
            <Form.Item
              label={t("pro.addresses.zip")}
              name="zip_code"
              required={true}
              rules={[{ required: true, message: requiredErrorMessage }]}
            >
              <CustomInput />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={t("pro.addresses.country")}
          name="country"
          required={true}
          rules={[{ required: true, message: requiredErrorMessage }]}
        >
          <CustomInput />
        </Form.Item>
      </CustomForm>
    </>
  )
}
