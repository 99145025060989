import { Button } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { isStripeConnectedSelector, stripeConnect, stripeDisconnect } from "store/user"

export const StripeActionButtons = ({ flow_type }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isStripeConnected = useSelector(isStripeConnectedSelector)
  const connectStripeAccount = () => dispatch((dispatch) => stripeConnect(dispatch, { flow_type }))
  const disConnectStripeAccount = () => dispatch(stripeDisconnect())

  return (
    <>
      {isStripeConnected ? (
        <Button className="pr-l pl-l" color="primary" variant="solid" size="large" onClick={disConnectStripeAccount}>
          {t("global.disconnect")}
        </Button>
      ) : (
        <Button className="pr-l pl-l" color="primary" variant="solid" size="large" onClick={connectStripeAccount}>
          {t("pro.personal_info.stripe.connect")}
        </Button>
      )}
    </>
  )
}
