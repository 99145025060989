import React from "react"

export const Accomodation = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 0C9.62625 0 7.68 1.94625 7.68 4.32C7.68 6.69375 9.62625 8.64 12 8.64C14.3737 8.64 16.32 6.69375 16.32 4.32C16.32 1.94625 14.3737 0 12 0ZM12 0.96C13.8506 0.96 15.36 2.46937 15.36 4.32C15.36 6.17063 13.8506 7.68 12 7.68C10.1494 7.68 8.64 6.17063 8.64 4.32C8.64 2.46937 10.1494 0.96 12 0.96ZM12 2.31C11.6812 2.31 11.43 2.56125 11.43 2.88C11.43 3.19875 11.6812 3.45 12 3.45C12.3188 3.45 12.57 3.19875 12.57 2.88C12.57 2.56125 12.3188 2.31 12 2.31ZM11.52 3.99V6.24H12.48V3.99H11.52ZM1.68 10.56C0.7575 10.56 0 11.3175 0 12.24V24H3.36V22.08H20.64V24H24V16.56C24 15.6375 23.2425 14.88 22.32 14.88C22.0575 14.88 21.8212 14.9513 21.6 15.06V14.88C21.6 14.0906 20.9494 13.44 20.16 13.44H10.56C9.77063 13.44 9.12 14.0906 9.12 14.88V18.72H3.36V12.24C3.36 11.3175 2.6025 10.56 1.68 10.56ZM1.68 11.52C2.1 11.52 2.4 11.82 2.4 12.24V19.68H21.6V16.56C21.6 16.14 21.9 15.84 22.32 15.84C22.74 15.84 23.04 16.14 23.04 16.56V23.04H21.6V21.12H2.4V23.04H0.96V12.24C0.96 11.82 1.26 11.52 1.68 11.52ZM6.24 13.44C4.92 13.44 3.84 14.52 3.84 15.84C3.84 17.16 4.92 18.24 6.24 18.24C7.56 18.24 8.64 17.16 8.64 15.84C8.64 14.52 7.56 13.44 6.24 13.44ZM6.24 14.4C7.04063 14.4 7.68 15.0394 7.68 15.84C7.68 16.6406 7.04063 17.28 6.24 17.28C5.43937 17.28 4.8 16.6406 4.8 15.84C4.8 15.0394 5.43937 14.4 6.24 14.4ZM10.56 14.4H20.16C20.4263 14.4 20.64 14.6137 20.64 14.88V18.72H10.08V14.88C10.08 14.6137 10.2937 14.4 10.56 14.4Z"
      fill={fill}
    />
  </svg>
)
