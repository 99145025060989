import React, { useEffect } from "react"
import { Link, Outlet, useMatch } from "react-router-dom"
import { Col, Row } from "reactstrap"
import { oldRoutes } from "router/old-routes"
import Header from "components/marketing/Header"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import Template from "components/marketing/Template"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { isLastPage } from "helpers/pagination"
import { useTitle } from "hooks/useTitle"
import { Waypoint } from "react-waypoint"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanTemplates, getGuideTemplates } from "store/templates"
import { collectionAnySelector, collectionSelector, metadataSelector } from "store/selectors"
import WithEmptyState from "components/common/WithEmptyState"

export default function TemplatePage() {
  useTitle("Templates")

  const isTemplatesPage = !!useMatch(oldRoutes.guideMarketingTemplatesPath())
  const metadata = useSelector(metadataSelector("templates"))
  const templates = useSelector(collectionSelector("templates"))
  const hasTemplates = useSelector(collectionAnySelector("templates"))
  const dispatch = useDispatch()

  const fetchTemplates = (nextPage = false) => dispatch(getGuideTemplates(nextPage))

  useEffect(() => {
    fetchTemplates()
    return () => dispatch(cleanTemplates())
  }, []) // eslint-disable-line

  return (
    <>
      {isTemplatesPage && (
        <>
          <Header />
          <FirstLoading name="templates">
            <div className="gap-y-30 gx-30 d-flex">
              <WithEmptyState name="template" hasItems={hasTemplates} tag={false}>
                {
                  <div className="px-20 py-20">
                    <Row className="gap-y-30">
                      {templates.map((template, index) => (
                        <Col xs={12} sm={6} md={4} lg={3} key={index}>
                          {template.category === "automated" ? (
                            <Template template={template} className="h-100" />
                          ) : (
                            <Link
                              className="link link-dark vstack h-100"
                              to={oldRoutes.guideMarketingTemplateEditPath({ id: template.id })}
                            >
                              <Template template={template} className="h-100" />
                            </Link>
                          )}
                        </Col>
                      ))}
                      {!isLastPage(metadata) && <Waypoint onEnter={() => fetchTemplates(true)} />}
                    </Row>
                  </div>
                }
              </WithEmptyState>
            </div>
          </FirstLoading>
        </>
      )}
      <DefaultErrorBoundary>
        <FirstLoading name="templates" className={isTemplatesPage && "d-none"}>
          <Outlet />
        </FirstLoading>
      </DefaultErrorBoundary>
    </>
  )
}
