export const getQueryParamRemove = (param) => {
  const href = window.location.href
  const queryString = href.split("?")[1]

  let paramValue = null

  if (queryString) {
    const urlParams = new URLSearchParams(queryString)
    paramValue = urlParams.get(param)
    if (paramValue) {
      urlParams.delete(param)
      const urlParamsString = urlParams.toString()
      let newHash = href.split("?")[0]
      if (urlParamsString) {
        newHash += "?" + urlParamsString
      }
      window.history.replaceState(null, "", newHash)
    }
  }

  return paramValue
}
