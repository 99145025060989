import React from "react"
import AppleSignInButton from "react-apple-signin-auth"
import { useDispatch, useSelector } from "react-redux"
import { isProApp } from "utils/app-type"
import { LinkedAccount } from "./LinkedAccount"
import appleIcon from "assets/imagesV2/social/apple-black.svg"
import { connectOAuthProvider } from "store/user"

const provider = "apple"

export const AppleConnect = () => {
  const dispatch = useDispatch()
  const { connected_oauth_providers } = useSelector((state) => state.user)
  const connected = connected_oauth_providers?.includes(provider)

  const onSuccess = (response) => {
    const code = response.authorization?.id_token
    if (code) {
      dispatch(connectOAuthProvider({ provider, code }))
    }
  }

  const onFail = (response) => {
    // implement error handling
    console.error(response)
  }

  return (
    <AppleSignInButton
      authOptions={{
        clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
        scope: "email name",
        redirectURI: isProApp() ? "https://" + process.env.REACT_APP_PRO_HOST : process.env.REACT_APP_APPLE_REDIRECT_URL,
        state: "state",
        nonce: "nonce",
        usePopup: true
      }}
      onSuccess={onSuccess}
      onError={onFail}
      render={({ onClick }) => {
        return <LinkedAccount icon={appleIcon} account="Apple" provider={provider} connectOAuth={onClick} connected={connected} />
      }}
    />
  )
}
