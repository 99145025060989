import React, { memo } from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"
import Logo from "components/common/Logo"
import PhotoSlider from "components/common/PhotoSlider"
import { useSelector } from "react-redux"

const Template = ({ template, className, style, ...rest }) => {
  const { name, heading, button_text, button_url, category, paragraph_text, signature, images, template_type } = template

  const centeredText = ["automated", "small_gallery", "large_gallery"].includes(category)
    ? "text-center mx-auto"
    : "text-start ms-0 me-auto"
  const withPhoto = category === "large_gallery" || category === "small_gallery" || category === "automated"
  const isEmail = template_type === "email"
  const isSecondBasic = category === "second_basic"
  const isAutomated = category === "automated"
  const isSportsman = useSelector((state) => state.user.is_sportsman)

  const paragraphTextSliced = paragraph_text.slice(0, withPhoto ? 160 : 520)

  const classes = ["vstack rounded overflow-hidden shadow-lg position-relative w-100"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")} style={style} {...rest}>
      <div className={["flex-fill p-20 pb-0 fs-7", !isEmail ? "sms-text" : ""].join(" ")}>
        {isEmail &&
          (isSportsman ? (
            <Logo className="d-block mx-auto mb-10" alt="Logo" width={30} height={30} wide />
          ) : (
            <Logo className="d-block mx-auto mb-10" alt="Logo" width={30} height={30} is_sportswoman wide />
          ))}
        {heading && <h5 className={`mt-15 mb-10 ${centeredText}`}>{heading}</h5>}
        {!isAutomated && <p className={`my-10 ${centeredText}`}>{"Hello [Client Name]"}</p>}
        <div className={`my-15 ${centeredText}`}>
          <p className="m-0">
            {paragraphTextSliced}
            {paragraphTextSliced.length < paragraph_text.length && "..."}
          </p>
          {isSecondBasic && isEmail && (
            <Button type="button" color="dark" className="fs-7 px-25 w-auto mt-15" size="sm">
              {button_text}
            </Button>
          )}
        </div>
        {withPhoto && isEmail && (
          <div className="flex-shrink-0 mt-15 mx-n20" style={{ flexBasis: 200, flexGrow: 1 }}>
            <PhotoSlider photos={images} preview className="w-100 " />
          </div>
        )}
        {isAutomated ? (
          <h5 className="text-center p-10 mt-auto">{"Did you enjoy youre experience?"}</h5>
        ) : (
          !withPhoto && (
            <div className="mt-auto mb-15">
              {!isEmail && (
                <>
                  <Button color="link" className="fs-7 mt-15 link w-auto" block type="button">
                    {button_url}
                  </Button>
                  <br />
                </>
              )}
              <div>{signature}</div>
              <div className="fw-bold fst-italic">{"[Guide profile name]"}</div>
            </div>
          )
        )}
      </div>
      <div className={["bg-dark text-white text-center px-20 lh-1", isAutomated ? "py-3" : "py-20"].join(" ")}>
        <span className="fw-medium">{name}</span>
        {isAutomated && (
          <>
            <br />
            <small className="fs-7">(automated)</small>
          </>
        )}
      </div>
    </div>
  )
}

Template.propTypes = {
  template: PropTypes.shape({
    name: PropTypes.string.isRequired,
    heading: PropTypes.string,
    button_text: PropTypes.string,
    button_url: PropTypes.string,
    category: PropTypes.string.isRequired,
    paragraph_text: PropTypes.string.isRequired,
    signature: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string),
    template_type: PropTypes.string.isRequired
  }).isRequired,
  className: PropTypes.string,
  style: PropTypes.object
}

export default memo(Template)
