import React, { useEffect, useState } from "react"
import { useMatch } from "react-router-dom"
import PropTypes from "prop-types"
import { oldRoutes } from "router/old-routes"
import { Button, Container } from "reactstrap"
import NavigationLink from "./NavigationLink"
import Sort from "components/trips/filters/Sort"
import Price from "components/trips/filters/Price"
import Guests from "components/trips/filters/Guests"
import SkillLevel from "components/trips/filters/SkillLevel"
import Ecosystem from "components/trips/filters/Ecosystem"
// import BoatDetails from "components/trips/filters/BoatDetails"
// import BoatSafeties from "components/trips/filters/BoatSafeties"

import { useTranslation } from "react-i18next"
import { useScrollToActive } from "hooks/useScrollToActive"
import { scrollToActive } from "helpers/DOM"

import { useDispatch, useSelector } from "react-redux"
import { getFilterOptions } from "store/trips"
import { firstLoadingSelector } from "modules/loader-watchers/selectors"

TripFiltersNavigation.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  fluid: PropTypes.bool
}
export default function TripFiltersNavigation({ className, visible = false, fluid = true }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigationRef = useScrollToActive()
  const isTripsPage = useMatch(oldRoutes.tripsPath())
  const [isScrolled, setIsScrolled] = useState(false)
  const filterOptionsFirstLoading = useSelector(firstLoadingSelector("trips.filter_options"))

  const classes = ["bg-white shadow"]
  if (className) classes.push(className)

  const clickHandler = ({ currentTarget }) => scrollToActive(currentTarget)

  const scrollHandler = () => {
    const scrollElement = navigationRef.current
    const { scrollWidth, clientWidth, scrollLeft } = scrollElement
    const diff = scrollWidth - clientWidth
    if (diff && diff - scrollLeft) setIsScrolled(true)
    else setIsScrolled(false)
  }

  useEffect(() => {
    if (filterOptionsFirstLoading) dispatch(getFilterOptions())
  }, [filterOptionsFirstLoading]) //eslint-disable-line

  useEffect(() => {
    const scrollElement = navigationRef.current
    if (!scrollElement) return
    window.addEventListener("load", scrollHandler)
    window.addEventListener("resize", scrollHandler)
    scrollElement.addEventListener("scroll", scrollHandler)
    return () => {
      window.removeEventListener("load", scrollHandler)
      window.removeEventListener("resize", scrollHandler)
      scrollElement.removeEventListener("scroll", scrollHandler)
    }
    //eslint-disable-next-line
  }, [])

  return isTripsPage || visible ? (
    <nav className={classes.join(" ")}>
      <Container fluid={fluid}>
        <div className="full-window-scroll" ref={navigationRef}>
          <div className="flex-fill">
            <div className="hstack gap-20 py-10 py-sm-20">
              <Guests onToggleClick={clickHandler} />
              <NavigationLink tag={Button} iconName="Calendar" label={t("trips.filters.dates")} onClick={clickHandler} disabled />
              <Price onToggleClick={clickHandler} />
              <SkillLevel onToggleClick={clickHandler} />
              <Ecosystem onToggleClick={clickHandler} />
              <NavigationLink tag={Button} iconName="FishingRod" label={t("trips.filters.technique")} onClick={clickHandler} disabled />
              <NavigationLink tag={Button} iconName="Fish" label={t("trips.filters.species")} onClick={clickHandler} disabled />
              <div className="sticky-end ms-auto">
                <div
                  className={[
                    "bg-white px-10 pe-20 my-n15 me-n20 border-gray-lightest transition",
                    ...(isScrolled ? ["border-start scroll-shadow scroll-shadow-start"] : [])
                  ].join(" ")}
                >
                  <Sort />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </nav>
  ) : null
}
