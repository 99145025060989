import { createAction, createReducer } from "@reduxjs/toolkit"
import { api } from "./helpers/api"
import { find, reject, merge } from "lodash"
import { formatPaginationParams } from "helpers/pagination"
import { createdReview } from "./reviews"
import { toSearchParams } from "helpers/form"
import moment from "moment"

import { DATE_FORMAT } from "modules/datepicker/constants"
import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"
import { PAGINATION_INITIAL_STATE } from "./constants"

// ACTIONS
const requested = createAction("bookings/requested")
const received = createAction("bookings/received")
const failed = createAction("bookings/failed")
const cleaned = createAction("bookings/cleaned")

const requestedPast = createAction("bookings/requestedPast")
const receivedPast = createAction("bookings/receivedPast")
const failedPast = createAction("bookings/failedPast")
const cleanedPast = createAction("bookings/cleanedPast")

export const requestedIncomes = createAction("bookings/requestedIncomes")
const receivedIncomes = createAction("bookings/receivedIncomes")
const failedIncomes = createAction("bookings/failedIncomes")
const cleanedIncomes = createAction("bookings/cleanedIncomes")
export const createdIncome = createAction("bookings/createdIncome")

const requestedBooking = createAction("bookings/requestedBooking")
const receivedBooking = createAction("bookings/receivedBooking")
const failedBooking = createAction("bookings/failedBooking")
export const createdBooking = createAction("bookings/createdBooking")
export const updatedBooking = createAction("bookings/updatedBooking")
export const deletedBooking = createAction("bookings/deletedBooking")
const cleanedBooking = createAction("bookings/cleanedBooking")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  items: [],
  booking: {
    ...LOADING_INITIAL_STATE,
    id: null,
    booked_date: "",
    booked_time: "",
    adult_count: 0,
    child_count: 0,
    cash_deposit: 0,
    booking_fee_percentage: 0,
    status: "draft",
    wizard_step: "details",
    wizard_completed: false,
    accessibility_enabled: false,
    accessibility_notes: "",
    notes: [],
    trip: {},
    client: {}
  },
  past_trips: {
    ...LOADING_INITIAL_STATE,
    items: []
  },
  incomes: {
    ...LOADING_INITIAL_STATE,
    items: []
  },
  metadata: {
    ...PAGINATION_INITIAL_STATE,
    start_date: null,
    end_date: null
  }
}

const bookingsReducer = createReducer(initialState, {
  [requested.type]: (state, action) => {
    setRequested(state)
    if (action.props.reset) {
      state.items = []
      state.metadata.start_date = moment().format(DATE_FORMAT)
      state.metadata.end_date = moment().format(DATE_FORMAT)
    }
  },
  [received.type]: (state, action) => {
    setReceived(state)
    state.items = action.props.reset ? action.payload.data : state.items.concat(action.payload.data)
    Object.assign(state.metadata, action.payload.metadata)
  },
  [failed.type]: (state) => {
    setFailed(state)
  },
  [cleaned.type]: () => initialState,
  [requestedBooking.type]: (state) => {
    setRequested(state.booking)
  },
  [receivedBooking.type]: (state, action) => {
    setReceived(state.booking)
    if (action.payload.data) {
      Object.assign(state.booking, action.payload.data)
      Object.assign(state.metadata, action.payload.metadata)
    } else Object.assign(state.booking, action.payload)
  },
  [createdBooking.type]: (state, action) => {
    setReceived(state.booking)
    state.items = [action.payload, ...state.items]
    Object.assign(state.booking, action.payload)
  },
  [updatedBooking.type]: (state, action) => {
    setReceived(state.booking)
    const booking = find(state.items, ["id", action.payload.data?.id || action.payload.id]) || {}
    Object.assign(booking, action.payload.data || action.payload)
    Object.assign(state.booking, action.payload.data || action.payload)
    Object.assign(state.metadata, action.payload.metadata || {})
  },
  [createdReview.type]: (state, action) => {
    if (action.payload.reviewable_type !== "Trip") return
    if (action.payload.reviewable_id === state.booking?.trip?.id) state.booking.with_trip_review = true
    const booking = find(state.items, ["trip.id", action.payload.reviewable_id]) || {}
    booking.with_trip_review = true
  },
  [deletedBooking.type]: (state, action) => {
    setReceived(state.booking)
    state.items = reject(state.items, ["id", action.payload.id])
  },
  [failedBooking.type]: (state) => {
    setFailed(state.booking)
    state.booking.payLoading = false
  },
  [cleanedBooking.type]: (state) => {
    state.booking = initialState.booking
  },
  [requestedPast.type]: (state) => {
    setRequested(state.past_trips)
  },
  [receivedPast.type]: (state, action) => {
    setReceived(state.past_trips)
    state.past_trips.items = action.payload.data
  },
  [failedPast.type]: (state) => {
    setFailed(state.past_trips)
  },
  [cleanedPast.type]: (state) => {
    console.log("cleaned")
    setFailed(state.past_trips)
    state.past_trips = initialState.past_trips
  },
  [requestedIncomes.type]: (state) => {
    setRequested(state.incomes)
  },
  [receivedIncomes.type]: (state, action) => {
    setReceived(state.incomes)
    state.incomes.items = action.payload
  },
  [failedIncomes.type]: (state) => {
    setFailed(state.incomes)
  },
  [cleanedIncomes.type]: (state) => {
    state.incomes = initialState.incomes
  },
  [createdIncome.type]: (state, action) => {
    setReceived(state.incomes)
    if (action.payload?.incomes?.length) state.incomes.items = action.payload.incomes
    const booking = find(state.items, ["id", action.payload?.booking?.id]) || {}
    if (booking) {
      merge(booking, action.payload?.booking)
      merge(state.booking, action.payload?.booking)
    }
  }
})
export default bookingsReducer

const getItems = (url, nextPage, params = {}, dispatch, getState) => {
  const state = getState()
  const paginationParams = toSearchParams(formatPaginationParams(state.bookings.metadata, nextPage))
  const serializedParams = toSearchParams({ ...params, include_unavailable_dates: true }, null, paginationParams)

  return dispatch(
    api({
      url,
      onStart: requested.type,
      onSuccess: received.type,
      onError: failed.type,
      params: serializedParams,
      props: { reset: !nextPage }
    })
  )
}

const getItem = (url, id, dispatch, getState, noCache = false) => {
  const sameIdOrUuid = (item) => +item.id === +id || item.uuid === id
  const state = getState()
  const bookingsBooking = find(state.bookings.items, sameIdOrUuid)
  const dashboardBooking = find(state.dashboard.bookings, sameIdOrUuid)
  const booking = bookingsBooking || dashboardBooking

  if (booking && !noCache) {
    dispatch(receivedBooking(booking))
    return new Promise((resolve) => resolve(booking))
  }
  return dispatch(
    api({
      url,
      onStart: requestedBooking.type,
      onSuccess: receivedBooking.type,
      onError: failedBooking.type,
      params: { include_unavailable_dates: true, include_stripe_cards: true }
    })
  )
}

// PUBLIC ACTIONS

export const getGuideBookings = (params, nextPage) => (dispatch, getState) =>
  getItems("/guide/bookings", nextPage, params, dispatch, getState)

export const getBookings = (nextPage, params) => (dispatch, getState) =>
  getItems("/client/trip_bookings", nextPage, params, dispatch, getState)

export const getGuidePastBookingsForClient = (client_id) =>
  api({
    url: `/guide/bookings/past`,
    params: { client_id },
    onStart: requestedPast.type,
    onSuccess: receivedPast.type,
    onError: failedPast.type
  })

export const getGuideBooking = (id) => (dispatch, getState) => getItem(`/guide/bookings/${id}`, id, dispatch, getState)
export const getBooking = (uuid, noCache) => (dispatch, getState) => getItem(`/trip_bookings/${uuid}`, uuid, dispatch, getState, noCache)

export const createBooking = (tripId, data) =>
  api({
    url: `/trips/${tripId}/trip_bookings`,
    data,
    method: "post",
    onStart: requestedBooking.type,
    onSuccess: createdBooking.type,
    onError: failedBooking.type,
    props: { showDetailedError: true },
    params: { include_unavailable_dates: true, include_stripe_cards: true }
  })

export const createGuideBooking = (data) =>
  api({
    url: `/guide/bookings`,
    data,
    method: "post",
    onStart: requestedBooking.type,
    onSuccess: createdBooking.type,
    onError: failedBooking.type
  })

export const updateBooking = (uuid, data) =>
  api({
    url: `/trip_bookings/${uuid}`,
    method: "put",
    data,
    onStart: requestedBooking.type,
    onSuccess: updatedBooking.type,
    onError: failedBooking.type,
    props: { showDetailedError: true }
  })

export const updateGuideBooking = (id, data) =>
  api({
    url: `/guide/bookings/${id}`,
    method: "put",
    data,
    onStart: requestedBooking.type,
    onSuccess: updatedBooking.type,
    onError: failedBooking.type
  })

export const createClientBookingIncomeRecord = (booking_id) =>
  api({
    url: `/trip_bookings/${booking_id}/payments`,
    method: "post",
    onStart: requestedIncomes.type,
    onSuccess: createdIncome.type,
    onError: failedIncomes.type,
    props: { showDetailedError: true }
  })

export const captureClientBookingIncomeRecord = (booking_id, record_id) =>
  api({
    url: `/trip_bookings/${booking_id}/payments/${record_id}/capture`,
    method: "put",
    onStart: requestedIncomes.type,
    onSuccess: createdIncome.type,
    onError: failedIncomes.type,
    props: { showDetailedError: true }
  })

export const getGuideBookingIncomes = (booking_id) =>
  api({
    url: `/guide/bookings/${booking_id}/payments`,
    onStart: requestedIncomes.type,
    onSuccess: receivedIncomes.type,
    onError: failedIncomes.type,
    props: { showDetailedError: true }
  })

export const getClientBookingIncomes = (booking_id) =>
  api({
    url: `/trip_bookings/${booking_id}/payments`,
    onStart: requestedIncomes.type,
    onSuccess: receivedIncomes.type,
    onError: failedIncomes.type,
    props: { showDetailedError: true }
  })

export const createGuideBookingIncomeRecord = (booking_id, data) =>
  api({
    url: `/guide/bookings/${booking_id}/payments/record`,
    method: "post",
    data,
    onStart: requestedIncomes.type,
    onSuccess: createdIncome.type,
    onError: failedIncomes.type,
    props: { showDetailedError: true }
  })

export const captureGuideBookingIncomeRecord = (booking_id, record_id) =>
  api({
    url: `/guide/bookings/${booking_id}/payments/${record_id}/capture`,
    method: "put",
    onStart: requestedIncomes.type,
    onSuccess: createdIncome.type,
    onError: failedIncomes.type,
    props: { showDetailedError: true }
  })

export const createGuideBookingIncomeRefund = (booking_id, record_id, data) =>
  api({
    url: `/guide/bookings/${booking_id}/payments/${record_id}/refund`,
    method: "post",
    data,
    onStart: requestedIncomes.type,
    onSuccess: createdIncome.type,
    onError: failedIncomes.type,
    props: { showDetailedError: true }
  })

export const saveBooking = (id, data) => (id ? updateBooking(id, data) : createBooking(data))
export const saveGuideBooking = (id, data) => (id ? updateGuideBooking(id, data) : createGuideBooking(data))

export const cancelGuideBooking = (id) =>
  api({
    url: `/guide/bookings/${id}`,
    method: "put",
    data: { status: "canceled" },
    onStart: requestedBooking.type,
    onSuccess: updatedBooking.type,
    onError: failedBooking.type
  })

export const cancelClientBooking = (uuid) =>
  api({
    url: `/trip_bookings/${uuid}/update_status`,
    method: "put",
    data: { status: "canceled" },
    onStart: requestedBooking.type,
    onSuccess: updatedBooking.type,
    onError: failedBooking.type
  })

export const confirmClientBooking = (uuid) =>
  api({
    url: `/trip_bookings/${uuid}/update_status`,
    method: "put",
    data: { status: "booked" },
    onStart: requestedBooking.type,
    onSuccess: updatedBooking.type,
    onError: failedBooking.type
  })

export const deleteClientBooking = (uuid) =>
  api({
    url: `/trip_bookings/${uuid}`,
    method: "delete",
    onStart: requestedBooking.type,
    onSuccess: deletedBooking.type,
    onError: failedBooking.type
  })

export const deleteClientDraftBooking = (uuid) =>
  api({
    url: `/trip_bookings/${uuid}/destroy_draft`,
    method: "delete",
    onStart: requestedBooking.type,
    onSuccess: deletedBooking.type,
    onError: failedBooking.type
  })

export const cleanBookings = () => (dispatch) => dispatch(cleaned())
export const cleanBooking = () => (dispatch) => dispatch(cleanedBooking())
export const cleanBookingIncomes = () => (dispatch) => dispatch(cleanedIncomes())
export const cleanPastBooking = () => (dispatch) => dispatch(cleanedPast())
