import React, { useMemo } from "react"
import { Button, Flex } from "antd"
import { spacings } from "themes/variables"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

export const ContentActionButtons = ({ form, onFinish }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { activeMenu, menuItems } = useSelector((state) => state.guideUserInfo)
  const menuItemsFlat = useMemo(() => menuItems.flatMap((menuItem) => menuItem.items), [menuItems])
  const currentMenuItemIndex = useMemo(() => menuItemsFlat.findIndex((menuItem) => menuItem.id === activeMenu), [menuItemsFlat, activeMenu])

  const isFirstMenuItemActive = menuItemsFlat[0].id === activeMenu
  const isLastMenuItemActive = menuItemsFlat[menuItemsFlat.length - 1].id === activeMenu

  const navigateToNextPage = () => {
    let nextMenuItem
    for (let i = currentMenuItemIndex + 1; i < menuItemsFlat.length; i++) {
      if (menuItemsFlat[i].path) {
        nextMenuItem = menuItemsFlat[i]
        break
      }
    }

    if (nextMenuItem) {
      navigate(nextMenuItem.path)
    }
  }

  const navigateToPreviousPage = () => {
    let previousMenuItem
    for (let i = currentMenuItemIndex - 1; i >= 0; i--) {
      if (menuItemsFlat[i].path) {
        previousMenuItem = menuItemsFlat[i]
        break
      }
    }

    if (previousMenuItem) {
      navigate(previousMenuItem.path)
    }
  }

  const btnClickHandler = (navigateTo) => {
    if (form?.isFieldsTouched()) {
      const values = form.getFieldsValue()
      onFinish(values, navigateTo)
    } else {
      navigateTo()
    }
  }

  const submitBtnClickHandler = () => {}

  return (
    <Flex className="mt-4xl" gap={spacings.S}>
      {!isFirstMenuItemActive && (
        <Button
          className="pr-xl pl-xl"
          color="primary"
          variant="outlined"
          size="large"
          onClick={() => btnClickHandler(navigateToPreviousPage)}
        >
          {t("global.previous")}
        </Button>
      )}
      {isLastMenuItemActive ? (
        <Button className="pr-xl pl-xl" color="primary" variant="solid" size="large" onClick={submitBtnClickHandler}>
          {t("global.submit")}
        </Button>
      ) : (
        <Button className="pr-xl pl-xl" color="primary" variant="solid" size="large" onClick={() => btnClickHandler(navigateToNextPage)}>
          {t("global.next")}
        </Button>
      )}
    </Flex>
  )
}
