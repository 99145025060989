import React, { memo } from "react"
import { useTranslation } from "react-i18next"
import { oldRoutes } from "router/old-routes"
import SidebarStepLink from "components/wizards/StepLink"
import Icon from "components/common/Icon"

import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"

import { useDispatch, useSelector } from "react-redux"
import { signOut } from "store/auth"
import { isGuideCompletedSelector } from "store/user"
import { useNavigate } from "react-router-dom"
import { routes } from "router"

function SidebarNavigation() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const confirmModal = useConfirmModal()
  const wizardCompleted = useSelector(isGuideCompletedSelector)

  const signOutHandler = confirmModal(
    { title: "Are you sure you want to sign out?", color: "danger", submitText: t("global.confirm") },
    () => dispatch(signOut()).then(() => navigate(routes.rootPath()))
  )

  return (
    <div className="flex-shrink-0 d-flex flex-md-column min-h-100" style={{ "--gap": `${40}px`, gap: "var(--gap)" }}>
      <SidebarStepLink stepNumber={1} name="user.guide" label="Choose Your Activities" sublabel="What activities will you be guiding?" />
      <SidebarStepLink stepNumber={2} name="user.guide" label="Your Profile" sublabel="Create your guide profile" />
      <SidebarStepLink stepNumber={3} name="user.guide" label="Photos" sublabel="Add Your Images" />
      {wizardCompleted ? (
        <SidebarStepLink
          to={oldRoutes.guideRootPath()}
          className="mt-auto"
          stepNumber={<Icon iconName="ArrowLeft" width={18} height={10} />}
          label={t("header.home")}
          disabled={false}
          end
        />
      ) : (
        <>
          <SidebarStepLink stepNumber={4} name="user.guide" label="Submit" sublabel="Submit Your Application" />
          <SidebarStepLink
            onClick={signOutHandler}
            className="mt-auto"
            stepNumber={<Icon iconName="ArrowLeft" width={18} height={10} />}
            label={t("header.log_out")}
          />
        </>
      )}
    </div>
  )
}

export default memo(SidebarNavigation)
