import React, { useCallback, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import { Button, DropdownToggle } from "reactstrap"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import Icon from "components/common/Icon"
import DateSwitcher from "modules/datepicker/DateSwitcher"

import { useTranslation } from "react-i18next"

import { api } from "store/helpers/api"
import { useDispatch } from "react-redux"
import { cleanExpenses, getGuideAccounting, getGuideAccountingOverview } from "store/accounting"

import { DEFAULT_RANGE, DEFAULT_TYPE, RANGE_OPTIONS, SCOUP_LIST, TYPE_OPTIONS_ENUM, TYPE_WITH_DATESWITCHER } from "constants/accounting"

export const requestParams = (params) => {
  if (!params.type || !params.date || !params.range) return
  const q = {}
  const range = params.range === "week" ? "weekDay" : params.range
  const date = global.dateTime(params.date)

  q.date_gteq = date.startOf(range, 1).format()
  q.date_lteq = date.endOf(range, 1).format()

  const searchParams = { include_stats: true, q }
  if (SCOUP_LIST.includes(params.type)) searchParams.scope = params.type.toLowerCase()

  return searchParams
}

Header.propTypes = {
  params: PropTypes.shape({
    type: PropTypes.string,
    date: PropTypes.string,
    range: PropTypes.string,
    date_gteq: PropTypes.string,
    date_lteq: PropTypes.string
  }).isRequired,
  onChangeFilters: PropTypes.func.isRequired
}

export default function Header({ params, onChangeFilters }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const showDateSwitcher = TYPE_WITH_DATESWITCHER.includes(params.type)
  const needReload = location.state?.reload || false
  const date = useMemo(() => (params.date ? global.dateTime(params.date) : null), [params.date])

  const changeTypeHandler = useCallback(
    (type) => {
      const showDateSwitcher = TYPE_WITH_DATESWITCHER.includes(type)
      const date = showDateSwitcher ? params.date : null
      onChangeFilters({ target: { value: type, name: "type" } })
      onChangeFilters({ target: { value: date, name: "date" } })
      if (!date) onChangeFilters({ target: { value: null, name: "range" } })
    },
    [params.date, onChangeFilters]
  )

  useEffect(() => {
    if (showDateSwitcher && !params.range) onChangeFilters({ target: { value: DEFAULT_RANGE, name: "range" } })
    else if (!showDateSwitcher && params.range) onChangeFilters({ target: { value: null, name: "range" } })
  }, [params.range, showDateSwitcher]) //eslint-disable-line

  useEffect(() => {
    if (!params.type) changeTypeHandler(DEFAULT_TYPE)
  }, [params.type, changeTypeHandler])

  const fetchAll = () => {
    if (!params.type || !params.date || !params.range) return
    const searchParams = requestParams(params)
    dispatch(getGuideAccounting(searchParams))
  }

  const fetchOverview = () => {
    if (!params.type) return
    const searchParams = {}
    if (params.date_gteq && params.date_lteq) searchParams.q = { date_gteq: params.date_gteq, date_lteq: params.date_lteq }
    dispatch(getGuideAccountingOverview(searchParams))
  }

  const getAccountingDataHandler = async () => {
    dispatch(api({ url: "/guide/accounting/export" })).then((data) => {
      const blob = new Blob([data], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "report.csv")
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      window.URL.revokeObjectURL(url) //
    })
  }

  const fetchData = () => {
    if (!params.type) return
    if (params.type === "business_overview") fetchOverview()
    else fetchAll()
  }

  useEffect(() => {
    fetchData()
  }, [params.type, params.range, params.date, params.date_gteq, params.date_lteq]) // eslint-disable-line

  useEffect(() => () => dispatch(cleanExpenses()), []) // eslint-disable-line

  useEffect(() => {
    if (!needReload) return
    window.history.replaceState({}, document.title)
    fetchData()
  }, [needReload]) // eslint-disable-line

  return (
    <div>
      <div className="my-10">
        <div
          className={`grid grid-cols-${showDateSwitcher ? 3 : 2} hstack gap-2 justify-content-between align-items-center w-100 flex-wrap`}
        >
          <Dropdown
            className="my-1 my-md-n1"
            toggleButton={
              <DropdownToggle color="ghost" className="hstack gap-2 rounded-1 m-0 p-1">
                <h1 className="h3 fw-medium lh-1">{TYPE_OPTIONS_ENUM[params.type]}</h1>
                <Icon iconName="SidebarToggle" size={20} />
              </DropdownToggle>
            }
          >
            {Object.entries(TYPE_OPTIONS_ENUM).map(([type, label], index) => (
              <DropdownItem key={index} onClick={() => changeTypeHandler(type)} active={params.type === type}>
                {label}
              </DropdownItem>
            ))}
          </Dropdown>
          {showDateSwitcher && (
            <div className="hstack gap-3 mx-auto my-1 my-md-n1">
              <DateSwitcher
                name="date"
                type={params.range}
                value={date}
                defaultValue={global.dateTime().startOf("date")}
                onChange={onChangeFilters}
                restrictions={{
                  maxDate: global.dateTime().endOf(params.range === "week" ? "date" : params.range || "date", 1)
                }}
              />
              <Dropdown
                className="my-n1"
                toggleButton={
                  <DropdownToggle color="primary-second" outline className="hstack gap-2 rounded-pill py-2 px-15">
                    <span className="fs-7 fw-medium lh-1">{RANGE_OPTIONS[params.range]}</span>
                    <Icon iconName="SidebarToggle" size={14} />
                  </DropdownToggle>
                }
              >
                {Object.entries(RANGE_OPTIONS).map(([range, label], index) => (
                  <DropdownItem key={index} name="range" value={range} onClick={onChangeFilters} active={params.range === range}>
                    {label}
                  </DropdownItem>
                ))}
              </Dropdown>
            </div>
          )}
          <div className="hstack gap-2 justify-content-end my-3 my-md-n1">
            <Button color="primary-second" tag={Link} className="px-20 py-10 fs-7" to={oldRoutes.guideAccountingAddExpancePath({}, params)}>
              {t("expense.add")}
            </Button>
            <Button color="primary-second" outline className="p-2">
              <Icon iconName="ShareArrow" size={22} block onClick={getAccountingDataHandler} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
