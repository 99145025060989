import React, { useEffect, useState } from "react"
import { Layout } from "antd"
import styles from "./Index.module.css"
import { Outlet, useLocation } from "react-router-dom"
import { Sidebar } from "./Sidebar"
import { useDispatch } from "react-redux"
import { setProHomeMenuItemActive } from "store/pro-home-menu"
import classNames from "classnames"
import { routes } from "router/routes"
import UnactiveProPage from "./UnactiveProPage"

const { Content } = Layout

export const ProHomeLayout = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [menuId, setMenuId] = useState()

  useEffect(() => {
    const menuPath = location.pathname.split(routes.proHomeRootPath()).filter(Boolean)[0]
    const menuId = menuPath?.split("/").filter(Boolean)[0]
    dispatch(setProHomeMenuItemActive(menuId))
    setMenuId(menuId)
  }, [dispatch, location.pathname])

  return (
    <Layout className={styles.layout}>
      <Sidebar />
      <Content className={classNames(styles.content, menuId && styles.hasMenuId)}>
        <UnactiveProPage>
          <Outlet />
        </UnactiveProPage>
      </Content>
    </Layout>
  )
}
