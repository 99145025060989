import React, { useState } from "react"
import { Col, Form, Row } from "antd"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

// selectors
import { updatePassword } from "store/user"
// hooks
import useFormErrors from "hooks/useAntdFormErrors"
// ui
import { Text } from "ui/Typography"
import { CustomInput } from "ui/Input"
import { CustomForm } from "ui/Form"
import { ActionButtons } from "ui/ActionButtons"
// components
import { ChangeEmailPhoneForm } from "./ChangeEmailPhoneForm"
// utils
import { GoogleConnect } from "./GoogleConnect"
import { FacebookConnect } from "./FacebookConnect"
import { AppleConnect } from "./AppleConnect"
import { notification } from "ui/Notification"

export const LoginAndSecurity = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [newPassword, setNewPassword] = useState(null)
  const [currentPassword, setCurrentPassword] = useState(null)
  const [form] = Form.useForm()
  useFormErrors(form)

  const { loading } = useSelector((state) => state.auth)

  const handleNavigateBack = () => navigate(-1)

  const handleUpdatePassword = ({ current_password, password }) => {
    dispatch(
      updatePassword({
        current_password,
        password,
        password_confirmation: password
      })
    ).then(() => {
      form.resetFields()
      setNewPassword(null)
      setCurrentPassword(null)
      notification.success({
        message: t("password.changed")
      })
    })
  }

  return (
    <>
      <Row>
        <Col span={24} md={14}>
          <ChangeEmailPhoneForm />
        </Col>
      </Row>
      <CustomForm
        form={form}
        name="settings-login-security"
        layout="vertical"
        validateMessages={{ required: t("form_validation.cannot_be_blank") }}
        initialValues={{
          password: "",
          current_password: ""
        }}
        onFinish={handleUpdatePassword}
      >
        <Col span={24} md={14}>
          <Form.Item label={t("change_password.current_password")} name="current_password" required={true} rules={[{ required: true }]}>
            <CustomInput.Password placeholder="Enter Password" onChange={(e) => setCurrentPassword(e.target.value)} />
          </Form.Item>
          <Form.Item
            label={t("change_password.new_password")}
            name="password"
            required
            rules={[{ required: true }, { min: 8, message: t("form_validation.password_length") }]}
          >
            <CustomInput.Password placeholder="8+ characters" onChange={(e) => setNewPassword(e.target.value)} />
          </Form.Item>
        </Col>
        <div className="my-xl">
          <Text fw={500}>{t("settings.connect_accounts")}</Text>
        </div>
        <Row gutter={[24, 12]}>
          <Col xl={8}>
            <FacebookConnect />
          </Col>
          <Col xl={8}>
            <GoogleConnect />
          </Col>
          <Col xl={8}>
            <AppleConnect />
          </Col>
        </Row>
        <div className="flex gap-s mt-4xl">
          <ActionButtons
            onCancelClick={handleNavigateBack}
            loading={loading}
            disabled={!(currentPassword?.length > 0 && newPassword?.length >= 8)}
          />
        </div>
      </CustomForm>
    </>
  )
}
