import React from "react"

export const Insurance = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 0.960022C10.9056 0.960022 9.99146 1.5661 8.64189 2.26033C7.29233 2.95457 5.48792 3.79054 2.66252 4.41096L2.28564 4.4944V4.88065C2.28564 11.627 4.64547 16.135 7.01627 18.9328C9.38707 21.7306 11.7985 22.8488 11.7985 22.8488L12.0103 22.9463L12.2175 22.8413C12.2175 22.8413 21.7144 17.9414 21.7144 4.88065V4.4944L21.3375 4.41096C18.5121 3.79054 16.7077 2.95457 15.3581 2.26033C14.0086 1.5661 13.0944 0.960022 12 0.960022ZM12 1.92002C12.7075 1.92002 13.5382 2.40297 14.9194 3.11346C16.2239 3.78453 18.0703 4.59878 20.7235 5.22846C20.5544 17.0769 12.6088 21.524 11.9925 21.8503C11.6959 21.7055 9.82533 20.7631 7.74846 18.3122C5.54553 15.7125 3.36076 11.5394 3.27564 5.22846C5.92933 4.59875 7.77595 3.78461 9.08064 3.11346C10.4618 2.40297 11.2925 1.92002 12 1.92002ZM12 6.72002C9.35474 6.72002 7.20002 8.87474 7.20002 11.52C7.20002 14.1653 9.35474 16.32 12 16.32C14.6453 16.32 16.8 14.1653 16.8 11.52C16.8 8.87474 14.6453 6.72002 12 6.72002ZM12 7.68002C14.1265 7.68002 15.84 9.39356 15.84 11.52C15.84 13.6465 14.1265 15.36 12 15.36C9.87356 15.36 8.16002 13.6465 8.16002 11.52C8.16002 9.39356 9.87356 7.68002 12 7.68002ZM11.52 9.12002V11.04H9.60002V12H11.52V13.92H12.48V12H14.4V11.04H12.48V9.12002H11.52Z"
      fill={fill}
    />
  </svg>
)
