import React from "react"
import PropTypes from "prop-types"
import { Button, Flex, Avatar } from "antd"
import { MapPin, Star, User } from "react-feather"
import { useTranslation } from "react-i18next"

// UI
import { Text, Title } from "ui/Typography"

export const PreviewHeader = ({ imageSrc, name, rating = 0, ratingCount = 0, address, className, onMessageClick }) => {
  const { t } = useTranslation()

  return (
    <Flex vertical gap={24}>
      <Flex justify="space-between" align="center" wrap className={className}>
        <Flex gap={20} align="center">
          <div style={{ height: 80, width: 80 }}>
            <Avatar src={imageSrc} icon={<User />} size={80} />
          </div>
          <Flex vertical gap={0} justify="center" wrap>
            <Title level={3}>{name}</Title>
            <Flex gap={24} align="center" wrap>
              <Text fw="400" type="secondary" className="no-pre-wrap">
                <MapPin size={16} /> {address || "—"}
              </Text>
              <Text classname="">
                <Star size={16} className="text-primary-second" /> {rating} <Text type="secondary">({ratingCount})</Text>
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Button type="primary" size="large" className="ml-auto my-2" onClick={onMessageClick}>
          {t("client.message")}
        </Button>
      </Flex>
    </Flex>
  )
}

PreviewHeader.propTypes = {
  imageSrc: PropTypes.string,
  name: PropTypes.string,
  rating: PropTypes.number,
  address: PropTypes.string,
  onMessageClick: PropTypes.func,
  className: PropTypes.string
}
