import React from "react"
import { isProApp } from "utils/app-type"
import { ClientLandingPage } from "./ClientLandingPage"
import { Navigate } from "react-router-dom"
import { routes } from "router"

export default function Landing() {
  if (isProApp()) {
    return <Navigate to={routes.signInV2()} replace />
  } else {
    return <ClientLandingPage />
  }
}
