import React from "react"
import classNames from "classnames"
import { Text } from "ui/Typography"
import styles from "./Message.module.css"

export const MessageContent = ({ message, isOwnMessage, scrollToBottom }) => {
  if (message.media_url) {
    return (
      <img
        src={message.media_url}
        alt=""
        className={classNames(styles.imgMessage, "mb-xxs", isOwnMessage && "self-end")}
        onLoad={scrollToBottom && scrollToBottom}
      />
    )
  }

  return (
    <Text variant="body" style={{ color: isOwnMessage && "#ffffff", whiteSpace: "pre-line" }}>
      {message.body}
    </Text>
  )
}
