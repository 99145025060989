import React from "react"
import { useMatch } from "react-router-dom"
import { routes } from "router/routes"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import styles from "./UnactiveProPage.module.css"

export default function UnactiveProPage({ children }) {
  const { t } = useTranslation()
  const { guide, role } = useSelector((state) => state.user)
  const isSettingsPage = useMatch({ path: routes.proSettingsRootPath(), end: false })
  const isContactSupportPage = useMatch({ path: routes.contactSupportPath(), end: false })

  if (role === "user") return children
  if (guide?.active || isSettingsPage || isContactSupportPage) return children

  return (
    <div className={styles.wrapper}>
      <h3>{t("pro.under_review.title")}</h3>
      <p>
        {t("pro.under_review.subtitle")}
        <br />
        {t("pro.under_review.info")}
      </p>
    </div>
  )
}
