import { useState } from "react"
import { Col, Row } from "antd"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { useTheme } from "context/AntdThemeContext"
import { Card } from "ui/AdventureModeSelect/Card"
import { themes } from "constants/theme"
import { updateUser } from "store/user"
import { ContentHeader } from "layoutsV2/pro/PersonalInfoLayout/ContentHeader"
import { ContentActionButtons } from "layoutsV2/pro/PersonalInfoLayout/ContentActionButtons"

export const AdventureMode = () => {
  const { t } = useTranslation()
  const { theme, switchTheme, saveThemeToStorage } = useTheme()
  const dispatch = useDispatch()
  const [mode, setMode] = useState(theme)

  const onSelect = (theme) => {
    dispatch(updateUser({ adventure_mode: mode })).then(() => {
      saveThemeToStorage(mode)
      setMode(theme)
      switchTheme(theme)
    })
  }

  return (
    <Row>
      <Col lg={16} md={24} xs={24} span={16}>
        <div className="flex-column">
          <ContentHeader
            title={t("select_adventure_mode.title")}
            description={`${t("select_adventure_mode.subtitle")}. ${t("select_adventure_mode.info")}`}
          />

          <div className="justify-center">
            <Row gutter={24} className="w-full">
              <Col span={12}>
                <Card
                  type={themes.sportsman}
                  bordered={false}
                  selected={mode === themes.sportsman}
                  onSelect={() => onSelect(themes.sportsman)}
                  isRadioButton
                />
              </Col>
              <Col span={12}>
                <Card
                  type={themes.sportswoman}
                  bordered={false}
                  selected={mode === themes.sportswoman}
                  onSelect={() => onSelect(themes.sportswoman)}
                  isRadioButton
                />
              </Col>
            </Row>
          </div>
          <ContentActionButtons />
        </div>
      </Col>
    </Row>
  )
}
