import React from "react"
import { useTranslation } from "react-i18next"
import { Title, Text } from "ui/Typography"
import { Button } from "antd"
import { useDevice } from "hooks/useDevice"
import { Icon } from "ui/Icon"
import classNames from "classnames"

const AddressTitle = ({ isMobile, children }) => {
  if (isMobile) {
    return (
      <Text className="text-center" variant="sub">
        {children}
      </Text>
    )
  }

  return (
    <Title className="mb-0" level={3}>
      {children}
    </Title>
  )
}

export const AddressesHeader = ({ onPreview }) => {
  const { isMobile } = useDevice()
  const { t } = useTranslation()
  return (
    <div className={classNames(isMobile ? "mb-s" : "mb-xl")}>
      <div className={classNames(isMobile ? "flex-column justify-center" : "justify-between align-center", "mb-l")}>
        <AddressTitle isMobile={isMobile}>{t("pro.addresses.title")}</AddressTitle>
        {!isMobile && (
          <Button
            color="primary"
            variant="outlined"
            size="large"
            icon={<Icon type="feather" name="Play" strokeWidth={1} />}
            onClick={onPreview}
          >
            {t("global.preview")}
          </Button>
        )}
      </div>
      <Text>{t("pro.addresses.info")}</Text>
    </div>
  )
}
