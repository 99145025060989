import React, { memo } from "react"
import PropTypes from "prop-types"
import { Link, useSearchParams } from "react-router-dom"
import { routes } from "router/routes"

import { colorClasses } from "helpers/color"
import { timeTo12 } from "helpers/time"

import { STATUS_COLOR } from "components/bookings/constants"

const Booking = ({ booking, className, isDisabled, inTimeline = false, badge = false, top = 30 }) => {
  const [searchParams] = useSearchParams()
  const convertedStatus =
    (booking.status === "canceled" && "canceled") ||
    (["pending", "outstanding"].includes(booking.payment_status) && "unpaid") ||
    (isDisabled && "completed") ||
    booking.status

  const classes = []
  if (className) classes.push(className)
  if (inTimeline)
    classes.push(
      "vstack me-auto h-100 rounded-1 border badge bg-opacity-25 bg-opacity-50-hover",
      "fs-7 fw-semibold text-start text-wrap text-decoration-none lh-1"
    )
  else
    classes.push(
      "hstack gap-1 me-auto p-1 rounded badge bg-opacity-25 bg-opacity-100-hover",
      "fs-7 fw-semibol text-start text-wrap text-decoration-none lh-1 text-white-hover"
    )
  if (booking.status === "canceled") classes.push("text-decoration-line-through")
  classes.push(colorClasses(convertedStatus, STATUS_COLOR))

  const titleClasses = []
  if (inTimeline) titleClasses.push("sticky-top sticky-bottom p-1 my-auto")
  const titleStyles = inTimeline ? { "--sticky-top": `${top}px`, "--sticky-bottom": "10px" } : {}

  return (
    <Link to={routes.guideDashboardBookingEditPath({ id: booking.id }, searchParams)} className={classes.join(" ")}>
      {badge && <div className={["rounded p-1", colorClasses(convertedStatus, STATUS_COLOR)].join(" ")} />}
      <small className={titleClasses.join(" ")} style={titleStyles}>
        {booking.trip.title}
        {booking.trip.start_time && ` - ${timeTo12(booking.trip.start_time)}`}
      </small>
    </Link>
  )
}

Booking.propTypes = {
  booking: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    payment_status: PropTypes.string,
    trip: PropTypes.shape({
      title: PropTypes.string.isRequired,
      start_time: PropTypes.string
    }).isRequired
  }).isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  inTimeline: PropTypes.bool,
  badge: PropTypes.bool,
  top: PropTypes.number
}

Booking.defaultProps = {
  className: "",
  isDisabled: false,
  inTimeline: false,
  badge: false,
  top: 30
}

export default memo(Booking)
