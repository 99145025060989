import React from "react"
import { Form, Input, Button, Checkbox } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Title, Text } from "ui/Typography"
import { signIn } from "store/auth"
import { routes } from "router"
import useFormErrors from "hooks/useAntdFormErrors"
import { CustomInputError } from "ui/CustomInputError"
import { getRole } from "utils/app-type"

export const PasswordRecoverySuccess = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.auth)
  const location = useLocation()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const role = getRole()

  const from = location.state?.from?.pathname || "/"
  const loggedInHandler = () => navigate(from, { replace: true })

  const onFinish = (user) => {
    dispatch(signIn({ ...user, role })).then(loggedInHandler)
  }

  const { errors } = useFormErrors(form)

  return (
    <>
      <Title level={2} className="justify-center mb-3xl">
        {t("create_password.success")}
      </Title>
      <Form form={form} name="login" initialValues={{ remember: true }} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={t("sign_in.email")}
          name="email"
          required={false}
          rules={[
            { required: true, message: t("form_validation.email_required") },
            { type: "email", message: t("form_validation.email") }
          ]}
          help={!!errors?.email ? <CustomInputError errorKey={errors.email} /> : null}
        >
          <Input placeholder={t("sign_in.email")} />
        </Form.Item>
        <Form.Item
          label={t("sign_in.password")}
          name="password"
          required={false}
          rules={[
            { required: true, message: t("form_validation.password_required") },
            { min: 8, message: t("form_validation.password_length") }
          ]}
          help={!!errors?.password ? <CustomInputError errorKey={errors?.password} /> : null}
        >
          <Input.Password placeholder={t("sign_in.password")} />
        </Form.Item>
        <Form.Item name="remember_me" valuePropName="checked">
          <div className="justify-between">
            <Checkbox value="remember_me">
              <Text type="secondary" variant="body">
                {t("sign_in.remember_me")}
              </Text>
            </Checkbox>
            <Text link>
              <Link to={routes.passwordRecovery()}>{t("sign_in.forgot_password")}?</Link>
            </Text>
          </div>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block size="large" disabled={loading}>
            {t("global.sign_in")}
          </Button>
        </Form.Item>
      </Form>
      {!!errors?.base ? <CustomInputError errorKey={errors.base} /> : null}
    </>
  )
}
