import React from "react"
import { AuthenticationLayout } from "layoutsV2/client/AuthenticationLayout"
import { SignUpOptions as Options } from "ui/auth/SignUpOptions"

export const SignUpOptions = () => {
  return (
    <AuthenticationLayout type="signup">
      <Options />
    </AuthenticationLayout>
  )
}
