import React from "react"
import styles from "./SettingsCard.module.css"
import { Icon } from "ui/Icon"
import { useDevice } from "hooks/useDevice"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

export const SettingsCard = ({ title, iconName, path, comingSoon }) => {
  const { isMobile } = useDevice()
  const iconSize = isMobile ? 24 : 44
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleOnClick = () => {
    if (path) {
      navigate(path)
    }
  }
  return (
    <div className={classNames(styles.card, comingSoon && styles.disabledCard)} onClick={handleOnClick}>
      <div className={styles.cardIcon}>
        <Icon width={iconSize} height={iconSize} name={iconName} />
      </div>
      <div className={styles.cardTitle}>{title}</div>
      {comingSoon && <div className={styles.comingSoon}>{t("campaign.coming_soon")}</div>}
    </div>
  )
}
