import React from "react"
import styles from "./StripeAccountCard.module.css"
import stripeIcon from "assets/imagesV2/settings/stripe.svg"
import { Flex } from "antd"
import { spacings } from "themes/variables"
import { StripeActionButtons } from "./StripeActionButtons"
import { useDevice } from "hooks/useDevice"
import { StripeStatus } from "./StripeStatus"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

export const StripeAccountCard = ({ flow_type, className }) => {
  const { isMobile } = useDevice()
  const { t } = useTranslation()
  return (
    <div className={classNames(styles.wrapper, className)}>
      <Flex gap={spacings.M} align="center">
        <img src={stripeIcon} alt={"Stripe icon"} className={styles.stripeLogo} />
        <Flex vertical>
          <div className={styles.title}>Stripe</div>
          <div className={styles.account}></div>
          <div className={styles.accountDescription}>{t("pro.personal_info.stripe.card_description")}</div>
          {isMobile && <StripeStatus />}
        </Flex>
      </Flex>
      {!isMobile && (
        <Flex gap={spacings["3XL"]}>
          <StripeStatus />
          <StripeActionButtons flow_type={flow_type} />
        </Flex>
      )}
    </div>
  )
}
