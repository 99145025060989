import React from "react"
import { Button, Col, Label, Row } from "reactstrap"
import Input from "components/form/Input"
import LanguageChanger from "./LanguageChanger"
import Avatar from "components/common/Avatar"
import Icon from "components/common/Icon"
import GooglePlacesInput from "modules/google/GooglePlacesInput"

import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { compressFile } from "helpers/compress"
import { convertGoogleAddressComponentsToObj } from "modules/google/helpers"
import useForm from "hooks/useForm"

import { useSelector, useDispatch } from "react-redux"
import { updateUser } from "store/user"
import { generateCountryOptions } from "components/bookings/helpers"

const coogleAddressConfig = {
  address_line_1: { elements: ["street_number", "route"], divider: " " },
  state: { elements: ["administrative_area_level_1"] },
  city: { elements: ["locality"] },
  country: { elements: ["country"] },
  zip_code: { elements: ["postal_code", "postal_code_suffix"], divider: "-" }
}

export default function SettingsForm() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const user = useSelector((store) => store.user)
  const [form, changeHandler, submitHandler, submitCallback, reset, isChanged] = useForm(user, [
    "first_name",
    "last_name",
    "birthday",
    "email",
    "phone_number",
    "gender",
    "address_line_1",
    "address_line_2",
    "city",
    "state",
    "country",
    "zip_code"
  ])

  const today = new Date()
  const sevenDaysAgo = new Date(today)
  sevenDaysAgo.setDate(today.getDate() - 7)
  const maxDate = sevenDaysAgo.toISOString().split("T")[0]

  const handleImageChange = async (e) => {
    const avatar = await compressFile(e.target.files[0])
    const formData = serialize({ avatar })
    if (avatar) dispatch(updateUser(formData))
  }

  const placeChangedHandler = ({ target }) => {
    const { address_components } = target?.place || {}
    const obj = convertGoogleAddressComponentsToObj(address_components, coogleAddressConfig)
    Object.keys(obj).forEach((name) => changeHandler({ target: { name, value: obj[name] } }))
  }

  submitCallback(() => {
    dispatch(updateUser(form))
  })

  return (
    <form className="w-100" onSubmit={submitHandler}>
      <Row className="gap-y-15 gx-4" style={{ maxWidth: 670 }}>
        <Col xs={12} sm={3} md={2} className="align-self-center hstack">
          <div className="link-target mw-100">
            <Avatar user={user} size={116} />
            <div className="hstack position-absolute top-0 start-0 w-100 h-100 z-2">
              <Button tag="label" htmlFor="avatarPhoto" color="ghost" size="icon" className="ms-auto mt-auto">
                <Icon iconName="Edit" className="d-block text-dark" />
              </Button>
              <input
                type="file"
                id="avatarPhoto"
                name="avatarPhoto"
                className="d-none"
                accept="image/png, image/jpeg"
                onChange={handleImageChange}
              />
            </div>
          </div>
        </Col>
        <Col xs={12} sm={9} md={10}>
          <Row className="gap-y-15 gx-4" style={{ maxWidth: 670 }}>
            <Col xs={12} md={6}>
              <Label className="mt-1" for="first_name">
                {t("settings.first_name")} *
              </Label>
              <Input
                id="first_name"
                type="text"
                name="first_name"
                placeholder={t("settings.first_name")}
                value={form.first_name || ""}
                onChange={changeHandler}
                withError
              />
            </Col>
            <Col xs={12} md={6}>
              <Label className="mt-1" for="last_name">
                {t("settings.last_name")} *
              </Label>
              <Input
                id="last_name"
                type="text"
                name="last_name"
                placeholder={t("settings.last_name")}
                value={form.last_name || ""}
                onChange={changeHandler}
                withError
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Label className="mt-1" for="gender">
            {t("settings.gender_label")}
          </Label>
          <Input
            id="gender"
            type="select"
            name="gender"
            placeholder={t("settings.gender_label")}
            value={form.gender || ""}
            onChange={changeHandler}
            withError
          >
            <option value="male">{t("settings.gender.male")}</option>
            <option value="female">{t("settings.gender.female")}</option>
          </Input>
        </Col>
        <Col xs={12}>
          <Label className="mt-1" for="birthday">
            {t("settings.birthday")}
          </Label>
          <Input id="birthday" name="birthday" type="date" value={form.birthday} onChange={changeHandler} min="1900-01-01" max={maxDate} />
        </Col>
        <Col xs={12}>
          <Label className="mt-1" for="email">
            {t("settings.email")} *
          </Label>
          <Input
            id="email"
            type="email"
            name="email"
            placeholder={t("settings.email")}
            value={form.email || ""}
            onChange={changeHandler}
            withError
          />
        </Col>
        <Col xs={12}>
          <Label className="mt-1" for="phone_number">
            {t("settings.phone")}
          </Label>
          <Input
            id="phone_number"
            type="tel"
            name="phone_number"
            placeholder={t("settings.phone")}
            value={form.phone_number || ""}
            onChange={changeHandler}
            withError
          />
        </Col>
        <Col xs={12}>
          <LanguageChanger />
        </Col>
        <Col xs={12}>
          <Label className="mt-1">{t("settings.address.title")}</Label>
          <GooglePlacesInput
            placeholder={t("settings.address.address_line_1")}
            defaultValue={form.address_line_1 || ""}
            onChange={placeChangedHandler}
            wrapperProps={{ className: "h-100 flex-fill" }}
            useDefaultValue
          />
        </Col>
        <Col xs={12}>
          <Input
            type="text"
            name="address_line_2"
            placeholder={t("settings.address.address_line_2")}
            value={form.address_line_2 || ""}
            onChange={changeHandler}
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            type="text"
            name="city"
            placeholder={t("settings.address.city")}
            value={form.city || ""}
            onChange={changeHandler}
            withError
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            type="text"
            name="state"
            placeholder={t("settings.address.state")}
            value={form.state || ""}
            onChange={changeHandler}
            withError
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            type="select"
            name="country"
            placeholder={t("settings.address.country")}
            value={form.country || ""}
            onChange={changeHandler}
            className={form.country ? "" : "text-gray-light"}
            withError
          >
            {generateCountryOptions()}
          </Input>
        </Col>
        <Col xs={12} md={6}>
          <Input
            type="text"
            name="zip_code"
            placeholder={t("settings.address.zip_code")}
            value={form.zip_code}
            onChange={changeHandler}
            withError
          />
        </Col>
        <div className="hstack gap-20 justify-content-end mt-25">
          {isChanged && (
            <Button color="light" type="button" onClick={reset}>
              {t("global.cancel")}
            </Button>
          )}
          <Button color="primary" disabled={!isChanged}>
            {t("settings.update")}
          </Button>
        </div>
      </Row>
    </form>
  )
}
