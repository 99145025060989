import React, { useCallback, useEffect, useRef, memo } from "react"
import { Layout, List } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { useDevice } from "hooks/useDevice"
import { Message } from "../Message"
import { HistoryHeader } from "../HistoryHeader"
import { MessageForm } from "./MessageForm"
import { Waypoint } from "react-waypoint"
import { isLastPage } from "helpers/pagination"

// Redux
import {
  activeConversationSelector,
  getConversation,
  messagesMetadataSelector,
  messagesSelector,
  getConversations
} from "store/conversations"
import { collectionAnySelector, collectionSelector } from "store/selectors"
import { groupMessagesByDateAndAuthor } from "../utils"

import styles from "./ChatHistory.module.css"
import classNames from "classnames"
// import { routes } from "router"

const { Content } = Layout

const History = ({ selectedConversationId }) => {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { isMobile, isTablet } = useDevice()
  const conversationBox = useRef(null)
  const conversationBoxScrollHeight = useRef(0)
  const hasConversations = useSelector(collectionAnySelector("conversations"))
  const conversations = useSelector(collectionSelector("conversations"))

  const firstRender = useRef(true)
  const hasDispatchedConversations = useRef(false)
  // const navigate = useNavigate()
  const messages = useSelector(messagesSelector)
  const metadata = useSelector(messagesMetadataSelector)
  const activeConversation = useSelector(activeConversationSelector)

  const user = useSelector((state) => state.user)
  const isGuide = user.role === "guide_user"
  const participant = isGuide ? activeConversation?.client : activeConversation?.contact

  const scrollToBottom = () => conversationBox?.current?.scrollIntoView({ block: "end" })

  const fetchMessages = useCallback(
    (nextPage = false) => {
      conversationBoxScrollHeight.current = conversationBox.current?.scrollHeight
      return (
        selectedConversationId &&
        dispatch(getConversation(selectedConversationId, nextPage))
          .then(() => {
            if (!nextPage) {
              scrollToBottom()
            } else {
              conversationBox.current.parentNode?.scrollTo({
                top: conversationBox.current.parentNode.scrollHeight - conversationBoxScrollHeight.current
              })
            }
            firstRender.current = false
          })
          .catch(() => setSearchParams({}))
      )
    },
    [selectedConversationId] // eslint-disable-line
  )

  useEffect(() => {
    const hasSelectedConversation = conversations.find((conversation) => conversation.id === selectedConversationId)
    if (selectedConversationId && (hasSelectedConversation || hasDispatchedConversations.current)) {
      fetchMessages()
    } else if (selectedConversationId && hasConversations && !hasSelectedConversation && !hasDispatchedConversations.current) {
      hasDispatchedConversations.current = true
      dispatch(getConversations(null)).then(() => {
        fetchMessages()
      })
    }
  }, [fetchMessages, hasConversations]) //eslint-disable-line

  const groupedMessages = groupMessagesByDateAndAuthor(messages)

  return (
    <div className={classNames(styles.history, (isMobile || isTablet) && styles.smallScreen)}>
      <HistoryHeader participant={participant} isMobile={isMobile} isTablet={isTablet} />
      <Content className={styles.content}>
        {!isLastPage(metadata) && <Waypoint onEnter={() => fetchMessages(true)} topOffset={-50} />}
        <List
          dataSource={groupedMessages}
          ref={conversationBox}
          renderItem={(msg) => (
            <Message
              key={msg.id}
              message={msg}
              isOwnMessage={user?.id === msg?.author?.id}
              isMobile={isMobile}
              scrollToBottom={firstRender.current && scrollToBottom}
            />
          )}
        />
      </Content>
      <MessageForm conversation={activeConversation} messageSent={scrollToBottom} isSmallScreen={isMobile || isTablet} />
    </div>
  )
}

export const ChatHistory = memo(History)
