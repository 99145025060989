import { Flex } from "antd"
import { countries, getCountryDialCodeFromCountryCodeOrNameOrFlagEmoji } from "country-codes-flags-phone-codes"
import { useMemo, useState } from "react"
import { PhoneCodeLabel } from "./PhoneCodeLabel"
import { PhoneCodeOption } from "./PhoneCodeOption"
import { CustomInput, CustomSelect } from ".."
import parsePhoneNumber, { AsYouType, isValidPhoneNumber } from "libphonenumber-js"

const options = countries.map((country) => {
  return { value: country.code, label: <PhoneCodeOption country={country} /> }
})

export const PhoneInput = ({ initialValue, disabled, borderless, onChange }) => {
  const parsedInitialValue = useMemo(() => parsePhoneNumber(initialValue || "", "US"), [initialValue])
  const [countryCode, setCountryCode] = useState(parsedInitialValue?.country || "US")
  const [phoneNumber, setPhoneNumber] = useState(parsedInitialValue?.formatNational({ nationalPrefix: false }) || "")

  const handleCountryCodeSelect = (value) => {
    setCountryCode(value)
    const dialCode = getCountryDialCodeFromCountryCodeOrNameOrFlagEmoji(value)
    const full_number = `${dialCode}${phoneNumber}`
    const formattedPhoneNumber = new AsYouType(value).input(phoneNumber)
    setPhoneNumber(formattedPhoneNumber)
    let cleanFullPhoneNumber = null
    if (isValidPhoneNumber(full_number)) {
      const parsedFullPhoneNumber = parsePhoneNumber(full_number)
      cleanFullPhoneNumber = parsedFullPhoneNumber?.number
    }
    onChange?.(cleanFullPhoneNumber)
  }

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value
    if (phoneNumber?.split(value)[1] === ")") {
      value = value.substring(0, value.length - 1)
    }
    const formattedPhoneNumber = new AsYouType(countryCode).input(value)
    setPhoneNumber(formattedPhoneNumber)
    const dialCode = getCountryDialCodeFromCountryCodeOrNameOrFlagEmoji(countryCode)
    const full_number = `${dialCode}${formattedPhoneNumber}`
    let cleanFullPhoneNumber = null
    if (isValidPhoneNumber(full_number)) {
      cleanFullPhoneNumber = parsePhoneNumber(full_number)?.number
    }
    onChange?.(cleanFullPhoneNumber)
  }

  return (
    <Flex gap={8}>
      <CustomSelect
        options={options}
        value={countryCode}
        onSelect={handleCountryCodeSelect}
        labelRender={({ value }) => <PhoneCodeLabel countryCode={value} />}
        popupMatchSelectWidth={false}
        disabled={disabled}
        borderless={borderless}
        style={{ width: "auto" }}
      />
      <CustomInput
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        placeholder="XXX-XXX-XXXX"
        disabled={disabled}
        borderless={borderless}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault()
          }
        }}
        className="flex-grow-1"
      />
    </Flex>
  )
}
