export const ACCESS_ATTRIBUTE = ["type", "date", "range", "overview_range", "date_gteq", "date_lteq"]

export const DEFAULT_TYPE = "business_overview"

export const TYPE_OPTIONS_ENUM = {
  business_overview: "Business Overview",
  all: "All",
  expenses: "Expenses",
  sales: "Sales"
}

export const DEFAULT_RANGE = "month"

export const RANGE_OPTIONS = {
  month: "Month",
  week: "Week",
  date: "Day"
}

export const TYPE_WITH_DATESWITCHER = ["all", "expenses", "sales"]
export const SCOUP_LIST = ["expenses", "sales"]

export const STATS_NAMES = {
  all: "profit",
  expenses: "expenses",
  sales: "incomes"
}

export const CATEGORY_ICONS = {
  "equipment-gear": "boat",
  "legal-professional": "bag",
  fuel: "fuel",
  insurance: "insurance",
  licenses: "license",
  maintenance: "maintenance",
  memberships: "membership",
  "slip-marine-fees": "boat",
  lodging: "accomodation",
  accomodation: "accomodation",
  other: "other",
  Advertising: "advertising",
  "food-and-beverages": "food",
  "staff-and-labor": "staff"
}

export const RECURRING_TYPE = ["Monthly", "Yearly"]
