import { dynamicRoute } from "./routes"

export const ROUTES_LIST = {
  rootPath: "/",
  // becomeAGuidePath: "/become-a-guide",
  faqPath: "/faq",
  termsAndConditionsPath: "/terms-and-conditions",
  privacyPolicyPath: "/privacy-policy",
  tripsPath: "/trips",
  tripPath: "/trips/:id",
  referralPath: "/referral",
  salesPath: "/sales",

  // Devise routes
  signInPath: "/sign-in",
  signUpPath: "/sign-up",
  signUpRequestSentPath: "/sign-up/request-sent",
  forgotPasswordPath: "/forgot-password",
  resetPasswordRequestSentPath: "/reset-password/request-sent",
  resetPasswordPath: "/reset-password",
  resetPasswordSuccessPath: "/reset-password/success",
  verifyAccountPath: "/verify-account",
  verifyCodePath: "/verify-code",
  splashPath: "/splash",

  // Booking routes
  bookingWizardPath: "/booking_trips/wizard",

  guestBookingWizardRootPath: "/guest/booking_trips/:uuid/wizard",
  guestBookingWizardStep1Path: "/guest/booking_trips/:uuid/wizard/booking",
  guestBookingWizardStep2Path: "/guest/booking_trips/:uuid/wizard/accessibility",
  guestBookingWizardStep3Path: "/guest/booking_trips/:uuid/wizard/payment",
  guestBookingWizardStep4Path: "/guest/booking_trips/:uuid/wizard/confirmation",

  // Client routes
  clientRootPath: "/client",
  clientFavoritesPath: "/client/favorites",
  clientMessagesPath: "/client/messages",
  clientProfileRootPath: "/client/profile/:id",
  clientSettingsRootPath: "/client/settings",
  clientSettingsPaymentPath: "/client/settings/payment",
  clientSettingsPasswordPath: "/client/settings/password",
  clientSettingsNotificationsPath: "/client/settings/notifications",

  clientBookingsPath: "/client/booking_trips",
  clientBookingsPastTripsPath: "/client/booking_trips/past",
  clientBookingPath: "/client/booking_trips/:uuid",
  clientBookingWizardRootPath: "/client/booking_trips/:uuid/wizard",
  clientBookingWizardStep1Path: "/client/booking_trips/:uuid/wizard/booking",
  clientBookingWizardStep2Path: "/client/booking_trips/:uuid/wizard/accessibility",
  clientBookingWizardStep3Path: "/client/booking_trips/:uuid/wizard/payment",
  clientBookingWizardStep4Path: "/client/booking_trips/:uuid/wizard/confirmation",

  publicGuidePath: "/guides/:slug",
  publicGuideTripsPath: "/guides/:slug/trips",
  publicGuideLocationPath: "/guides/:slug/location",
  publicGuideBoatsPath: "/guides/:slug/boats",
  publicGuidePostsPath: "/guides/:slug/posts",
  publicGuideReviewsPath: "/guides/:slug/reviews",

  // Guide routes
  guideRootPath: "/pro/home",
  guideDashboardPath: "/pro/home/dashboard",
  guideDashboardBookingNewPath: "/guide/dashboard/bookings/new",
  guideDashboardBookingEditPath: "/guide/dashboard/bookings/:id/edit",
  guideDashboardJournalNewPath: "/guide/dashboard/journal_notes/new",
  guideDashboardJournalEditPath: "/guide/dashboard/journal_notes/:id/edit",

  guideDashboardUnavailableDayManagePath: "/guide/dashboard/unavailable_days/manage",

  guideWizardRootPath: "/pro/wizard",
  guideWizardStep1Path: "/pro/wizard/choose-your-activities",
  guideWizardStep2Path: "/pro/wizard/your-profile",
  guideWizardStep3Path: "/pro/wizard/photos",
  guideWizardStep4Path: "/pro/wizard/extras",
  guideWizardStep5Path: "/pro/wizard/documents",
  guideWizardStep6Path: "/pro/wizard/submit",

  guideBookingsPath: "/pro/home/bookings",
  guideBookingNewPath: "/pro/home/bookings/new",
  guideBookingEditPath: "/pro/home/bookings/:id/edit",

  guideClientsPath: "/pro/home/clients",
  guideClientNewPath: "/pro/home/clients/new",
  guideClientPath: "/pro/home/clients/:id",
  guideClientEditPath: "/pro/home/clients/:id/edit",
  guideClientEmailActionPath: "/pro/home/clients/email_action",
  guideClientTextActionPath: "/pro/home/clients/text_action",
  guideClientMessageActionPath: "/pro/home/clients/message_action",

  guideTripsPath: "/pro/home/trips",
  guideTripPath: "/pro/home/trips/:id",
  guideTripWizardRootPath: "/pro/home/trips/:id/wizard",
  guideTripWizardStep1Path: "/pro/home/trips/:id/wizard/trip-details",
  guideTripWizardStep2Path: "/pro/home/trips/:id/wizard/photos",
  guideTripWizardStep3Path: "/pro/home/trips/:id/wizard/boats",
  guideTripWizardStep3AddNewBoatPath: "/pro/home/trips/:id/wizard/boats/new",
  guideTripWizardStep4Path: "/pro/home/trips/:id/wizard/location",
  guideTripWizardStep5Path: "/pro/home/trips/:id/wizard/preview",

  guideMessagesPath: "/pro/home/messages",
  guideAccountingPath: "/pro/home/accounting",
  guideAccountingAddExpancePath: "/pro/home/accounting/new",
  guideAccountingEditExpancePath: "/pro/home/accounting/:id/edit",

  guideBoatsPath: "/pro/home/boats",
  guideAddNewBoatPath: "/pro/home/boats/new",
  guideEditBoatPath: "/pro/home/boats/:id/edit",

  guideMarketingPath: "/pro/home/marketing",
  guideMarketingPostNewPath: "/pro/home/marketing/new",
  guideMarketingPostEditPath: "/pro/home/marketing/:id/edit",
  guideMarketingTemplatesPath: "/pro/home/marketing/templates",
  guideMarketingTemplatesParentsPath: "/pro/home/marketing/templates/parents",
  guideMarketingTemplateNewPath: "/pro/home/marketing/templates/new",
  guideMarketingTemplateEditPath: "/pro/home/marketing/templates/:id/edit",
  
  guideSettingsPhotosPath: "/pro/home/settings/photos",
  guideSettingsExtrasPath: "/pro/home/settings/extras",
  guideSettingsDocumentsPath: "/pro/home/settings/documents",
  guideSettingsStripePath: "/pro/home/settings/stripe",
  guideSettingsSocialsPath: "/pro/home/settings/social-networks",
  guideSettingsPasswordPath: "/pro/home/settings/password",
  guideSettingsNotificationsPath: "/pro/home/settings/notifications",

  guideWeatherPath: "/pro/home/weather",

  guidePostPath: "/pro/home/posts/:id",
  guideProfileRootPath: "/pro/home/profile/:id",
  guideProfileContactsPath: "/pro/home/profile/:id/contacts",
  guideProfileMediaPath: "/pro/home/profile/:id/media",
  guideProfileTaggedPath: "/pro/home/profile/:id/tagged",
  guideProfileSettingsPath: "/pro/home/profile/:id/settings",
  guideContactsPath: "/pro/home/contacts",
  guideCommunitiesPath: "/pro/home/communities",
  guideCommunityPath: "/pro/home/communities/:id",
  guideNotificationsPath: "/pro/home/notifications",
  guideContactUsPath: "/pro/home/contact-us",
  guideChangePasswordPath: "/pro/home/change-password"
}

export const oldRoutes = Object.keys(ROUTES_LIST).reduce(
  (acc, routeAlias) => ({ ...acc, [routeAlias]: dynamicRoute(ROUTES_LIST[routeAlias]) }),
  {}
)
