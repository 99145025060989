import React from "react"
import { List } from "antd"
import { Text } from "ui/Typography"
import { Icon } from "ui/Icon"
import { colors } from "themes/variables"

import styles from "./Upload.module.css"

export const UploadListItem = ({ file, handleDelete, handleDownload }) => {
  return (
    <List.Item>
      <div className="flex align-center">
        <Icon name="File" color={colors.textSecondary} width={20} height={20} style={{ marginRight: "12px" }} />
      </div>

      <div className={styles.fileName}>
        <Text variant="sub">{file.filename || file.name}</Text>
      </div>

      <div className="flex ml-xs justify-between">
        <Icon
          name="Download"
          color={colors.textSecondary}
          width={20}
          height={20}
          onClick={() => handleDownload(file)}
          style={{ cursor: "pointer" }}
        />
        <Icon
          className="ml-xxs"
          name="Trash"
          color={colors.textSecondary}
          width={20}
          height={20}
          onClick={() => handleDelete(file)}
          style={{ cursor: "pointer" }}
        />
      </div>
    </List.Item>
  )
}
