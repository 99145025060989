import React, { memo, useRef } from "react"
import PropTypes from "prop-types"
import { Button, DropdownToggle, Label } from "reactstrap"
import { Dropdown } from "components/common/Dropdown"
import Input from "components/form/Input"
import Loading from "modules/loader-watchers/Loading"

import useForm from "hooks/useForm"
import { useTranslation } from "react-i18next"
import { getMomentValue } from "modules/datepicker/helpers"
import { numberToCurrency } from "helpers/string"

import { useDispatch, useSelector } from "react-redux"
import { modelSelector } from "store/selectors"
import { createGuideBookingIncomeRefund } from "store/bookings"

import { DATE_SHORT_TEXT_FORMAT } from "modules/datepicker/constants"

const Income = ({ income }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  const booking = useSelector(modelSelector("booking"))
  const [form, changeHandler, submitHandler, submitCallback, reset, isChanged] = useForm({ amount: 0, reduce_cost: false })
  const disabled = income.available_for_refund <= 0

  const onRefundToggle = () => inputRef?.current?.select?.()

  submitCallback(() => {
    const stripe = income.stripe
    dispatch(createGuideBookingIncomeRefund(booking.id, income.id, { ...form, stripe })).then(reset)
  })

  if (!income?.id) return null

  const color = income.stripe ? "stripe" : "success"

  return (
    <tr key={income.id} className="border-bottom border-gray-lightest fs-5 fw-normal">
      <td className="text-dark text-opacity-75 ps-0 text-nowrap">
        {getMomentValue(income.income_date).format(DATE_SHORT_TEXT_FORMAT) || "—"}
      </td>
      <td className={[income.amount < 0 ? "text-danger" : "text-dark"].join(" ")}>
        <div className="hstack gap-1 justify-content-end">{numberToCurrency(income.amount) || "—"}</div>
      </td>
      <td className="text-center">
        <div className={`d-inline-block py-0 px-3 rounded fs-6 fw-semibold lh-base text-${color} bg-${color} bg-opacity-25 text-center`}>
          {income.stripe ? "Card" : "Cash"}
        </div>
      </td>
      <td className="text-center">{!!income.payment_type_description && income.payment_type_description}</td>
      <td className="text-end pe-0">
        {!income.is_refund && !disabled && (
          <Dropdown
            classNameMenu="p-15 overflow-hidden"
            disabled={disabled}
            onToggle={onRefundToggle}
            toggleButton={
              <DropdownToggle
                className={[
                  "bg-info text-info bg-opacity-25 border-0 rounded-pill fs-6 fw-semibold px-4 py-0 lh-base shadow-none",
                  disabled ? "opacity-50" : ""
                ].join(" ")}
              >
                {t("booking.payment.labels.refund_client")}
              </DropdownToggle>
            }
          >
            <Loading tag="div" className="vstack gap-10" name="bookings.incomes" spinnerProps={{ className: "m-n2" }}>
              <Label for="amount" className="fs-7 text-dark text-opacity-50 m-0">
                <small>{t("booking.payment.labels.enter_refund_amount")}</small>
              </Label>
              <Input
                id="amount"
                type="number"
                name="amount"
                value={form.amount}
                min={0}
                max={income.available_for_refund}
                hint={`Max available for refund: ${numberToCurrency(income.available_for_refund)}`}
                onChange={changeHandler}
                ref={inputRef}
                withWrapper
              />
              {isChanged && (
                <div className="hstack gap-10">
                  <Label check className="hstack gap-2 d-inline-flex fs-7 fw-normal">
                    <Input
                      id="reduce_cost"
                      type="checkbox"
                      name="reduce_cost"
                      value={form.reduce_cost}
                      onChange={changeHandler}
                      className="m-0"
                    />
                    <span className={`text-dark ${form.reduce_cost ? "" : "text-opacity-50"}`}>Reduce cost</span>
                  </Label>
                  <Button color="primary-second" className="fs-7 px-4 py-2 ms-auto" type="button" onClick={submitHandler}>
                    {t("global.save")}
                  </Button>
                </div>
              )}
            </Loading>
          </Dropdown>
        )}
      </td>
    </tr>
  )
}

Income.propTypes = {
  income: PropTypes.shape({
    id: PropTypes.string.isRequired,
    income_date: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    available_for_refund: PropTypes.number.isRequired,
    is_refund: PropTypes.bool.isRequired,
    stripe: PropTypes.bool
  }).isRequired
}

export default memo(Income)
