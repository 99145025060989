import SupportForm from "components/SupportForm"
import { NavigationPath } from "layoutsV2/pro/ProHomeLayout/NavigationPath"
import React from "react"

export const ContactSupportPage = () => {
  return (
    <div className="p-20">
      <NavigationPath className="mx-20 px-2" />
      <SupportForm showTitle={false} />
    </div>
  )
}
