import React, { useRef } from "react"
import { Button, Upload } from "antd"
import PropTypes from "prop-types"
import { Icon } from "ui/Icon"
import { mediaBlockRenderer } from "helpers/editor"
import { Editor, RichUtils, EditorState, AtomicBlockUtils } from "draft-js"
import "draft-js/dist/Draft.css"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { resetUnviewedMessagesCount } from "store/conversations"
import { colors } from "themes/variables"
import classNames from "classnames"

MessageEditor.propTypes = {
  editorState: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  media: PropTypes.object,
  setMedia: PropTypes.func.isRequired,
  placeholder: PropTypes.string
}

export default function MessageEditor({ editorState, setEditorState, className, media, setMedia, placeholder = "", inputClassName = "" }) {
  const { t } = useTranslation()
  const prevEditorStateRef = useRef(EditorState.createEmpty())
  const editor = useRef(null)
  const dispatch = useDispatch()

  const focusEditor = () => {
    editor.current.focus()
  }

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (!newState) return
    setEditorState(newState)
  }

  const insertImage = (url) => {
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity("image", "IMMUTABLE", { src: url })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(
      editorState,
      {
        currentContent: contentStateWithEntity
      },
      "create-entity"
    )

    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
  }

  const handlePastedFiles = (files) => {
    if (media) return
    const imageUrl = URL.createObjectURL(files[0])
    setMedia(files[0])
    setEditorState(insertImage(imageUrl))
  }

  const handleChangeEditor = (state) => {
    const previousContent = prevEditorStateRef.current.getCurrentContent()
    const previousText = previousContent.getPlainText()
    const newContent = state.getCurrentContent()
    const newText = newContent.getPlainText()

    if (previousText.length === 0 && newText.length > 0) {
      dispatch(resetUnviewedMessagesCount())
    }

    setEditorState(state)
    prevEditorStateRef.current = state
  }

  const onUpload = ({ file }) => {
    handlePastedFiles([file])
  }

  return (
    <div className={classNames(className)} onClick={focusEditor}>
      <Upload beforeUpload={(file) => false} showUploadList={false} onChange={onUpload}>
        <Button icon={<Icon name="Image" color={colors.textSecondary} width={16} height={16} />} />
      </Upload>
      <div className={classNames(inputClassName)}>
        <Editor
          blockRendererFn={mediaBlockRenderer}
          ref={editor}
          editorState={editorState}
          onChange={handleChangeEditor}
          handleKeyCommand={handleKeyCommand}
          placeholder={placeholder || t("messenger.placeholder")}
          handlePastedFiles={handlePastedFiles}
        />
      </div>
    </div>
  )
}
