import React, { useCallback, useEffect, useRef, useState, useMemo } from "react"
import { Link, Outlet, useSearchParams } from "react-router-dom"
import { routes } from "router/routes"
import { Button, Label, Input, DropdownToggle } from "reactstrap"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import Sort from "components/clients/filters/Sort"
import Groups from "components/clients/filters/Groups"
import SortableTable from "components/common/SortableTable"
import Icon from "components/common/Icon"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"
import WithEmptyState from "components/common/WithEmptyState"

import { debounce, omit } from "lodash"
import { useTitle } from "hooks/useTitle"
import { useTranslation } from "react-i18next"
import useSearchFilter from "hooks/useSearchFilter"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { getGuideClients, cleanClients } from "store/clients"
import { collectionAnySelector, collectionSelector } from "store/selectors"

import { CLIENT_FILTERS, CLIENT_FILTERS_HELP_ATTRIBUTES } from "constants/clients"

const DELAY_TIME = 1000

export default function Clients() {
  useTitle("Clients")
  useLoaderWatchers({ watchers: ["clients"] })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectAllRef = useRef(null)
  const [searchBy, setSearchBy] = useState("")
  const clients = useSelector(collectionSelector("clients"))
  const hasClients = useSelector(collectionAnySelector("clients"))
  const [searchParams] = useSearchParams()
  const [params, updateSearchFilters, searchUpdatedCallback] = useSearchFilter({
    accessAttributes: CLIENT_FILTERS,
    exceptDependencies: CLIENT_FILTERS_HELP_ATTRIBUTES
  })
  const selectedIds = params["user_ids[]"] || []
  const isSelectedAll = selectedIds.length && selectedIds.length === clients.length

  function selectHandler(userId) {
    const newIds = selectedIds.includes(+userId) ? selectedIds.filter((id) => +id !== +userId) : selectedIds.concat(+userId)
    const user_ids = newIds.length ? newIds : null
    updateSearchFilters({ "user_ids[]": user_ids })
  }

  const selectAllHandler = () => {
    const user_ids = selectedIds.length ? null : clients.map((i) => i.user.id)
    updateSearchFilters({ "user_ids[]": user_ids })
  }

  useEffect(() => {
    if (selectAllRef.current) selectAllRef.current.indeterminate = selectedIds.length !== clients.length && selectedIds.length
  }, [selectedIds.length, clients.length]) //eslint-disable-line

  // eslint-disable-next-line
  const debounceSetFullNameFilter = useCallback(
    debounce((value) => updateSearchFilters({ user_first_name_or_user_last_name_cont: value }), DELAY_TIME),
    []
  )

  const searchFieldHandler = ({ target }) => setSearchBy(target.value) || debounceSetFullNameFilter(target.value || null)

  const fetchClients = () => dispatch(getGuideClients(null, { q: omit(params, CLIENT_FILTERS_HELP_ATTRIBUTES) }))

  searchUpdatedCallback(() => fetchClients())

  useEffect(() => {
    return () => dispatch(cleanClients())
  }, []) //eslint-disable-line

  const headers = useMemo(
    () => {
      return [
        {
          content: ""
        },
        {
          content: <Sort buttonText="NAME" sortBy="sort_by_user_full_name" />,
          thProps: { className: "h6 fw-medium lh-1" }
        },
        {
          content: <Groups />,
          thProps: { className: "h6 fw-medium lh-1" }
        },
        {
          content: "EMAIL",
          thProps: { className: "h6 fw-medium lh-1" }
        },
        {
          content: "PHONE",
          thProps: { className: "h6 fw-medium lh-1" }
        },
        {
          content: <Sort buttonText="LAST TRIP" sortBy="sort_by_last_trip_date" />,
          thProps: { className: "h6 fw-medium lh-1" }
        },
        {
          content: <Sort buttonText="YTD SPEND" sortBy="sort_by_total_booking_amount" />,
          thProps: { className: "h6 fw-medium lh-1" }
        }
      ]
    },
    [] //eslint-disable-line
  )

  return (
    <div className="vstack flex-fill z-0 p-20" id="clients">
      <FirstLoading name="clients">
        <div className="flex-fill">
          <div>
            <div className="hstack gap-20 align-items-center p-20">
              <div className="hstack">
                <Label check className="d-inline-flex fs-7 fw-normal ">
                  <Input
                    type="checkbox"
                    checked={isSelectedAll}
                    onChange={selectAllHandler}
                    className="mt-0 mx-10"
                    innerRef={selectAllRef}
                  />
                  {!selectedIds.length && <span className="text-dark text-opacity-50">{"Select All"}</span>}
                </Label>
                {selectedIds.length > 0 && (
                  <Dropdown
                    className="my-n1"
                    toggleButton={
                      <DropdownToggle
                        color="link"
                        className="hstack gap-2 m-0 p-0 fs-7 fw-normal text-decoration-none text-opacity-75-hover text-dark"
                      >
                        <span>
                          {isSelectedAll && "All "}
                          {selectedIds.length} Selected
                        </span>
                        <Icon iconName="SidebarToggle" size={10} className="opacity-50" />
                      </DropdownToggle>
                    }
                  >
                    <DropdownItem tag={Link} to={routes.guideClientEmailActionPath({}, searchParams)}>
                      {"Email Selection"}
                    </DropdownItem>
                    <DropdownItem tag={Link} to={routes.guideClientTextActionPath({}, searchParams)}>
                      {"Text Selection"}
                    </DropdownItem>
                    <DropdownItem tag={Link} to={routes.guideClientMessageActionPath({}, searchParams)}>
                      {"Message Selection"}
                    </DropdownItem>
                  </Dropdown>
                )}
              </div>
              <Input
                id="search"
                type="text"
                placeholder="Search Client"
                value={searchBy}
                onChange={searchFieldHandler}
                className="w-25 fs-6 rounded-pill"
                style={{ maxWidth: 230 }}
              />
              <Button tag={Link} color="primary" className="px-20 py-10 fs-7 ms-auto my-n1" to={routes.guideClientNewPath()}>
                {t("clients.add_client")}
              </Button>
            </div>

            <WithEmptyState name="client" hasItems={hasClients} className="vstack gap-20 p-20">
              <SortableTable rows={clients} selectRow={selectHandler} selectedIds={selectedIds} headers={headers} />
            </WithEmptyState>
          </div>
        </div>

        <DefaultErrorBoundary>
          <Outlet />
        </DefaultErrorBoundary>
      </FirstLoading>
    </div>
  )
}
