import React, { useMemo } from "react"
import styles from "./SubHeader.module.css"
import { Title } from "ui/Typography"
import { Progress } from "antd"
import { useSelector } from "react-redux"

export const SubHeader = () => {
  const { menuItems } = useSelector((state) => state.guideUserInfo)
  const menuItemsFlat = useMemo(() => menuItems.flatMap((menuItem) => menuItem.items), [menuItems])
  const completedItems = useMemo(() => menuItemsFlat.filter((menuItem) => menuItem.complete), [menuItemsFlat])
  const progressValue = useMemo(() => Math.round((completedItems.length / menuItemsFlat.length) * 100), [completedItems, menuItemsFlat])

  return (
    <div className={styles.subHeader}>
      <div className={styles.titleWrapper}>
        <Title className="mb-0" level={2}>
          Become a Pro
        </Title>
      </div>
      <div className={styles.progressWrapper}>
        <Progress
          percent={progressValue}
          format={(percent) => {
            return (
              <div className={styles.progressText}>
                {percent >= 25 && <div className={styles.progressTitle}>completion rate</div>}
                <div className={styles.progressNumber}>{percent}%</div>
              </div>
            )
          }}
          size={["", 38]}
          percentPosition={{ align: "end", type: "inner" }}
        />
      </div>
    </div>
  )
}
