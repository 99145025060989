import React, { memo, useMemo } from "react"
import PropTypes from "prop-types"

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Pie } from "react-chartjs-2"

import { find } from "lodash"

import { useSelector } from "react-redux"
import { useTheme } from "context/AntdThemeContext"
import { colorsRGB } from "themes/variables"

ChartJS.register(ArcElement, Tooltip, Legend)

const bgColor = (index, size, primaryColorRGB) => `rgba(${primaryColorRGB}, ${1 - index / (size + 1)})`

const chartData = (data) => ({
  labels: data.labels,
  datasets: [
    {
      label: "$",
      data: data.data,
      backgroundColor: data.backgroundColor
    }
  ]
})

export const options = {
  devicePixelRatio: 2,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "right",
      labels: {
        color: "#141414",
        font: { size: 10 },
        boxWidth: 8,
        boxHeight: 8,
        pointStyle: "circle",
        usePointStyle: true,
        padding: 4,
        sort: (a, b, d) => d.datasets[0].data[b.index] - d.datasets[0].data[a.index]
      }
    }
  }
}

ExpensesCategoriesPie.propTypes = {
  range: PropTypes.string.isRequired,
  className: PropTypes.string
}

ExpensesCategoriesPie.defaultProps = {
  className: ""
}

function ExpensesCategoriesPie({ range, className }) {
  const { isSportsman } = useTheme()
  const { metadata } = useSelector((store) => store.accounting)
  const { expense_category_stats, expense_categories } = metadata
  const categories_stats = useMemo(() => expense_category_stats[range] || {}, [expense_category_stats, range])
  const categoriesValuesSorted = useMemo(() => Object.values(categories_stats).sort((a, b) => b - a), [categories_stats])
  const primaryColorRGB = isSportsman ? colorsRGB.sportsmanBrand : colorsRGB.sportswomanBrand

  const rawData = useMemo(
    () =>
      Object.entries(categories_stats).reduce(
        (data, [category_id, total]) => {
          const category = find(expense_categories, ["id", +category_id])
          const index = categoriesValuesSorted.indexOf(total)
          data.labels.push(category.name)
          data.data.push(+total)
          data.backgroundColor.push(bgColor(index, categoriesValuesSorted.length, primaryColorRGB))
          return data
        },
        { labels: [], data: [], backgroundColor: [], borderColor: [] }
      ),
    [categories_stats, expense_categories, categoriesValuesSorted, primaryColorRGB]
  )

  const data = useMemo(() => chartData(rawData), [rawData])

  const classes = ["chart-container position-relative"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")} style={{ height: 140 }}>
      <Pie data={data} options={options} />
    </div>
  )
}

export default memo(ExpensesCategoriesPie)
