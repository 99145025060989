import React from "react"
import PropTypes from "prop-types"
import DateDetails from "components/bookings/DateDetails"

import moment from "moment"
import { groupBy } from "lodash"

import { useSelector } from "react-redux"
import { collectionSelector } from "store/selectors"

import { DATE_FORMAT, MONTH_FORMAT } from "modules/datepicker/constants"
import BookingRequests from "./BookingRequests"

Details.propTypes = {
  params: PropTypes.shape({
    type: PropTypes.string.isRequired,
    sort: PropTypes.string.isRequired
  }).isRequired
}

export default function Details({ params }) {
  const bookingsData = useSelector(collectionSelector("bookings"))
  const isGroupByMonth = ["all", "past"].includes(params.type)
  const { bookings, bookingRequested } = bookingsData.reduce(
    (acc, booking) => {
      if (booking.status === "requested") {
        acc.bookingRequested.push(booking)
      } else {
        acc.bookings.push(booking)
      }
      return acc
    },
    { bookings: [], bookingRequested: [] }
  )
  const format = isGroupByMonth ? MONTH_FORMAT : DATE_FORMAT
  const groupedItems = groupBy(bookings, ({ booked_date }) => moment(booked_date).format(format))
  const groups = Object.keys(groupedItems).sort((a, b) => ((params.sort === "asc" ? a < b : a > b) ? -1 : 1))

  return (
    <div className="vstack gap-20 p-20">
      {bookingRequested.length > 0 && <BookingRequests bookingRequestsData={bookingRequested} />}
      {groups.map((groupedDate, index) => (
        <DateDetails key={index} date={groupedDate} bookings={groupedItems[groupedDate]} isGroupByMonth={isGroupByMonth} />
      ))}
    </div>
  )
}
