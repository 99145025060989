import React from "react"
import FacebookLogin from "@greatsumini/react-facebook-login"
import { useDispatch, useSelector } from "react-redux"
import { LinkedAccount } from "./LinkedAccount"
import facebookIcon from "assets/imagesV2/social/facebook-squared.svg"
import { connectOAuthProvider } from "store/user"

const provider = "facebook"

export const FacebookConnect = () => {
  const dispatch = useDispatch()
  const { connected_oauth_providers } = useSelector((state) => state.user)
  const connected = connected_oauth_providers?.includes(provider)

  const onSuccess = (response) => {
    const code = response.accessToken
    if (code) {
      dispatch(connectOAuthProvider({ provider, code }))
    }
  }

  const onFail = (response) => {
    // implement error handling
    console.error(response)
  }
  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      fields="name,email,picture"
      onSuccess={onSuccess}
      onFail={onFail}
      render={({ onClick }) => {
        return <LinkedAccount icon={facebookIcon} account="Facebook" provider={provider} connectOAuth={onClick} connected={connected} />
      }}
    />
  )
}
