import React from "react"
import { Link, NavLink, useLocation, useParams } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import { Button, DropdownToggle } from "reactstrap"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import Icon from "components/common/Icon"

import { useTranslation } from "react-i18next"


export default function Header() {
  const { t } = useTranslation()
  const params = useParams()

  const location = useLocation()

  const pages = {
    [oldRoutes.guideMarketingTemplatesPath()]: "templates",
    [oldRoutes.guideMarketingPath()]: "feeder",
    [oldRoutes.guideMarketingPostNewPath()]: "feeder",
    [oldRoutes.guideMarketingPostEditPath(params)]: "feeder"
  }

  const activePage = pages[location.pathname]

  return (
    <div className="sticky-top bg-white">
      <div className="flex-align-center py-10 py-sm-20 px-20 flex-wrap">
        <div className="hstack gap-30 justify-content-end my-3 my-md-0">
          <h1 className="h3 fw-medium lh-1">{t("marketing.marketing")}</h1>
          <Dropdown
            className="my-n1"
            toggleButton={
              <DropdownToggle color="primary-second" outline className="hstack gap-2 rounded-pill py-2 px-15">
                <span className="fs-7 fw-medium lh-1">{t(`marketing.${activePage}`)}</span>
                <Icon iconName="SidebarToggle" size={14} />
              </DropdownToggle>
            }
          >
            <DropdownItem tag={NavLink} to={oldRoutes.guideMarketingPath()} end>
              {t("marketing.feeder")}
            </DropdownItem>
            <DropdownItem tag={NavLink} to={oldRoutes.guideMarketingTemplatesPath()}>
              {t("marketing.templates")}
            </DropdownItem>
          </Dropdown>
        </div>
        {activePage === "feeder" ? (
          <Button
            tag={Link}
            color="primary"
            className="px-20 py-10 fs-7 ms-auto my-2 my-md-n1 w-100 w-sm-auto"
            to={oldRoutes.guideMarketingPostNewPath()}
          >
            {t("posts.add_new")}
          </Button>
        ) : (
          <Button
            tag={Link}
            color="primary"
            className="px-20 py-10 fs-7 ms-auto my-2 my-md-n1"
            to={oldRoutes.guideMarketingTemplatesParentsPath()}
          >
            {t("marketing.create_template")}
          </Button>
        )}
      </div>
    </div>
  )
}
