import { useState, useRef } from "react"
import { Col, Row } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

// context
import { useTheme } from "context/AntdThemeContext"
// ui
import { Title, Text } from "ui/Typography"
import { Card } from "ui/AdventureModeSelect/Card"
import { ActionButtons } from "ui/ActionButtons"
// constants
import { themes } from "constants/theme"
// store
import { updateUser } from "store/user"
import { SettingsTitle } from "../ProSettings/SettingsTitle"

export const AdventureMode = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { theme, switchTheme, saveThemeToStorage } = useTheme()
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.auth)
  const [mode, setMode] = useState(theme)
  const prevModeRef = useRef(mode)

  const onSelect = (theme) => {
    setMode(theme)
    switchTheme(theme)
  }

  const handleNavigateBack = () => {
    navigate(-1)
  }

  const handleCancel = () => {
    if (prevModeRef.current !== mode) {
      switchTheme(themes[prevModeRef.current])
    }

    handleNavigateBack()
  }

  const submitAdventureMode = () => {
    dispatch(updateUser({ adventure_mode: mode })).then(() => {
      handleNavigateBack()
      saveThemeToStorage(mode)
    })
  }

  return (
    <div className="flex-column">
      <div className="flex-column">
        <SettingsTitle title={t("select_adventure_mode.title")} subTitle={t("select_adventure_mode.subtitle")} />
        <div className="justify-center pt-4xl">
          <Row gutter={24} className="w-full">
            <Col span={12}>
              <Card
                type={themes.sportsman}
                bordered={false}
                selected={mode === themes.sportsman}
                onSelect={() => onSelect(themes.sportsman)}
                isRadioButton
              />
            </Col>
            <Col span={12}>
              <Card
                type={themes.sportswoman}
                bordered={false}
                selected={mode === themes.sportswoman}
                onSelect={() => onSelect(themes.sportswoman)}
                isRadioButton
              />
            </Col>
          </Row>
        </div>

        <div className="flex gap-s mt-4xl">
          <ActionButtons onCancelClick={handleCancel} onSaveClick={submitAdventureMode} loading={loading} />
        </div>
      </div>
    </div>
  )
}
