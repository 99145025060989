const hosts = {
  local: {
    PRO: "localhost:3000",
    PRO_LANDING: "dspro.guide",
    MEN: "",
    WOMEN: "",
    CUSTOMER: "localhost:3002",
    PROTOCOL: "http://"
  },
  dev: {
    PRO: "dev.dspro.guide",
    PRO_LANDING: "dspro.guide",
    MEN: "dev.digitalsportsmen.com",
    WOMEN: "dev.digitalsportswomen.com",
    CUSTOMER: "dev-web.ds.guide",
    PROTOCOL: "https://"
  },
  qa: {
    PRO: "qa.dspro.guide",
    PRO_LANDING: "dspro.guide",
    MEN: "qa.digitalsportsmen.com",
    WOMEN: "qa.digitalsportswomen.com",
    CUSTOMER: "qa-web.ds.guide",
    PROTOCOL: "https://"
  },
  prod: {
    PRO: "manage.dspro.guide",
    PRO_LANDING: "dspro.guide",
    MEN: "digitalsportsmen.com",
    WOMEN: "digitalsportwomen.com",
    CUSTOMER: "ds.guide",
    PROTOCOL: "https://"
  }
}

export default hosts
