import React, { useState } from "react"
import { Col, Row, Form } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { serialize } from "object-to-formdata"

// layouts
import { ContentActionButtons } from "layoutsV2/pro/PersonalInfoLayout/ContentActionButtons"
// components
import { AddressesHeader } from "./Header"
import { AddressesSearch } from "./Search"
import { CustomAddress } from "./CustomAddress"
import { ProfilePreview } from "ui/PersonalInfo/ProfilePreview"
// store
import { guideSelector, updateGuide } from "store/user"

export const PersonalInfoAddresses = () => {
  const [manualAddressEnabled, setManualAddressEnabled] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { id, street_address, state, country, city, zip_code, ...guide } = useSelector(guideSelector)
  const initialValues = {
    street_address,
    state,
    country,
    city,
    zip_code
  }

  const onFinish = (formData, navigateTo) => {
    const serializedFormData = serialize({ guide: formData }, { indices: true })
    dispatch(updateGuide(id, serializedFormData)).then(() => navigateTo())
  }

  const handlePreviewToggle = () => setShowPreview((prev) => !prev)

  return (
    <>
      <Row>
        <Col lg={16} md={24} xs={24} span={16}>
          <AddressesHeader onPreview={handlePreviewToggle} />
          {manualAddressEnabled ? (
            <CustomAddress backToSearch={() => setManualAddressEnabled(false)} form={form} initialValues={initialValues} />
          ) : (
            <AddressesSearch setManualAddressEnabled={() => setManualAddressEnabled(true)} form={form} initialValues={initialValues} />
          )}
        </Col>
        <Col lg={16} md={24} xs={24} span={16}>
          <ContentActionButtons form={form} onFinish={onFinish} />
        </Col>
      </Row>
      <ProfilePreview
        show={showPreview}
        tab="about"
        guide={{
          ...guide,
          display_location: `${form.getFieldValue("city")}, ${form.getFieldValue("country")}`
        }}
        onClose={handlePreviewToggle}
      />
    </>
  )
}
