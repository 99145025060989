import React, { useEffect, useRef } from "react"
import styles from "./Header.module.css"
import bgMan from "assets/imagesV2/logo_man.svg"
import bgWoman from "assets/imagesV2/logo_woman.svg"
import { useTheme } from "context/AntdThemeContext"
import { themes } from "constants/theme"
import { Avatar, Button, Flex, Layout } from "antd"
import { useTranslation } from "react-i18next"
import { Link, Outlet } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useCable } from "components/Cable"
import { signOut } from "store/auth"
import { Icon } from "ui/Icon"
import { receivedWSConversation } from "store/conversations"
import { setProHomeMenuOpen } from "store/pro-home-menu"
import { routes } from "router"
import { channels } from "constants/cable"
import { colors, spacings } from "themes/variables"

export const ProHeader = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const cable = useCable()
  const dispatch = useDispatch()
  const { avatar } = useSelector((state) => state.user)
  const logoSrc = theme === themes.sportswoman ? bgWoman : bgMan
  const chanel = useRef(null)
  const messagesBoxScrollSaver = useRef(null)

  const logoutBtnClickHandler = () => {
    dispatch(signOut())
  }

  const menuBtnClickHandler = () => {
    dispatch(setProHomeMenuOpen())
  }

  const beforeAction = (action) => {
    if (action.type === receivedWSConversation.type && messagesBoxScrollSaver.current) messagesBoxScrollSaver.current.saveScroll()
  }

  const afterAction = (action, response) => {
    if (action.type === receivedWSConversation.type && messagesBoxScrollSaver.current)
      messagesBoxScrollSaver.current.scrollToSaved((savedScrolls) => (savedScrolls.bottom === 0 ? "bottom" : "top"))
  }

  const receivedHandler = (beforeAction, afterAction) => (action) => {
    beforeAction?.(action)
    const dispatchResult = dispatch(action)

    if (dispatchResult instanceof Promise) dispatchResult.then((res) => afterAction(action, res))
    else afterAction(action)
  }

  useEffect(() => {
    console.log("WS_ON", process.env.REACT_APP_WS_ON)
    if (!cable || [false, "false"].includes(process.env.REACT_APP_WS_ON)) return
    chanel.current = cable.subscriptions.create({ channel: channels.rootChannel }, { received: receivedHandler(beforeAction, afterAction) })
    return () => chanel.current?.unsubscribe()
  }, [cable]) //eslint-disable-line

  return (
    <Layout className={styles.layout}>
      <div className={styles.header}>
        <Icon onClick={menuBtnClickHandler} className={styles.menuIcon} name="menu" color="currentColor" />
        <Link to={routes.guideDashboardPath()} className={styles.logo}>
          <img className={styles.logoImage} src={logoSrc} alt="" />
        </Link>
        <Flex gap={spacings["3XL"]} align="center">
          <Button className={styles.logoutBtn} color="primary" variant="outlined" size="large" onClick={logoutBtnClickHandler}>
            {t("header.log_out")}
          </Button>
          <Link to={routes.proSettingsProfileInformation()}>
            <Avatar
              src={avatar?.includes("localhost") ? avatar.replace("https://", "http://") : avatar}
              style={{
                border: `1.5px solid ${colors.lines}`,
                flexShrink: 0
              }}
              className={styles.avatar}
            />
          </Link>
        </Flex>
      </div>
      <Outlet />
    </Layout>
  )
}
