import React, { useEffect } from "react"
import { Container } from "reactstrap"
import TripLocation from "components/trips/Location"

import { useTitle } from "hooks/useTitle"

import { useDispatch, useSelector } from "react-redux"
import { getTrip } from "store/trips"
import { modelSelector } from "store/selectors"

export default function Location() {
  const dispatch = useDispatch()
  const guide = useSelector(modelSelector("guide"))
  const tripId = guide.popular_trips[0]?.id
  useTitle(guide.display_name)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(getTrip(tripId))
  }, [tripId]) //eslint-disable-line

  return (
    <Container>
      <TripLocation height={660} />
    </Container>
  )
}
