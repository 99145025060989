import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col } from "antd"
import { guideSelector, updateGuide } from "store/user"
import { serialize } from "object-to-formdata"
import { DocumentUploadGrid } from "ui/DocumentUploadGrid"
import { mapLicenses, serializeLicensesData } from "helpers/files"
import { ContentActionButtons } from "layoutsV2/pro/PersonalInfoLayout/ContentActionButtons"
import { notification } from "ui/Notification"
import { useTranslation } from "react-i18next"

export const PersonalInfoLicenses = () => {
  const { t } = useTranslation()
  const guide = useSelector(guideSelector)
  const dispatch = useDispatch()
  const licenses = mapLicenses(guide.documents)
  const formMock = {
    isFieldsTouched: function () {
      return true
    },
    getFieldsValue: function () {}
  }

  const [filesState, setFilesState] = useState({
    ...licenses
  })

  const handleFileListChange = (name, newFileList) => {
    setFilesState((prevState) => ({
      ...prevState,
      [name]: newFileList
    }))
  }

  const handleSubmit = (data, navigateTo) => {
    const formData = serialize({ guide: serializeLicensesData(filesState) }, { indices: true })
    dispatch(updateGuide(guide.id, formData))
      .then(() => navigateTo())
      .catch(() => {
        notification.error({
          message: t("user.guide_update_error")
        })
      })
  }

  return (
    <Row>
      <Col sm={24} md={20}>
        <DocumentUploadGrid handleFileListChange={handleFileListChange} filesState={filesState} />
      </Col>
      <Col lg={16} md={24} xs={24} span={16}>
        <ContentActionButtons form={formMock} onFinish={handleSubmit} />
      </Col>
    </Row>
  )
}
