import React from "react"
import styles from "./MenuItem.module.css"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import { Icon } from "ui/Icon"
import { setProHomeMenuClose } from "store/pro-home-menu"

export const MenuItem = ({ item }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { guide } = useSelector((state) => state.user)
  const { activeMenu } = useSelector((state) => state.proHomeMenu)
  const { name, id, iconName, iconType, path } = item
  const isActive = activeMenu === id

  const onClickHandler = () => {
    dispatch(setProHomeMenuClose())
    if (typeof path === "string") {
      navigate(path)
    } else if (typeof path === "function") {
      const dynamicPath = path({ slug: guide.slug })
      navigate(dynamicPath)
    }
  }

  return (
    <div className={classNames(styles.menuGroupItem, isActive && styles.menuGroupItemActive)} onClick={onClickHandler}>
      <Icon name={iconName} type={iconType} strokeWidth={1} className={styles.itemIcon} color="currentColor" />
      <div className={styles.itemName}>{name}</div>
    </div>
  )
}
