import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { Button, Label } from "reactstrap"
import Input from "components/form/Input"
import PhotoSelector from "components/form/PhotoSelector"
import ArrayInput from "components/form/ArrayInput"
import AutofillInput from "components/form/AutofillInput"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import useForm, { cleanNestedAttributes, useNestedFields } from "hooks/useForm"
import { map } from "lodash"

import { useDispatch, useSelector } from "react-redux"
import { saveBoat, getCustomInfo, getSafety } from "store/boats"
import { collectionSelector, modelSelector } from "store/selectors"
import { firstLoadingSelector, loadingSelector } from "modules/loader-watchers/selectors"

const Form = ({ onChanged, closeHandler }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const boat = useSelector(modelSelector("boat"))

  const isBoatPersisted = !!boat?.id

  const custom_info = useMemo(
    () => (boat.custom_info || []).map((custom_info, index) => ({ _id: index + 1, name: custom_info })),
    [boat.custom_info]
  )
  const safety = useMemo(() => (boat.safety || []).map((safety, index) => ({ _id: index + 1, name: safety })), [boat.safety])

  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm(
    { ...boat, photos: boat.properties, custom_info, safety },
    ["name", "description", "custom_info", "safety", "photos"]
  )
  const [, , addCustomInfoHandler, removeCustomInfoHandler] = useNestedFields(form, "custom_info", [], changeHandler)
  const [, , addSafetyHandler, removeSafetyHandler] = useNestedFields(form, "safety", [], changeHandler)

  const custom_info_list = useSelector(collectionSelector("boats.custom_info"))
  const customInfoFirstLoading = useSelector(firstLoadingSelector("boats.custom_info"))
  const customInfoLoading = useSelector(loadingSelector("boats.custom_info"))
  const custom_info_selected = useMemo(() => map(form.custom_info, "name"), [form.custom_info])

  useEffect(() => {
    if (customInfoFirstLoading) dispatch(getCustomInfo())
  }, [customInfoFirstLoading]) //eslint-disable-line

  const safety_list = useSelector(collectionSelector("boats.safety"))
  const safetyLoadingsFirstLoading = useSelector(firstLoadingSelector("boats.safety"))
  const safetyLoading = useSelector(loadingSelector("boats.safety"))
  const safety_selected = useMemo(() => map(form.safety, "name"), [form.safety])

  useEffect(() => {
    if (safetyLoadingsFirstLoading) dispatch(getSafety())
  }, [safetyLoadingsFirstLoading]) //eslint-disable-line

  submitCallback(() => {
    const properties = form.photos.map((file) => ({ ...file, file: file.file }))
    const properties_attributes = cleanNestedAttributes(properties, ["file"])

    const boatAttributes = { ...form, custom_info: custom_info_selected, safety: safety_selected }
    delete boatAttributes.photos

    let formData = serialize({ boat: boatAttributes })
    formData = serialize({ boat: { properties_attributes } }, { indices: true }, formData)

    dispatch(saveBoat(boat.id, formData)).then(closeHandler)
  })

  useEffect(() => {
    if (typeof onChanged === "function") onChanged(isChanged)
  }, [isChanged, onChanged])

  return (
    <FirstLoading name="boats.boat" new>
      <form onSubmit={submitHandler}>
        <div className="vstack gap-20">
          <div className="hstack align-items-center gap-20 bg-white rounded p-20 sticky-top">
            <h1 className="h3 fw-medium lh-1">{t(`boat.${isBoatPersisted ? "edit" : "add"}`)}</h1>
            <div className="hstack gap-10 my-n1 ms-auto">
              <Button color="light" className="fs-7" onClick={closeHandler}>
                {t("global.cancel")}
              </Button>
              <Button color="primary" className="fs-7" disabled={!isChanged}>
                {t("global.save")}
              </Button>
            </div>
          </div>
          <div className="vstack gap-30 bg-white rounded p-20 ">
            <div className="grid grid-cols-1 grid-cols-xsm-2 gap-20">
              <div>
                <Label for="name" className="fs-6">
                  {t("boat.name")}
                </Label>
                <Input
                  id="name"
                  type="text"
                  name="name"
                  placeholder={t("boat.name_placeholder")}
                  value={form.name || ""}
                  onChange={changeHandler}
                  className="fs-6"
                  withError
                />
              </div>
            </div>
            <div>
              <Label for="description" className="fs-6">
                {t("boat.description")}
              </Label>
              <Input
                id="description"
                type="textarea"
                name="description"
                rows={10}
                placeholder={t("boat.description_placeholder")}
                value={form.description || ""}
                onChange={changeHandler}
                className="fs-6"
                withError
              />
            </div>
            <div>
              <Label className="fs-6">{t("boat.images")}</Label>
              <PhotoSelector imageHeight={104} form={form} changeHandler={changeHandler} max={4} small start label={false} />
            </div>
            <div>
              <h3 className="h5 mb-1">{t("boat.custom_info")}</h3>
              <ArrayInput
                ipnutTag={AutofillInput}
                form={form}
                name="custom_info"
                changeHandler={changeHandler}
                addHandler={addCustomInfoHandler}
                removeHandler={removeCustomInfoHandler}
                placeholder={""}
                className="grid-cols-sm-2 grid-cols-lg-3"
                suggestions={{
                  list: custom_info_list,
                  selected: custom_info_selected,
                  valueKey: "name",
                  withSearch: true,
                  loading: customInfoLoading.loading
                }}
              />
            </div>
            <div>
              <h3 className="h5 mb-1">{t("boat.safety")}</h3>
              <ArrayInput
                ipnutTag={AutofillInput}
                form={form}
                name="safety"
                changeHandler={changeHandler}
                addHandler={addSafetyHandler}
                removeHandler={removeSafetyHandler}
                placeholder={""}
                className="grid-cols-sm-2 grid-cols-lg-3"
                suggestions={{
                  list: safety_list,
                  selected: safety_selected,
                  valueKey: "name",
                  withSearch: true,
                  loading: safetyLoading.loading
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </FirstLoading>
  )
}

Form.propTypes = {
  onChanged: PropTypes.func,
  closeHandler: PropTypes.func.isRequired
}

export default Form
