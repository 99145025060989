import React from "react"

export const Bag = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.59988 1.44C8.81051 1.44 8.15988 2.09063 8.15988 2.88V4.32H1.43988C0.650508 4.32 -0.000117187 4.97063 -0.000117187 5.76V12.87C-0.00386719 12.9 -0.00386719 12.93 -0.000117187 12.96V21.12C-0.000117187 21.9094 0.650508 22.56 1.43988 22.56H22.5599C23.3493 22.56 23.9999 21.9094 23.9999 21.12V5.76C23.9999 4.97063 23.3493 4.32 22.5599 4.32H15.8399V2.88C15.8399 2.09063 15.1893 1.44 14.3999 1.44H9.59988ZM9.59988 2.4H14.3999C14.6699 2.4 14.8799 2.61 14.8799 2.88V4.32H9.11988V2.88C9.11988 2.61 9.32988 2.4 9.59988 2.4ZM1.43988 5.28H22.5599C22.8299 5.28 23.0399 5.49 23.0399 5.76V12.885C23.0399 12.9 23.0399 12.915 23.0399 12.93V12.96C23.0399 13.23 22.8299 13.44 22.5599 13.44H1.43988C1.16988 13.44 0.959883 13.23 0.959883 12.96C0.963633 12.93 0.963633 12.9 0.959883 12.87V5.76C0.959883 5.49 1.16988 5.28 1.43988 5.28ZM11.9999 10.56C11.4693 10.56 11.0399 10.9894 11.0399 11.52C11.0399 12.0506 11.4693 12.48 11.9999 12.48C12.5305 12.48 12.9599 12.0506 12.9599 11.52C12.9599 10.9894 12.5305 10.56 11.9999 10.56ZM0.959883 14.31C1.11176 14.3644 1.27113 14.4 1.43988 14.4H22.5599C22.7286 14.4 22.888 14.3644 23.0399 14.31V21.12C23.0399 21.39 22.8299 21.6 22.5599 21.6H1.43988C1.16988 21.6 0.959883 21.39 0.959883 21.12V14.31Z"
      fill={fill}
    />
  </svg>
)
