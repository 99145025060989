import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button, Modal } from "antd"
import { guideSelector, updateGuide } from "store/user"
import { serialize } from "object-to-formdata"
import { useDevice } from "hooks/useDevice"
import { DocumentUploadGrid } from "ui/DocumentUploadGrid"
import { mapLicenses, serializeLicensesData } from "helpers/files"
import { notification } from "ui/Notification"

export const ProLicenses = () => {
  const { t } = useTranslation()
  const { loading } = useSelector((state) => state.auth)
  const guide = useSelector(guideSelector)
  const dispatch = useDispatch()
  const licenses = mapLicenses(guide.documents)
  const { isMobile } = useDevice()

  const [filesState, setFilesState] = useState({
    ...licenses
  })

  const handleFileListChange = (name, newFileList) => {
    setFilesState((prevState) => ({
      ...prevState,
      [name]: newFileList
    }))
  }

  const handleSubmit = () => {
    const formData = serialize({ guide: serializeLicensesData(filesState) }, { indices: true })
    dispatch(updateGuide(guide.id, formData))
      .then(() => {
        notification.success({
          message: t("user.guide_success_update")
        })
      })
      .catch(() => {
        notification.error({
          message: t("user.guide_update_error")
        })
      })
  }

  const handleReset = () => {
    Modal.confirm({
      closable: true,
      icon: null,
      content: t("global.discard_changes"),
      onOk: () => {
        setFilesState(licenses)
      }
    })
  }

  return (
    <div className="flex-column pb-5xl">
      <DocumentUploadGrid handleFileListChange={handleFileListChange} filesState={filesState} />
      <div className="flex gap-s mt-4xl">
        <Button variant="outlined" color="primary" size="large" style={{ width: isMobile ? "50%" : "auto" }} onClick={handleReset}>
          {t("global.cancel")}
        </Button>
        <Button
          className="mb-xs"
          type="primary"
          size="large"
          onClick={handleSubmit}
          style={{ width: isMobile ? "50%" : "auto" }}
          disabled={loading}
        >
          {t("settings.update")}
        </Button>
      </div>
    </div>
  )
}
