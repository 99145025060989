import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Col, Row, FormGroup, Label } from "reactstrap"
import Input from "components/form/Input"
import { oldRoutes } from "router/old-routes"
import { Waypoint } from "react-waypoint"
import Trip from "components/trips/Trip"
import Icon from "components/common/Icon"
import WithEmptyState from "components/common/WithEmptyState"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"
import { isLastPage } from "helpers/pagination"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanTrip, createTrip, getGuideTrips, tripsWithoutEmptySelector } from "store/trips"
import { metadataSelector } from "store/selectors"

export default function Trips() {
  useTitle("Trips")
  useLoaderWatchers({ watchers: ["trips"] })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showArchived, setShowArchived] = useState(false)

  const metadata = useSelector(metadataSelector("trips"))
  const tripsWithoutEmpty = useSelector(tripsWithoutEmptySelector)
  const hasTrips = !!tripsWithoutEmpty?.length
  const statusUpdated = useSelector((state) => state.trips.statusUpdated)

  const fetchTrips = (nextPage = false, includeArchived = false) => {
    const params = includeArchived ? { include_archived: true } : {}
    dispatch(getGuideTrips(nextPage, params))
  }

  useEffect(() => {
    dispatch(cleanTrip())
    fetchTrips(false, showArchived)
    return () => dispatch(cleanTrip())
  }, [showArchived, hasTrips, statusUpdated]) //eslint-disable-line

  const createTripHandler = (event) => {
    if (event.persist) event.persist()
    dispatch(createTrip()).then(({ data: trip }) => trip.id && navigate(oldRoutes.guideTripWizardRootPath({ id: trip.id })))
  }

  const toggleArchived = () => setShowArchived(!showArchived)

  return (
    <FirstLoading name="trips">
      <div className="px-40 py-10 pb-30">
        <div className="hstack gap-15 py-10 py-sm-20 ">
          <h1 className="h3 fw-medium">{t("trips.title")}</h1>
          {hasTrips && (
            <Button color="primary" className="px-3 py-2 fs-7" onClick={createTripHandler}>
              {t("trips.add_new")}
            </Button>
          )}
          <FormGroup switch className="hstack ms-auto align-center">
            <Label check for="cash_payment_allowed" className="fs-6">
              {showArchived ? t("trips.hide_archived") : t("trips.show_archived")}
            </Label>
            <Input
              id="show_archived"
              name="show_archived"
              type="checkbox"
              checked={showArchived}
              onChange={toggleArchived}
              className="m-0 ms-20 mb-n1"
            />
          </FormGroup>
        </div>
        <WithEmptyState
          tag={Row}
          name="trip"
          hasItems={hasTrips}
          className="gap-y-20 gx-20 gap-y-xl-35 gx-xl-35 mt-25"
          fallback={
            <Col xs={12} sm={6} xl={3}>
              <div className="bg-white rounded border border-gray-lightest p-4 mt-2">
                <Icon iconName="Trips" className="" size={44} />
                <h4 className="mt-30">{t("trips.empty_message")}</h4>
                <Button color="primary" className="mt-25" block onClick={createTripHandler}>
                  {t("trips.create_now")}
                </Button>
              </div>
            </Col>
          }
        >
          {tripsWithoutEmpty.map((trip, index) => (
            <Col xs={12} lg={6} key={index}>
              <Trip trip={trip} className="h-100" guideView />
            </Col>
          ))}
          {!isLastPage(metadata) && <Waypoint onEnter={() => fetchTrips(true)} />}
        </WithEmptyState>
      </div>
    </FirstLoading>
  )
}
