export const ROUTES_LIST = {
  // temporary
  rootPath: "/",
  antd: "/antd",
  signInV2: "/auth/sign-in",
  signUpV2: "/auth/sign-up",
  newPassword: "/auth/new-password",
  signUpOptions: "/auth/sign-up/options",
  confirmEmail: "/auth/sign-up/confirm-email",
  confirmPhone: "/auth/sign-up/confirm-phone",
  confirmInfo: "/auth/sign-up/confirm-info",
  passwordRecovery: "/auth/password-recovery",
  passwordRecoveryEnterCode: "/auth/password-recovery/enter-code",
  passwordRecoveryCreatePassword: "/auth/password-recovery/create-password",
  passwordRecoverySuccess: "/auth/password-recovery/success",
  homePath: "/home",
  proPersonalInfoRootPath: "/pro/personal-info",
  proPersonalInfoProfileInfo: "/pro/personal-info/profile-information",
  proPersonalInfoAddressesPath: "/pro/personal-info/addresses",
  proPersonalInfAdventureMode: "/pro/personal-info/adventure-mode",
  proPersonalInfoLicenses: "/pro/personal-info/licenses-and-certifications",
  proPersonalInfoStripe: "/pro/personal-info/connect-with-stripe",
  proRootPath: "/pro",
  proHomeRootPath: "/pro/home",
  proSettingsRootPath: "/pro/home/settings",
  proSettingsStripeAccount: "/pro/home/settings/stripe-account",
  proSettingsProfileInformation: "/pro/home/settings/profile-information",
  proSettingsAdventureMode: "/pro/home/settings/adventure-mode",
  proSettingsLicenses: "/pro/home/settings/licenses",
  proSettingsLoginAndSecurity: "/pro/home/settings/login-and-security",
  proMessagesRootPath: "/pro/home/messages",

  // reskin routes
  guideDashboardPath: "/pro/home/dashboard",
  guideDashboardBookingNewPath: "/pro/home/dashboard/bookings/new",
  guideDashboardBookingEditPath: "/pro/home/dashboard/bookings/:id/edit",
  guideDashboardJournalNewPath: "/pro/home/dashboard/journal_notes/new",
  guideDashboardJournalEditPath: "/pro/home/dashboard/journal_notes/:id/edit",

  guideDashboardUnavailableDayManagePath: "/pro/home/dashboard/unavailable_days/manage",
  guideBookingsPath: "/pro/home/bookings",
  guideBookingNewPath: "/pro/home/bookings/new",
  guideBookingEditPath: "/pro/home/bookings/:id/edit",

  guideClientsPath: "/pro/home/clients",
  guideClientNewPath: "/pro/home/clients/new",
  guideClientPath: "/pro/home/clients/:id",
  guideClientEditPath: "/pro/home/clients/:id/edit",
  guideClientEmailActionPath: "/pro/home/clients/email_action",
  guideClientTextActionPath: "/pro/home/clients/text_action",
  guideClientMessageActionPath: "/pro/home/clients/message_action",

  guideTripsPath: "/pro/home/trips",
  guideTripPath: "/pro/home/trips/:id",

  guideBoatsPath: "/pro/home/boats",
  guideAddNewBoatPath: "/pro/home/boats/new",
  guideEditBoatPath: "/pro/home/boats/:id/edit",

  guideMessagesPath: "/pro/home/messages",

  guideAccountingPath: "/pro/home/accounting",
  guideAccountingAddExpancePath: "/pro/home/accounting/new",
  guideAccountingEditExpancePath: "/pro/home/accounting/:id/edit",

  guideMarketingPath: "/pro/home/marketing",
  guideMarketingPostNewPath: "/pro/home/marketing/new",
  guideMarketingPostEditPath: "/pro/home/marketing/:id/edit",
  guideMarketingTemplatesPath: "/pro/home/marketing/templates",
  guideMarketingTemplatesParentsPath: "/pro/home/marketing/templates/parents",
  guideMarketingTemplateNewPath: "/pro/home/marketing/templates/new",
  guideMarketingTemplateEditPath: "/pro/home/marketing/templates/:id/edit",

  guideFaqPath: "/pro/home/faqs",
  contactSupportPath: "/pro/home/contact-support",

  publicGuidePath: "/pro/home/preview/:slug",
  publicGuideTripsPath: "/pro/home/preview/:slug/trips",
  publicGuideLocationPath: "/pro/home/preview/:slug/location",
  publicGuideBoatsPath: "/pro/home/preview/:slug/boats",
  publicGuidePostsPath: "/pro/home/preview/:slug/posts",
  publicGuideReviewsPath: "/pro/home/preview/:slug/reviews"
  // Common routes
}

export const dynamicRoute = (path) => {
  const requiredParams = path.match(/:\w+/g) || []
  const route = (params = {}, search = {}) => {
    const searchString = new URLSearchParams(search).toString()
    if (!Object.keys(params).length) return searchString ? `${path}?${searchString}` : path

    const errors = []
    const dynamicPath = requiredParams.reduce((link, param) => {
      const receivedParam = params[param.replace(":", "")]
      if (receivedParam !== undefined) return link.replace(param, receivedParam)
      else errors.push(`"${param}" param is required`)
      return link
    }, path)

    if (errors.length) {
      console.error(`For path "${path}" ${errors.join(", ")}`)
    }

    return searchString ? `${dynamicPath}?${searchString}` : dynamicPath
  }
  return route
}

export const routes = Object.keys(ROUTES_LIST).reduce(
  (acc, routeAlias) => ({ ...acc, [routeAlias]: dynamicRoute(ROUTES_LIST[routeAlias]) }),
  {}
)
