import React, { useState, useEffect } from "react"
import { timeTo12 } from "helpers/time"
import { useTranslation } from "react-i18next"
import { Button } from "reactstrap"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
import { hideModal } from "modules/modals/reducer"
import { confirmClientBooking, deleteClientBooking } from "store/bookings"

export default function BookingRequests({ bookingRequestsData = [] }) {
  const [body, setBody] = useState({ tripName: "", tripDate: "" })
  const [bookingId, setBookingId] = useState(null)
  const [shouldTriggerAccept, setShouldTriggerAccept] = useState(false)
  const [shouldTriggerDeny, setShouldTriggerDeny] = useState(false)

  const AcceptBookingRequestsModal = useConfirmModal()
  const closeAcceptHandler = () => dispatch(hideModal("AcceptBookingRequestsModal"))
  const DenyBookingRequestsModal = useConfirmModal()
  const closeDenyHandler = () => dispatch(hideModal("DenyBookingRequestsModal"))
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const acceptBookingRequestsModalHandler = AcceptBookingRequestsModal(
    {
      title: t("bookings.trip_added_to_calendar", { tripName: body.tripName, tripDate: body.tripDate }),
      color: "primary",
      reverseButtons: true,
      submitText: t("global.ok"),
      hasCancelButton: false
    },
    () => dispatch(confirmClientBooking(bookingId)).then(closeAcceptHandler)
  )

  const denyBookingRequestsModalHandler = DenyBookingRequestsModal(
    {
      title: "Are you sure you want to cancel this Booking?",
      color: "primary",
      reverseButtons: true,
      customSize: "lg",
      hasBody: true,
      tripName: body.tripName,
      tripDate: body.tripDate,
      submitText: t("global.yes"),
      cancelText: t("bookings.no_go_back")
    },
    () => dispatch(deleteClientBooking(bookingId)).then(closeDenyHandler)
  )

  useEffect(() => {
    if (shouldTriggerAccept) {
      acceptBookingRequestsModalHandler()
      setShouldTriggerAccept(false)
    }
  }, [shouldTriggerAccept, body, bookingId, acceptBookingRequestsModalHandler])

  useEffect(() => {
    if (shouldTriggerDeny) {
      denyBookingRequestsModalHandler()
      setShouldTriggerDeny(false)
    }
  }, [shouldTriggerDeny, body, bookingId, denyBookingRequestsModalHandler])
  return (
    <div className="mb-5">
      <div className="text-color-primary mb-5">
        <span className="fw-medium me-2">{t("bookings.booking_request")}</span>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip className="pointer-events-none">
              <p className="m-0">These clients booked 24h before trip starts. You need to manually approve or deny this booking.</p>
            </Tooltip>
          }
        >
          {({ ref, ...triggerHandler }) => (
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...triggerHandler}>
              <circle cx="7.5" cy="7.5" r="7" stroke="#1D72F4" />
              <path
                d="M7.9544 5.87061V11.1538H7.0462V5.87061H7.9544ZM6.97784 4.46924C6.97784 4.32275 7.02178 4.19906 7.10968 4.09814C7.20082 3.99723 7.33428 3.94678 7.51007 3.94678C7.68259 3.94678 7.81443 3.99723 7.90557 4.09814C7.99998 4.19906 8.04718 4.32275 8.04718 4.46924C8.04718 4.60921 7.99998 4.72965 7.90557 4.83057C7.81443 4.92822 7.68259 4.97705 7.51007 4.97705C7.33428 4.97705 7.20082 4.92822 7.10968 4.83057C7.02178 4.72965 6.97784 4.60921 6.97784 4.46924Z"
                fill="#1D72F4"
              />
            </svg>
          )}
        </OverlayTrigger>
      </div>

      {bookingRequestsData.map((booking) => (
        <div className="bg-white d-flex lign-items-center rounded overflow-hidden mb-2 pt-4 pb-4 px-4 fw-medium" key={booking.id}>
          <div className="flex-grow-1">{timeTo12(booking.trip.start_time)}</div>
          <div className="flex-grow-1">{booking.booked_datetime}</div>
          <div className="flex-grow-2" style={{ width: "40%" }}>
            {booking.trip.title}
          </div>
          <div className="flex-grow-1" style={{ width: "10%" }}>
            {booking.adult_count} Adults
          </div>
          <div className="d-flex">
            <Button
              className="fs-7"
              color="primary"
              onClick={() => {
                setBody({ tripName: booking.trip.title, tripDate: booking.booked_datetime })
                setBookingId(booking.uuid)
                setShouldTriggerAccept(true)
                acceptBookingRequestsModalHandler()
              }}
            >
              {t("bookings.accept")}
            </Button>
            <Button
              className="fs-7 ms-2"
              color="dark"
              outline
              onClick={() => {
                setBody({ tripName: booking.trip.title, tripDate: booking.booked_datetime })
                setBookingId(booking.uuid)
                setShouldTriggerDeny(true)
                denyBookingRequestsModalHandler()
              }}
            >
              {t("bookings.deny")}
            </Button>
          </div>
        </div>
      ))}
    </div>
  )
}
