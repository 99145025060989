import React, { useState, useEffect, useCallback } from "react"
import { Outlet } from "react-router-dom"
import Header from "components/bookings/Header"
import Details from "components/bookings/Details"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import WithEmptyState from "components/common/WithEmptyState"
import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"

import useSearchFilter from "hooks/useSearchFilter"
import { isEqual } from "lodash"
import { isLastPage } from "helpers/pagination"
import { Waypoint } from "react-waypoint"
import { useTitle } from "hooks/useTitle"

import { cleanBookings, getGuideBookings } from "store/bookings"
import { useDispatch, useSelector } from "react-redux"
import { collectionAnySelector, metadataSelector } from "store/selectors"

function Bookings() {
  useTitle("Bookings")
  useLoaderWatchers({ watchers: ["bookings"] })
  const dispatch = useDispatch()
  const [params, updateSearchFilters] = useSearchFilter({ accessAttributes: ["range", "date", "type", "search", "sort"] })
  const [paramsState, setParamsState] = useState(params)

  const metadata = useSelector(metadataSelector("bookings"))
  const hasBookings = useSelector(collectionAnySelector("bookings"))

  const changeHandler = useCallback(
    ({ target }) =>
      setParamsState((prevParamsState) => ({
        ...prevParamsState,
        [target.name]: target.value
      })),
    []
  )

  const fetchBookings = useCallback(
    (nextPage = false) => {
      if (!params.date || !params.range || !params.type || !params.sort) return
      const range = params.range === "week" ? "weekDay" : params.range
      const data = {}
      const today = global.dateTime().format()
      const date = global.dateTime(params.date)

      data["q[client_full_name_or_trip_title_cont]"] = params.search

      if (!["all", "past"].includes(params.type)) {
        data["q[booked_date_gteq]"] = date.startOf(range, 1).format()
        data["q[booked_date_lteq]"] = date.endOf(range, 1).format()
      }

      if (params.type === "upcoming" && today > data["q[booked_date_gteq]"]) data["q[booked_date_gteq]"] = today
      if (params.type === "past") data["q[booked_date_lteq]"] = global.dateTime().manipulate(-1, "date").format()

      if (["full_paid", "outstanding"].includes(params.type)) data["q[payment_status_eq]"] = params.type
      if (params.type === "canceled") data["q[status_eq]"] = params.type
      if (params.sort) data["sort"] = params.sort

      dispatch(getGuideBookings(data, nextPage))
    },
    [params.date, params.range, params.type, params.sort, params.search] //eslint-disable-line
  )

  useEffect(() => {
    if (isEqual(paramsState, params)) return
    updateSearchFilters(paramsState)
  }, [paramsState]) //eslint-disable-line

  useEffect(() => {
    fetchBookings(false)
  }, [fetchBookings])

  useEffect(() => () => dispatch(cleanBookings()), []) //eslint-disable-line

  return (
    <div className="flex-fill z-0 position-relative p-20" id="bookings">
      <Header params={params} onChangeFilters={changeHandler} />
      <FirstLoading name="bookings">
        <WithEmptyState name="booking" hasItems={hasBookings} className="vstack gap-20">
          {/* <WithEmptyState name="booking" hasItems={true} className="vstack gap-20"> */}
          <Details params={params} />
          {!isLastPage(metadata) && <Waypoint onEnter={() => fetchBookings(true)} />}
        </WithEmptyState>

        <DefaultErrorBoundary>
          <Outlet />
        </DefaultErrorBoundary>
      </FirstLoading>
    </div>
  )
}

export default Bookings
