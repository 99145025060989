import React, { useRef, useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { PlayIcon } from "ui/MediaPreview/PlayIcon"

export const VideoPlayer = ({ className, src, thumbnailUrl, onClick, onPlay }) => {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [hasPlayed, setHasPlayed] = useState(false) // Track if video has been played

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play()
      setIsPlaying(true)
      setHasPlayed(true)
      onPlay?.(videoRef.current)
    } else {
      videoRef.current.pause()
      setIsPlaying(false)
    }
  }

  const handleVideoClick = (e) => {
    e.preventDefault()
    handlePlayPause()
    onClick?.()
  }

  return (
    <div className={classNames("relative w-full h-full rounded overflow-hidden", className)}>
      {!hasPlayed && (
        <LazyLoadImage
          src={thumbnailUrl}
          alt="Video thumbnail"
          wrapperClassName="object-cover w-full h-full rounded"
          className="object-cover w-full h-full rounded"
          effect="blur"
        />
      )}

      <video
        ref={videoRef}
        src={src}
        className={classNames("object-fit w-full h-full rounded", !hasPlayed && "hidden")}
        onClick={handleVideoClick}
        controls={isPlaying}
      />

      {!isPlaying && <PlayIcon onClick={handleVideoClick} />}
    </div>
  )
}

VideoPlayer.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func
}
