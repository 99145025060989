import { Text, Title } from "ui/Typography"
import { useTranslation } from "react-i18next"
import { UploadSection } from "./UploadSection"
import { Row, Col } from "antd"

export const DocumentUploadGrid = ({ handleFileListChange, filesState }) => {
  const { t } = useTranslation()
  const internalizationKeyPrefix = "pro.settings.licenses."

  return (
    <>
      <Title level={4} className="mb-l">
        {t(`${internalizationKeyPrefix}title`)}
      </Title>
      <Text className="mb-5xl">{t(`${internalizationKeyPrefix}info`)}</Text>
      <Row gutter={[20, 30]}>
        <Col sm={12} xs={24}>
          <UploadSection
            title={t(`${internalizationKeyPrefix}driver_license`)}
            name="driver_license"
            fileList={filesState.driver_license}
            onFileListChange={handleFileListChange}
          />
        </Col>
        <Col sm={12} xs={24}>
          <UploadSection
            title={t(`${internalizationKeyPrefix}captain_license`)}
            name="capitan_license"
            fileList={filesState.capitan_license}
            onFileListChange={handleFileListChange}
          />
        </Col>
        <Col sm={12} xs={24}>
          <UploadSection
            title={t(`${internalizationKeyPrefix}guide_certificate`)}
            name="guide_license"
            fileList={filesState.guide_license}
            onFileListChange={handleFileListChange}
          />
        </Col>
        <Col sm={12} xs={24}>
          <UploadSection
            title={t(`${internalizationKeyPrefix}fishing_permit`)}
            name="fishing_permit"
            fileList={filesState.fishing_permit}
            onFileListChange={handleFileListChange}
          />
        </Col>
        <Col sm={12} xs={24}>
          <UploadSection
            title={t(`${internalizationKeyPrefix}safety_certificate`)}
            name="waiver"
            fileList={filesState.waiver}
            onFileListChange={handleFileListChange}
          />
        </Col>
        <Col sm={12} xs={24}>
          <UploadSection
            title={t(`${internalizationKeyPrefix}other_docs`)}
            name="other"
            fileList={filesState.other}
            onFileListChange={handleFileListChange}
          />
        </Col>
      </Row>
    </>
  )
}
