import React, { memo, useEffect, useRef, useState } from "react"
import { Button, ButtonGroup, DropdownToggle, Label, Modal, ModalBody } from "reactstrap"
import { Dropdown } from "components/common/Dropdown"
import Input from "components/form/Input"
import Incomes from "./Incomes"
import Loading from "modules/loader-watchers/Loading"
import StripeWrapper from "components/stripe/StripeWrapper"
import PaymentConfirmation from "./PaymentConfirmation"

import { useTranslation } from "react-i18next"
import { colorClasses } from "helpers/color"
import { numberToCurrency } from "helpers/string"
import { calculateChanges } from "../helpers"
import useForm from "hooks/useForm"

import { useDispatch, useSelector } from "react-redux"
import { captureGuideBookingIncomeRecord, createGuideBookingIncomeRecord, updateGuideBooking } from "store/bookings"
import { modelSelector } from "store/selectors"

import { PAYMENT_STATUS_COLOR } from "components/bookings/constants"

const Payment = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const recordInputRef = useRef(null)
  const reduceInputRef = useRef(null)
  const booking = useSelector(modelSelector("booking"))
  const [stripeModalIsOpen, setStripeModalIsOpen] = useState()

  const calculation = calculateChanges({ ...booking }, booking)
  const deposited = booking.cash_deposit + booking.stripe_deposit
  const refunded = booking.cash_refund + booking.stripe_refund
  const tripCost = calculation.prevTotalPrice
  const subtotal = calculation.subtotal
  const fees = calculation.taxesAndFees
  const depositAmount = deposited + refunded
  const outstanding = Math.round((tripCost - depositAmount) * 100) / 100

  const [recordForm, recordChangeHandler, recordSubmitHandler, recordSubmitCallback, recordReset, recordIsChanged] = useForm({
    amount: 0
  })
  const [reduceForm, reduceChangeHandler, reduceSubmitHandler, reduceSubmitCallback, reduceReset, reduceIsChanged] = useForm({
    current_amount: booking.current_amount
  })

  const recordToggle = () => prefillRecord() || recordInputRef?.current?.select?.()
  const reduceToggle = () => reduceInputRef?.current?.select?.()

  useEffect(() => {
    if (!recordForm.amount) prefillRecord()
  }, []) //eslint-disable-line

  recordSubmitCallback(() => dispatch(createGuideBookingIncomeRecord(booking.id, { ...recordForm, stripe: false })).then(recordReset))

  const openStripeModal = () => setStripeModalIsOpen(true)
  const closeStripeModal = () => setStripeModalIsOpen(false)
  const prefillRecord = () => recordChangeHandler({ target: { name: "amount", value: outstanding } })

  reduceSubmitCallback(() => dispatch(updateGuideBooking(booking.id, reduceForm)).then(reduceReset))

  const dropdownClasses = ["bg-opacity-25 border-0 rounded-pill fw-semibold px-3 py-0 lh-base shadow-none"]
  dropdownClasses.push(colorClasses(booking.payment_status, PAYMENT_STATUS_COLOR))

  return (
    <div className="vstack gap-20 bg-white rounded p-20">
      <div className="hstack gap-10">
        <h5>{t("booking.payment.labels.payment_summary")}</h5>
        <Dropdown
          className="ms-auto"
          classNameMenu="p-15"
          end
          toggleButton={
            <DropdownToggle className={dropdownClasses.join(" ")}>
              {t(`booking.payment.statuses.${booking.payment_status}`, { outstanding: numberToCurrency(outstanding) })}
            </DropdownToggle>
          }
        >
          <div className="vstack gap-10">
            <Label className="fs-7 text-dark text-opacity-50 m-0">
              <small>{t("global.actions")}</small>
            </Label>

            <Dropdown
              onToggle={recordToggle}
              classNameMenu="p-15"
              toggleButton={
                <DropdownToggle
                  className="bg-success text-success bg-opacity-25 bg-opacity-50-hover border-0 fw-semibold px-3 py-1 shadow-none"
                  style={{ minWidth: 150 }}
                >
                  {t("booking.payment.labels.record_payment")}
                </DropdownToggle>
              }
            >
              <Loading tag="div" className="vstack gap-10" name="bookings.incomes" spinnerProps={{ className: "m-n2" }}>
                <Label for="record" className="fs-7 text-dark text-opacity-50 m-0">
                  <small>{t("booking.payment.labels.enter_cash_amount")}</small>
                </Label>
                <Input
                  id="amount"
                  type="number"
                  name="amount"
                  value={recordForm.amount}
                  min={0}
                  max={outstanding}
                  onChange={recordChangeHandler}
                  ref={recordInputRef}
                  withWrapper
                />
                {recordIsChanged && recordForm.amount > 0 && (
                  <ButtonGroup>
                    <Button
                      color="primary"
                      className="bg-success text-success bg-opacity-25 bg-opacity-50-hover border-0 fw-semibold px-3 py-1 shadow-none"
                      type="button"
                      onClick={recordSubmitHandler}
                    >
                      By Cash
                    </Button>
                    {booking.trip.can_be_paid_by_card && (
                      <Button
                        color="primary"
                        className="bg-stripe text-stripe bg-opacity-25 bg-opacity-50-hover border-0 fw-semibold px-3 py-1 shadow-none"
                        value="card"
                        type="button"
                        onClick={openStripeModal}
                      >
                        By Card
                      </Button>
                    )}
                  </ButtonGroup>
                )}
              </Loading>
            </Dropdown>

            <Dropdown
              tag="form"
              onSubmit={(e) => e.preventDefault()}
              onToggle={reduceToggle}
              classNameMenu="p-15"
              toggleButton={
                <DropdownToggle
                  className="bg-info text-info bg-opacity-25 border-0 rounded-pill fw-semibold px-3 py-1 shadow-none"
                  style={{ minWidth: 150 }}
                >
                  {t("booking.payment.labels.adjust_amount")}
                </DropdownToggle>
              }
            >
              <Loading tag="div" className="vstack gap-10" name="bookings.booking" spinnerProps={{ className: "m-n2" }}>
                <Label for="current_amount" className="fs-7 text-dark text-opacity-50 m-0">
                  <small>{t("booking.payment.labels.enter_adjusted_amount")}</small>
                </Label>
                <Input
                  id="current_amount"
                  type="number"
                  name="current_amount"
                  value={reduceForm.current_amount}
                  onChange={reduceChangeHandler}
                  ref={reduceInputRef}
                  withWrapper
                />
                {reduceIsChanged && (
                  <Button color="primary-second" className="fs-7 px-4 py-2 ms-auto" type="button" onClick={reduceSubmitHandler}>
                    {t("global.save")}
                  </Button>
                )}
              </Loading>
            </Dropdown>
          </div>
        </Dropdown>
      </div>

      <Incomes />

      <div className="grid grid-cols-2 gap-10" style={{ maxWidth: 400 }}>
        <span>{booking?.trip?.title}</span>
        <span className="text-end">{numberToCurrency(subtotal)}</span>
        <span>{t("booking.payment.labels.fees")}</span>
        <span className="text-end">{numberToCurrency(fees)}</span>
        <span></span>
        <span></span>
        <br />
        <span className="text-end">{numberToCurrency(tripCost)}</span>
        <span>{t("booking.payment.labels.deposit")}:</span>
        <span className="text-end">{numberToCurrency(depositAmount)}</span>
        <span className="fw-semibold">{t("booking.payment.labels.balance")}:</span>
        <span className="text-end fw-semibold">{numberToCurrency(outstanding)}</span>
      </div>

      <Modal
        isOpen={stripeModalIsOpen}
        size="xl"
        centered
        scrollable
        backdropClassName="backdrop-blur-3"
        contentClassName="rounded-sm-3"
        toggle={closeStripeModal}
        onClosed={prefillRecord}
      >
        <ModalBody className="p-20">
          <StripeWrapper>
            <PaymentConfirmation
              createAction={createGuideBookingIncomeRecord}
              captureAction={captureGuideBookingIncomeRecord}
              amount={recordForm.amount}
              submitText={t("global.submit")}
              onConfirm={closeStripeModal}
              client={booking.client}
              showTotal
              showPaymentMethods
            />
          </StripeWrapper>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default memo(Payment)
