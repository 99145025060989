function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const generateScriptForEmbedCheckout = (url) => {
  const uuid = generateUUID()
  return `
    <button id="${uuid}-openModalBtn" style="padding: 10px 20px; font-size: 16px; cursor: pointer; background-color: #1D72F4; color: white; border: none; border-radius: 5px;">
        Open DS Booking
    </button>
    <div id="${uuid}-modal" style="display: none; position: fixed; z-index: 9999; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); justify-content: center; align-items: center;">
      <div id="${uuid}-modal-content" style="position: relative; background-color: rgba(250, 250, 252, 1); padding: 10px; border-radius: 8px; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); max-width: 700px; width: 90%; max-height: 90vh; height: 800px; box-sizing: border-box;">
        <span id="${uuid}-closeBtn" style="position: absolute; top: -24px; right: -24px; font-size: 30px; cursor: pointer; color: #333; font-weight: bold;">&times;</span>
        <iframe id="${uuid}-iframe" src="${url}" frameborder="0" style="width: 100%; height: 100%; border: none;"></iframe>
      </div>
    </div>
    <script>
      (function() {
        const modal = document.getElementById("${uuid}-modal");
        const closeModalBtn = document.getElementById("${uuid}-closeBtn");
        const iframe = document.getElementById("${uuid}-iframe");
        const openModalBtn = document.getElementById("${uuid}-openModalBtn");
        openModalBtn.addEventListener("click", () => {
          modal.style.display = "flex";
          iframe.src = "${url}";
        });
        closeModalBtn.addEventListener("click", () => {
          modal.style.display = "none";
          iframe.src = "";
        });
        window.addEventListener("click", (e) => {
          if (e.target === modal) {
            modal.style.display = "none";
            iframe.src = "";
          }
        });
      })();
    </script>
  `
}
