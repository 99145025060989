import { Col, Row } from "antd"
import { useDevice } from "hooks/useDevice"
import { ContentActionButtons } from "layoutsV2/pro/PersonalInfoLayout/ContentActionButtons"
import { ContentHeader } from "layoutsV2/pro/PersonalInfoLayout/ContentHeader"
import { StripeAccountCard } from "pagesV2/pro/StripeAccount/StripeAccountCard"
import { StripeActionButtons } from "pagesV2/pro/StripeAccount/StripeActionButtons"
import { useTranslation } from "react-i18next"

export const PersonalInfoStripe = () => {
  const { t } = useTranslation()
  const { isMobile } = useDevice()

  return (
    <>
      <Row>
        <Col lg={16} md={24} xs={24} span={16} >
          <ContentHeader title={t("pro.personal_info.stripe.title")} description={t("pro.personal_info.stripe.description")} />
          <StripeAccountCard flow_type="wizard" className="mb-4xl"/>
          {isMobile && <StripeActionButtons flow_type="wizard" />}
          <ContentActionButtons />
        </Col>
      </Row>
    </>
  )
}
