import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { PlayIcon } from "./PlayIcon"

export const MediaPreview = ({ media, className, wrapperClassName, onClick, playIconSize = "md" }) => {
  const isVideo = media?.url?.match(/\.(mp4|webm|ogg)$/i)

  const Media = () => (
    <LazyLoadImage
      src={media?.thumbnail_url || media?.url || ""}
      alt=""
      className={classNames("object-cover w-full h-full rounded", className)}
      wrapperClassName={classNames("w-full h-full", onClick && "cursor-pointer", wrapperClassName)}
      effect="blur"
      placeholderSrc={media?.placeholder_url}
      onClick={() => onClick?.()}
    />
  )

  if (isVideo) {
    return (
      <div className="relative w-full h-full rounded overflow-hidden">
        <Media />
        <PlayIcon size={playIconSize} onClick={() => onClick?.()} />
      </div>
    )
  }

  return <Media />
}

MediaPreview.propTypes = {
  media: PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    thumbnail_url: PropTypes.string,
    placeholder_url: PropTypes.string,
    type: PropTypes.oneOf(["image", "video"])
  }).isRequired,
  index: PropTypes.number.isRequired,
  onImageClick: PropTypes.func,
  playIconSize: PropTypes.string
}
