import React from "react"
import { Form, Button } from "antd"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { SocialButtons } from "../SocialButtons"
import { Title, Text } from "ui/Typography"
import { routes } from "router"
import { isProApp } from "utils/app-type"

export const SignUpOptions = () => {
  const { t } = useTranslation()
  const isProHost = isProApp()
  return (
    <>
      <Title level={2} className="justify-center mb-3xl">
        {isProHost ? t("global.become_a_ds_pro") : t("sign_up.register_guest_account")}
      </Title>
      <SocialButtons />
      <Form.Item>
        <Link to="/auth/sign-up">
          <Button color="primary" variant="outlined" block size="large">
            {isProHost ? t("sign_up.create_account_with_email") : t("sign_up.with_email")}
          </Button>
        </Link>
      </Form.Item>
      <div className="justify-center">
        <Text type="secondary" variant="body">
          {t("sign_up.already_have_account")}
        </Text>
        <Text className="ml-xs" link>
          <Link to={routes.signInV2()}>{isProHost ? t("sign_in.log_in") : t("sign_in.log_in_as_guest")}</Link>
        </Text>
      </div>
    </>
  )
}
