import React from "react"
import { Form, Button } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Title, Text } from "ui/Typography"
import { CodeInput } from "ui/Input"
import { CustomInputError } from "ui/CustomInputError"
import { verifyPasswordRecoveryCode, requestForgotPassword } from "store/password"
import { routes } from "router"
import useFormErrors from "hooks/useAntdFormErrors"

const initialValue = ["", "", "", "", ""]

export const EnterCode = () => {
  const { t } = useTranslation()
  const { loading } = useSelector((state) => state.auth)
  const [initialCode, setInitialCode] = React.useState()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isEmail = searchParams.get("recoveryMethod") === "email"
  const email = searchParams.get("email")
  const phone = searchParams.get("phone")
  const [form] = Form.useForm()

  const handleSubmit = ({ pin_code }) => {
    const fullCode = pin_code.join("")

    if (fullCode.length === 5) {
      dispatch(verifyPasswordRecoveryCode({ pin_code: fullCode })).then(() => navigate(routes.passwordRecoveryCreatePassword()))
    }
  }

  const handleResend = React.useCallback(() => {
    const data = {}
    isEmail ? (data.email = email) : (data.phone = phone)
    dispatch(requestForgotPassword(data))
    setInitialCode(initialValue)
  }, [email, phone, isEmail, dispatch])

  const infoTextKey = isEmail ? "enter_code.info_email" : "enter_code.info_phone"

  const { errors } = useFormErrors(form)

  return (
    <>
      <Title level={2} className="flex justify-center mb-m">
        {t("enter_code.title")}
      </Title>
      <Form form={form} name="verifyCode" layout="vertical" onFinish={handleSubmit}>
        <div className="text-center mb-xl">
          <Text type="sub" color="primary">
            {t(infoTextKey)}
          </Text>
          <Text className="ml-xs" strong>
            {isEmail ? email : phone}
          </Text>
        </div>
        <CodeInput
          form={form}
          name="pin_code"
          initialValue={initialCode}
          help={
            !!errors?.pin_code ? (
              <CustomInputError
                errorKey={errors.pin_code}
                customHandler={
                  // custom handler to pass resend code button as an option. arguably only place where is needed
                  errors.pin_code === "user.attributes.pin_code.expired"
                    ? () => (
                        <Button
                          className="pl-0 pb-0 mb-0 mt-0 pt-0 fw-normal"
                          color="primary"
                          variant="link"
                          size="medium"
                          onClick={handleResend}
                        >
                          {t("enter_code.resend_code")}
                        </Button>
                      )
                    : null
                }
              />
            ) : null
          }
        />
        <Form.Item className="mt-4xl">
          <Button type="primary" htmlType="submit" block size="large" disabled={loading}>
            {t("global.submit")}
          </Button>
        </Form.Item>
      </Form>
      <div className="flex justify-center align-center">
        <Text type="secondary" variant="body">
          {t("enter_code.code_not_received")}
        </Text>
        <Button color="primary" variant="link" size="medium" onClick={handleResend}>
          {t("enter_code.resend_code")}
        </Button>
      </div>
    </>
  )
}
