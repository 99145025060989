import WithEmptyState from "components/common/WithEmptyState"
import Trip from "components/trips/Trip"
import { TRIP_FILTERS, TRIP_FILTERS_HELP_ATTRIBUTES } from "constants/trip"
import { isLastPage } from "helpers/pagination"
import useSearchFilter from "hooks/useSearchFilter"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import Loading from "modules/loader-watchers/Loading"
import { useDispatch, useSelector } from "react-redux"
import { Waypoint } from "react-waypoint"
import { Col, Container, Row } from "reactstrap"
import { collectionAnySelector, collectionSelector, metadataSelector } from "store/selectors"
import { getTrips } from "store/trips"

export const ClientLandingPage = () => {
  const dispatch = useDispatch()
  const trips = useSelector(collectionSelector("trips"))
  const hasTrips = useSelector(collectionAnySelector("trips"))
  const metadata = useSelector(metadataSelector("trips"))

  const [params, updateSearchFilters, searchUpdatedCallback] = useSearchFilter({
    accessAttributes: TRIP_FILTERS,
    exceptDependencies: TRIP_FILTERS_HELP_ATTRIBUTES
  })

  const fetchTrips = (nextPage = false) => {
    const search = { q: params, northeast_corner: "90, 180", southwest_corner: "-90, -180" }
    if (!nextPage) window.scrollTo(0, 0)
    dispatch(getTrips(nextPage, search))
  }

  searchUpdatedCallback(() => fetchTrips())

  return (
    <Container>
      <Row>
        <FirstLoading name="trips" className="p-20">
          <Loading
            name="trips"
            className="p-15 bg-white shadow"
            spinnerProps={{ position: "fixed" }}
            style={{ minHeight: `calc(100vh - 150px)` }}
          >
            <WithEmptyState
              name="trip"
              hasItems={hasTrips}
              className="flex flex-wrap"
              fallbackTitle="No exact matches"
              fallbackText="Try changing or removing some filters or customizing your search area."
            >
              {trips.map((trip) => (
                <Col key={trip.id} xs={12} sm={6} md={4} xl={3} className="p-20">
                  <Trip trip={trip} withRating />
                </Col>
              ))}
              {!isLastPage(metadata) && <Waypoint onEnter={() => fetchTrips(true)} />}
            </WithEmptyState>
          </Loading>
        </FirstLoading>
      </Row>
    </Container>
  )
}
