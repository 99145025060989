import React, { useRef } from "react"
import { useDispatch } from "react-redux"
import { Form } from "antd"
import { sendMessage } from "store/conversations"
import { Button } from "antd"
import { Icon } from "ui/Icon"
import MessageEditor from "components/old/Messenger/ProMessageEditor"
import { EditorState, convertToRaw } from "draft-js"
import { trimEditorState } from "helpers/editor"
import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"

import styles from "./ChatHistory.module.css"
import classNames from "classnames"

export const MessageForm = ({ conversation, messageSent }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty())
  const [media, setMedia] = React.useState(null)
  const [editorKey, setEditorKey] = React.useState(1234)
  const ref = useRef(null)

  const onFinish = () => {
    const cleanState = trimEditorState(editorState)
    const content = convertToRaw(cleanState.getCurrentContent())
    const text = content.blocks.map((el) => el.text + "\n").join("")
    const message = {}
    message.body = text
    if (media) message.media = media
    const emptyEditorState = EditorState.createEmpty()
    setEditorState(emptyEditorState)
    dispatch(sendMessage(conversation.id, serialize({ message }))).then(messageSent)
    setEditorKey(editorKey + 1)
    ref.current.focus()
  }

  return (
    <div className={styles.footer} ref={ref}>
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Form.Item name="message" style={{ flex: 1 }}>
          <MessageEditor
            key={editorKey}
            ref={ref}
            className={classNames("flex align-center")}
            inputClassName={styles.input}
            editorState={editorState}
            setEditorState={setEditorState}
            media={media}
            placeholder={t("messenger.placeholder")}
            setMedia={setMedia}
            type="submit"
            onSubmit={onFinish}
          />
        </Form.Item>

        <Form.Item className="ml-s">
          <Button icon={<Icon name="Send" color="#ffffff" width={16} height={16} />} type="primary" htmlType="submit" />
        </Form.Item>
      </Form>
    </div>
  )
}
