import React from "react"
import { Outlet } from "react-router-dom"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"

export default function MarketingPage() {
  useLoaderWatchers({ watchers: ["posts", "templates"] })
  return (
    <div className="flex-fill z-0 p-20" id="marketing">
      <DefaultErrorBoundary>
        <Outlet />
      </DefaultErrorBoundary>
    </div>
  )
}
