import React from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import Calculator from "components/bookings/Calculator"
import ActionButtons from "components/wizards/ActionButtons"

import useForm from "hooks/useForm"
import { useWizardSteps } from "hooks/wizard"
import { serialize } from "object-to-formdata"

import { useDispatch, useSelector } from "react-redux"
import { updateBooking } from "store/bookings"

const Step1Form = () => {
  const { name, stepsKey } = useOutletContext()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updateStep = useWizardSteps({ name, stepsKey })
  const { booking } = useSelector((store) => store.bookings)
  const { trip, payment_status, wizard_completed, booking_fee_percentage } = booking
  const isNotPendingAndCompleted = payment_status !== "pending" && wizard_completed

  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm(booking, [
    "booked_date",
    "booked_time",
    "adult_count",
    "child_count"
  ])

  submitCallback(({ nativeEvent }) => {
    const nextPath = nativeEvent?.submitter?.value

    const formData = serialize({ booking: updateStep(form) })
    dispatch(updateBooking(booking.uuid, formData)).then(() => navigate(nextPath))
  })

  return (
    <form onSubmit={submitHandler} className="vstack">
      <Calculator
        form={form}
        changeHandler={changeHandler}
        trip={trip}
        current_booked_date={booking.booked_date}
        booking_fee_percentage={booking_fee_percentage}
        disabled={isNotPendingAndCompleted}
      />
      <ActionButtons
        isChanged={isChanged && !isNotPendingAndCompleted}
        action={updateBooking}
        name={name}
        stepsKey={stepsKey}
        className="mt-20"
        btnColor="primary"
        nextClassName="flex-fill text-uppercase"
      />
    </form>
  )
}

export default Step1Form
