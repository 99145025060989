import React from "react"

export const Complete = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#05A44F"
      fill-opacity="0.05"
    />
    <path d="M17.3333 8L9.99996 15.3333L6.66663 12" stroke="#05A44F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)
