import React from "react"
import { Card as AntdCard, Checkbox } from "antd"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { useDevice } from "hooks/useDevice"

import sportsmanLogo from "assets/imagesV2/logo_man.svg"
import sportswomanLogo from "assets/imagesV2/logo_woman.svg"
import styles from "./Card.module.css"

import { themes } from "constants/theme"
import { Text } from "ui/Typography"
import { Radio } from "ui/Radio"

export const Card = ({ type, selected, onSelect, isRadioButton = false }) => {
  const { isMobile } = useDevice()
  const isSportsman = type === themes.sportsman
  const { t } = useTranslation()
  const key = isSportsman ? "select_adventure_mode.sportsman" : "select_adventure_mode.sportswoman"
  return (
    <div className={classNames(styles.wrapper, isSportsman ? styles.sportsman : styles.sportswoman)} onClick={onSelect}>
      <AntdCard className={classNames(styles.card, selected && styles.selected)}>
        <img className="mb-4xl mt-xl" src={isSportsman ? sportsmanLogo : sportswomanLogo} alt="" />
        <div className={styles.filler} />
        <div className={styles.filler} />
        <div className={styles.filler} />
        <div className={styles.branding} />
      </AntdCard>
      <div className="flex-column text-center mt-s">
        <Text className="mb-2" variant="sub" fw="bold">
          {t([key])}
        </Text>
        {isMobile || isRadioButton ? <Radio value="email" checked={selected} /> : <Checkbox checked={selected} />}
      </div>
    </div>
  )
}
