import React, { useEffect } from "react"
import { Outlet, useParams } from "react-router-dom"
import Header from "components/layout/Header"
import GuideHeader from "components/guides/Header"
import Navigation from "components/guides/Navigation"
import Footer from "components/layout/Footer"
import withDefaultErrorBoundary from "modules/errors/HOCs/withDefaultErrorBoundary"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import { useDispatch, useSelector } from "react-redux"
import { cleanGuide, getClientGuide } from "store/guides"
import { modelSelector } from "store/selectors"

function GuideLayout() {
  const params = useParams()
  const dispatch = useDispatch()
  const guide = useSelector(modelSelector("guide"))

  useEffect(() => {
    dispatch(getClientGuide(params.slug))
    return () => dispatch(cleanGuide())
  }, [params.slug, dispatch])

  return (
    <main className="vstack min-vh-100 bg-light">
      <Header withNavigation withBotomBorder />
      <div className="bg-white shadow pt-20">
        <FirstLoading name="guides.guide" className="d-none">
          <GuideHeader guide={guide} />
        </FirstLoading>
        <Navigation slug={guide.slug} />
      </div>
      <div className="flex-fill vstack min-h-100 py-40">
        <FirstLoading name="guides.guide">
          <DefaultErrorBoundary>
            <Outlet />
          </DefaultErrorBoundary>
        </FirstLoading>
      </div>
      <Footer />
    </main>
  )
}

export default withDefaultErrorBoundary(GuideLayout)
