import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Input, Label } from "reactstrap"
import Spinner from "components/common/Spinner"
import PreviewMedia from "components/common/PreviewMedia"
import InputError from "components/form/InputError"

import { useNestedFields } from "hooks/useForm"
import { useTranslation } from "react-i18next"
import { checkFileSize, checkMimeType, maxSelectFile } from "helpers/form"
import { fixRotation } from "helpers/fix-rotation"
import { compressFile } from "helpers/compress"
import { ACCEPTED_MEDIA_TYPES } from "constants/form"
import { Icon } from "ui/Icon"
import { colors } from "themes/variables"

const PhotoSelector = ({ form, changeHandler, max = 0, small = false, start = false, label = "", imageHeight }) => {
  const { t } = useTranslation()
  const [, , addPhotoHandler, removePhotoHandler] = useNestedFields(form, "photos", ["file", "file_type"], changeHandler)
  const photosCount = useMemo(() => form.photos?.filter(({ _destroy }) => !_destroy)?.length, [form.photos])
  const [loadingMedia, setLoadingMedia] = useState(false)

  const uploadFile = (uploadedFile) =>
    new Promise(async (resolve, reject) => {
      const mediaType = uploadedFile.type.split("/").at(0)
      const rotatedFile = await fixRotation(uploadedFile, mediaType)
      const file = await compressFile(rotatedFile, mediaType)
      const file_preview = URL.createObjectURL(file)
      addPhotoHandler(null, {
        file,
        file_type: "photo",
        file_preview,
        mediaType
      })
      if (file) resolve(file)
      else reject(null)
    })

  const onChangeHandler = async (event) => {
    if (event.persist) event.persist()
    let uploadedFiles = event.target.files
    if (!uploadedFiles.length) return

    uploadedFiles = maxSelectFile(uploadedFiles, max)
    uploadedFiles = checkMimeType(uploadedFiles)
    uploadedFiles = checkFileSize(uploadedFiles)
    uploadedFiles = uploadedFiles.map(uploadFile)
    Promise.all(uploadedFiles).then(() => setLoadingMedia(false))
  }

  const style = {
    gridTemplateColumns: `repeat(${form.photos?.length || start ? "auto-fill" : 1}, minmax(${small ? "120px" : "180px"},1fr))`
  }

  return (
    <>
      <div className="grid gap-20" style={style}>
        {form.photos?.map(
          (photo) =>
            (photo?.file_preview || photo?.preview_url || photo?.url) &&
            !photo._destroy && (
              <div key={photo.id || photo._id} className="mx-auto w-100" style={{ maxWidth: 320 }}>
                <PreviewMedia
                  type={photo.mediaType}
                  preview={photo.file_preview || photo?.preview_url || photo.url}
                  onClose={() => removePhotoHandler(photo)}
                  ratio="4x3"
                  withTeathre
                  imageHeight={imageHeight}
                />
              </div>
            )
        )}
        {(photosCount < max || !max) && (
          <div className="mx-auto w-100" style={{ maxWidth: 320 }}>
            <div className="ratio ratio-4x3 bg-white border border-gray-lightest rounded shadow-sm">
              {loadingMedia ? (
                <Spinner className="position-absolute" />
              ) : (
                <Label check className="flex-grow-1 vstack align-items-center justify-content-center gap-1 p-15 h6 fs-7 fw-medium">
                  <Input
                    type="file"
                    onChange={onChangeHandler}
                    className="d-none"
                    accept={[ACCEPTED_MEDIA_TYPES.image.join(", ")]}
                    multiple
                  />
                  <div className="flex-column align-center">
                    <Icon name="Upload" width={small ? 45 : null} />
                    <span className="mt-10 text-color-primary" style={{ color: colors.pr }}>
                      {t("global.upload")}
                    </span>
                  </div>
                  {!!label && (
                    <span className="text-center text-dark text-opacity-50 mb-n10">
                      {label || (max ? t("global.upload_max", { max }) : t("global.upload"))}
                    </span>
                  )}
                </Label>
              )}
            </div>
          </div>
        )}
      </div>
      <InputError field="photos" className={["mt-1 mb-n3", start ? "me-auto" : "mx-auto"]} />
    </>
  )
}

PhotoSelector.propTypes = {
  form: PropTypes.object.isRequired,
  changeHandler: PropTypes.func.isRequired,
  max: PropTypes.number,
  small: PropTypes.bool,
  start: PropTypes.bool,
  label: PropTypes.string
}

export default PhotoSelector
