import React, { useState } from "react"
import { Button, Drawer, Flex, Col } from "antd"
import { useTranslation } from "react-i18next"
import { ChevronLeft, X, Grid } from "react-feather"

// components
import { GallerySlider } from "ui/GallerySlider"
import Gallery from "ui/Gallery"
// UI
import { Title } from "ui/Typography"

export const PreviewGallery = ({ name, mediaItems }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [activeSlide, setActiveSlide] = useState(null)

  const handleGalleryOpen = () => {
    setOpen(true)
  }

  const handleGalleryClose = () => {
    setOpen(false)
  }

  const handleImageClick = (index) => {
    setActiveSlide(index)
  }

  const handleBackClick = () => {
    setActiveSlide(null)
  }

  return (
    <Flex vertical gap={16}>
      <div className="relative flex flex-1">
        <Gallery mediaItems={mediaItems?.slice(0, 5)} />
        {mediaItems && mediaItems.length > 3 && (
          <div className="absolute bottom-0 right-0 p-s">
            <Button
              color="primary"
              variant="outlined"
              onClick={handleGalleryOpen}
              size="small"
              shape="round"
              icon={<Grid className="flex-shrink-0" size={16} />}
            >
              {t("global.see_more_photos")}
            </Button>
          </div>
        )}
      </div>

      <Drawer
        open={open}
        placement="bottom"
        closeIcon={null}
        height="100%"
        title={
          <Title level={4} className="m-0">
            {name}
          </Title>
        }
        extra={<Button icon={<X />} onClick={handleGalleryClose} type="text" />}
      >
        <Col xl={22} xxl={20} className="mx-auto">
          <Gallery mediaItems={mediaItems} onImageClick={handleImageClick} />
        </Col>
      </Drawer>

      <Drawer
        open={activeSlide !== null}
        placement="bottom"
        closeIcon={null}
        height="100%"
        styles={{ body: { padding: 0 } }}
        title={
          <Button icon={<ChevronLeft />} color="primary" variant="text" onClick={handleBackClick}>
            {t("global.back_to_gallery")}
          </Button>
        }
      >
        <Col span={22} xl={18} lg={20} className="m-auto h-full flex-column w-full">
          <GallerySlider mediaItems={mediaItems} activeSlide={activeSlide} />
        </Col>
      </Drawer>
    </Flex>
  )
}

export default PreviewGallery
