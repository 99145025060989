import React, { useState } from "react"
import { useNavigate, useOutletContext, useSearchParams } from "react-router-dom"
import { Button, TabContent, TabPane } from "reactstrap"
import PaymentOptionList from "../form/PaymentOptionList"
import StripeWrapper from "components/stripe/StripeWrapper"
import PaymentConfirmation from "../form/PaymentConfirmation"

import { useWizardSteps } from "hooks/wizard"
import { numberToCurrency } from "helpers/string"
import { calcPayToday, calculateBookingTotal } from "components/bookings/helpers"
import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { dynamicRoute } from "router/routes"

import { useDispatch, useSelector } from "react-redux"
import { createClientBookingIncomeRecord, captureClientBookingIncomeRecord, updateBooking } from "store/bookings"
import { modelSelector } from "store/selectors"

import { PAYMENT_FULL_CASH_ON_DAY, PAYMENT_TYPES } from "components/bookings/constants"
import { WIZARD_PATHS } from "constants/wizard"

const Step3Form = () => {
  const { t } = useTranslation()
  const { name, stepsKey } = useOutletContext()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updateStep = useWizardSteps({ name, stepsKey })
  const [searchParams] = useSearchParams()

  const booking = useSelector(modelSelector("booking"))
  const user = useSelector((state) => (state.user.id ? state.user : booking.client))
  const { trip, payment_status, wizard_completed } = booking
  const { stripe_account_data = {} } = trip.guide
  const isStripeConnected = !!stripe_account_data
  const isStripeChargeEnabled = stripe_account_data.charges_enabled
  const isNotPendingAndCompleted = payment_status !== "pending" && wizard_completed
  const mode = searchParams.get("mode")

  const nextPath = WIZARD_PATHS[stepsKey]?.confirm || ""
  const nextPathWithParams = nextPath ? dynamicRoute(nextPath)({ uuid: booking.uuid }, { mode }) : null

  const [activeTab, setActiveTab] = useState(booking.payment_status === "requires_confirmation" ? "checkout" : "payment_type")
  const title = t(`booking.wizard.step_3_${activeTab}.title`)
  const subtitle = t(`booking.wizard.step_3_${activeTab}.subtitle`)

  const paymentOptionsListName =
    (trip.cash_payment_allowed && trip.deposit_required && "cash_and_deposit") ||
    (trip.cash_payment_allowed && "cash") ||
    (trip.deposit_required && "deposit") ||
    "default"
  const paymentOptions = PAYMENT_TYPES[paymentOptionsListName]

  const [paymentOption, setPaymentOption] = useState(booking?.payment_option || null)

  // const isFullAmountInCashOnDay = paymentOption === "full_amount_in_cash_on_day"
  const selectedGuests = +booking.adult_count + +booking.child_count
  const { total: totalPrice } = calculateBookingTotal(trip, selectedGuests, booking.booking_fee_percentage)
  const payToday = calcPayToday(paymentOption, totalPrice, trip.deposit_percentage)
  const payLater = totalPrice - payToday

  const changePaymentOption = () => setActiveTab("payment_type")
  const updatePaymentOption = (event) => {
    event.preventDefault()
    if (booking.payment_option !== paymentOption)
      dispatch(updateBooking(booking.uuid, serialize({ booking: { payment_option: paymentOption } }))).then(() => setActiveTab("checkout"))
    else setActiveTab("checkout")
  }
  const confirmHandler = (booking) => {
    if (nextPathWithParams && booking.status === "booked") navigate(nextPathWithParams)
  }

  return (
    <div className="vstack position-relative">
      <div>
        {title && <h1 className="h2 lh-1 mb-0">{title}</h1>}
        <div className="hstack justify-content-between gap-20">
          {subtitle && <p className="fs-4 mt-15 mb-20 text-dark">{subtitle}</p>}
          {activeTab === "checkout" && (
            <Button color="link" className="link link-primary-second fw-semibold" onClick={changePaymentOption}>
              Change payment option
            </Button>
          )}
        </div>
      </div>
      <TabContent activeTab={activeTab} className="w-100">
        <TabPane tabId="payment_type">
          <form onSubmit={updatePaymentOption} className="vstack gap-15 mt-30">
            <PaymentOptionList
              paymentOptions={paymentOptions}
              active={paymentOption}
              onChange={setPaymentOption}
              totalPrice={totalPrice}
              depositPercentage={trip.deposit_percentage}
              disabled={isNotPendingAndCompleted}
              canCharge={isStripeConnected && isStripeChargeEnabled}
            />
            {paymentOption && (
              <>
                <div className="border-bottom border-gray-lightest py-10">
                  <div className="hstack gap-20 justify-content-between fs-3 fw-semibold">
                    <span className="text-dark text-opacity-50">{t("booking.wizard.step_3_payment_type.amount_due_today")}</span>
                    <span>{numberToCurrency(payToday)}</span>
                  </div>
                  {payLater > 0 && (
                    <div className="hstack gap-20 justify-content-between fs-7 fw-medium">
                      <span className="text-dark text-opacity-50">{t("booking.wizard.step_3_payment_type.amount_paid_later")}</span>
                      <span>{numberToCurrency(payLater)}</span>
                    </div>
                  )}
                </div>
                <Button color="primary" className="w-100 text-uppercase" disabled={isNotPendingAndCompleted}>
                  {t("global.next")}
                </Button>
              </>
            )}
          </form>
        </TabPane>

        <TabPane tabId="checkout">
          <StripeWrapper>
            <PaymentConfirmation
              createAction={createClientBookingIncomeRecord}
              captureAction={captureClientBookingIncomeRecord}
              amount={payToday}
              submitText={t("global.book")}
              onConfirm={confirmHandler}
              client={user}
              showTotal
              showPaymentMethods
            />
          </StripeWrapper>
        </TabPane>
      </TabContent>
    </div>
  )
}

export default Step3Form
