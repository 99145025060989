import React from "react"
import { Upload, Button, List, message, Modal } from "antd"
import { Text } from "ui/Typography"
import { Icon } from "ui/Icon"
import { colors } from "themes/variables"
import { UploadListItem } from "./UploadListItem"
import { useTranslation } from "react-i18next"
import { downloadFile } from "helpers/files"
import styles from "./Upload.module.css"

const acceptedTypes = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "text/plain"
]

export const UploadSection = ({ title, fileList, name, onFileListChange }) => {
  const { t } = useTranslation()
  const beforeUpload = (file) => {
    const isValidType = acceptedTypes.includes(file.type)
    if (!isValidType) {
      message.error(t("pro.settings.licenses.invalid_format"))
      return Upload.LIST_IGNORE
    }
    if (file.size / 1024 / 1024 > 5) {
      message.error(t("pro.settings.licenses.size_limit_exceeded"))
      return Upload.LIST_IGNORE
    }
    return true
  }

  const handleDelete = (file) => {
    Modal.confirm({
      closable: true,
      icon: null,
      title: t("pro.settings.licenses.delete_license_title"),
      content: t("pro.settings.licenses.delete_license_text"),
      onOk: () => {
        const list = fileList.map((item) => {
          if ((item.uid && file.uid && item.uid === file.uid) || (item.id && file.id && item.id === file.id)) {
            return {
              ...item,
              _destroy: true
            }
          }
          return item
        })
        onFileListChange(name, list)
        message.success(t("pro.settings.licenses.document_deleted"))
      }
    })
  }

  const enabledFileList = fileList.filter((file) => !file._destroy)

  return (
    <>
      {title && <Text variant="sub">{title}</Text>}
      <div className={styles.uploadWrapper}>
        <Upload
          accept={acceptedTypes.join(",")}
          customRequest={() => {}}
          fileList={fileList}
          onChange={({ fileList: newFileList }) => onFileListChange(name, newFileList)}
          beforeUpload={beforeUpload}
          showUploadList={false}
        >
          <Button
            className={styles.upload}
            variant="dashed"
            icon={
              <div className={styles.circle}>
                <Icon name="Upload" color={colors.textSecondary} width={16} height={16} strokeWidth={2.4} />
              </div>
            }
          >
            <div className="align-center justify-center" style={{ flex: 1, maxWidth: "100%" }}>
              <div className="flex-column">
                <Text variant="link" className="mb-xxs">
                  {t("global.upload")}
                </Text>
                <Text className="flex-1" variant="caption">
                  {t("pro.settings.licenses.file_restrictions")}
                </Text>
              </div>
            </div>
          </Button>
        </Upload>
        {enabledFileList.length > 0 && (
          <List
            className={styles.uploadList}
            dataSource={enabledFileList}
            renderItem={(file) => <UploadListItem file={file} handleDownload={downloadFile} handleDelete={handleDelete} />}
          />
        )}
      </div>
    </>
  )
}
