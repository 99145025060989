import React, { useState, useEffect } from "react"
import { Form, Button } from "antd"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import classes from "classnames"

// selectors
import { guideSelector, updateGuide, updateUser } from "store/user"
// hooks
import useFormErrors from "hooks/useAntdFormErrors"
// ui
import { CustomInput, PhoneInput } from "ui/Input"
import { CustomForm } from "ui/Form"
// utils
import { resendConfirmCode, verifyByPinCode } from "store/auth"
import { CustomInputError } from "ui/CustomInputError"
import { notification } from "ui/Notification"

export const ChangeEmailPhoneForm = ({ inputType = "email" }) => {
  const isEmail = inputType === "email"
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.auth)
  const { email, phone_number } = useSelector((state) => state.user)
  const { id } = useSelector(guideSelector)
  const [editingInput, setEditingInput] = useState(false)
  const [codeSent, setCodeSent] = useState(false)
  const [code, setCode] = useState("")
  const [isValidValue, setSubmittable] = useState(false)
  const { errors } = useFormErrors(form)

  const onInputChange = () => {
    if (!editingInput) {
      setEditingInput(true)
    }
    const value = form.getFieldValue(inputType)
    setSubmittable(!!value)
  }

  const handleCancel = () => {
    setEditingInput(false)
    form.resetFields()
  }

  const handleSendCode = () => {
    const inputValue = form.getFieldValue(inputType)
    if (inputValue) {
      dispatch(updateGuide(id, { [inputType]: inputValue })).then(() => {
        dispatch(updateUser({ [inputType]: inputValue })).then(() => {
          notification.success({
            message: t(`settings.${inputType}_updated`)
          })

          dispatch(resendConfirmCode({ [inputType]: inputValue })).then(() => {
            setCodeSent(true)
          })
        })
      })
    }
  }

  const handleConfirmCode = () => {
    const code = form.getFieldValue("code")
    dispatch(verifyByPinCode(code)).then((res) => {
      notification.success({
        message: t(`settings.${inputType}_confirmed`)
      })
      setCodeSent(false)
      setEditingInput(false)
      form.setFieldValue("code", "")
      setCode("")
    })
  }

  return (
    <CustomForm form={form} initialValues={{ email, phone_number }} layout="vertical">
      <Form.Item
        hidden={!isEmail}
        label={t("sign_up.email")}
        name="email"
        required
        autoComplete="off"
        rules={[
          { type: "email", message: t("form_validation.email") },
          { required: true, message: t("form_validation.cannot_be_blank") }
        ]}
        help={!!errors?.email ? <CustomInputError errorKey={errors.email} showLinkText={false} /> : null}
      >
        <CustomInput placeholder="Enter Email" onChange={onInputChange} />
      </Form.Item>
      <Form.Item
        hidden={isEmail}
        label={t("guide.phone_number")}
        name="phone_number"
        required={true}
        rules={[{ required: true, message: t("form_validation.phone_number_invalid") }]}
        help={!!errors?.phone_number ? <CustomInputError errorKey={errors.phone_number} showLinkText={false} /> : null}
      >
        <PhoneInput initialValue={phone_number} onChange={onInputChange} />
      </Form.Item>

      <div className={classes("flex gap-s mb-s", { hidden: !editingInput })}>
        <SendCodeButton onClick={handleSendCode} disabled={!isValidValue} />
        <Button color="primary" variant="outlined" size="medium" onClick={handleCancel}>
          {t("global.cancel")}
        </Button>
      </div>
      <div className={classes({ hidden: !editingInput || !codeSent })}>
        <Form.Item
          label={t("enter_code.title")}
          name="code"
          required
          autoComplete="off"
          rules={[
            { max: 5, message: t("form_validation.five_digit_code") },
            { required: true, message: t("enter_code.title") }
          ]}
          help={!!errors?.pin_code ? <CustomInputError errorKey={errors.pin_code} /> : null}
        >
          <CustomInput placeholder="XXXXX" onChange={(e) => setCode(e.target.value)} />
        </Form.Item>
        <Button className="mb-s" type="primary" size="medium" disabled={code.length !== 5 || loading} onClick={handleConfirmCode}>
          {t("global.confirm")}
        </Button>
      </div>
    </CustomForm>
  )
}

const SendCodeButton = ({ onClick, disabled }) => {
  const [timer, setTimer] = useState(0)
  const { t } = useTranslation()

  useEffect(() => {
    let interval
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    } else if (timer === 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [timer])

  const handleClick = () => {
    onClick()
    setTimer(60)
  }

  return (
    <Button type="primary" size="medium" onClick={handleClick} disabled={disabled || timer > 0}>
      {timer > 0 ? (
        <div className="flex">
          {t("enter_code.resend_in")}
          <div style={{ width: 25 }}>{timer}</div>
          {"sec."}
        </div>
      ) : (
        t("password_recovery.send_code")
      )}
    </Button>
  )
}
