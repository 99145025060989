import React, { memo, useCallback } from "react"
import PropTypes from "prop-types"
import { Link, createSearchParams, useNavigate } from "react-router-dom"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { Button, ButtonGroup } from "reactstrap"
import { oldRoutes } from "router/old-routes"
import PhotoSlider from "../common/PhotoSlider"
import { message } from "antd"
import Icon from "components/common/Icon"

import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
import { useTranslation } from "react-i18next"
import { numberToCurrency } from "helpers/string"
import { calculateChanges } from "components/bookings/helpers"
import pluralize from "pluralize"
import moment from "moment"

import { useDispatch, useSelector } from "react-redux"
import { duplicateTrip, updateTrip } from "store/trips"

import { showModal } from "modules/modals/reducer"
import { createBooking } from "store/bookings"
import { createFavoriteTrip, deleteFavouriteTrip } from "store/favourite-trips"
import { isGuideSelector } from "store/user"

import { TIME_FORMAT } from "modules/datepicker/constants"
import { generateScriptForEmbedCheckout } from "./utils"
import { getHostsByEnvironment } from "utils/host-urls"

const Trip = ({ trip, className, style, onClose, preview = false, withModal = false, withRating = false, guideView = false, ...rest }) => {
  const { id, title, description, status, min_guests, max_guests, start_time, end_time, address, photos, favorite_id, can_be_paid } = trip
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const confirmModal = useConfirmModal()
  const loggedIn = useSelector((store) => store.auth.loggedIn)
  const userId = useSelector((state) => state.user.id)
  const isGuide = useSelector(isGuideSelector)

  const calculation = calculateChanges(null, { trip })

  const tripLength = moment(end_time, TIME_FORMAT).diff(moment(start_time, TIME_FORMAT), "minute") || 0
  const tripHours = Math.floor(tripLength / 60)
  const tripMinutes = Math.round(tripLength - tripHours * 60)
  const tripDurationLabel = `${tripHours}h${tripMinutes ? ` ${tripMinutes}m` : ""}`

  const publishTripHandler = (event) => {
    if (event.persist) event.persist()
    const id = event?.currentTarget?.value
    if (id) dispatch(updateTrip(id, { trip: { status: "published" } }))
  }
  const bookTripHandler = () => {
    if (loggedIn) dispatch(createBooking(id)).then((booking) => navigate(oldRoutes.clientBookingWizardRootPath({ uuid: booking.uuid })))
    else navigate({ pathname: oldRoutes.bookingWizardPath(), search: createSearchParams({ trip_id: id }).toString() })
  }

  const toggleFavoriteTrip = useCallback(() => {
    if (withModal && favorite_id) return removeFavoriteTrip(favorite_id)
    if (favorite_id) return dispatch(deleteFavouriteTrip(favorite_id))
    dispatch(createFavoriteTrip(id))
  }, [id, favorite_id]) //eslint-disable-line

  const shareHandler = () =>
    dispatch(
      showModal("shareModal", {
        name: "Trip",
        path: oldRoutes.tripPath({ id }),
        description,
        title
      })
    )

  const duplicateHandler = confirmModal({ title: "Are you sure you want to duplicate this trip?", submitText: t("global.yes") }, () =>
    dispatch(duplicateTrip(id))
  )

  const removeFavoriteTrip = confirmModal({ title: "Are you sure?", color: "danger", submitText: t("global.yes") }, () =>
    dispatch(deleteFavouriteTrip(favorite_id))
  )
  const unpublishTripHandler = confirmModal(
    { title: "Are you sure you want to unpublish this trip?", color: "danger", submitText: t("global.yes") },
    ({ currentTarget }) => dispatch(updateTrip(+currentTarget.value, { trip: { status: "unpublished" } }))
  )

  const handleCopyEmbedCode = async (tripId) => {
    const hosts = getHostsByEnvironment()
    try {
      const script = generateScriptForEmbedCheckout(
        `${hosts.PROTOCOL}${hosts.CUSTOMER}/#${oldRoutes.bookingWizardPath()}?trip_id=${tripId}&mode=embed`
      )
      await navigator.clipboard.writeText(script)
      message.success("Embed code copied!")
    } catch (err) {
      console.error("failed to copy embed code")
    }
  }

  const archiveTripHandler = confirmModal(
    {
      title: "Are you sure you want to archive this trip? ",
      hasSubText: true,
      subText:
        "Note: Archiving this trip will not cancel any scheduled trips with clients. If you wish to cancel those trips, it will need to be done manually.",
      color: "danger",
      submitText: t("global.yes")
    },
    ({ currentTarget }) => dispatch(updateTrip(+currentTarget.value, { trip: { status: "archived" } }))
  )

  const unarchiveTripHandler = confirmModal(
    { title: "Are you sure you want to unarchive this trip?", color: "danger", submitText: t("global.yes") },
    ({ currentTarget }) => dispatch(updateTrip(+currentTarget.value, { trip: { status: "unpublished" } }))
  )

  const classes = ["hstack flex-wrap bg-white rounded overflow-hidden shadow-lg position-relative w-100"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")} style={style} {...rest}>
      <div className="flex-shrink-0 align-self-stretch position-relative" style={{ flexBasis: 200, flexGrow: 1 }}>
        <div className="position-absolute top-0 start-0 w-100 p-3 z-2">
          {isGuide ? (
            <StatusForGuide status={status} />
          ) : (
            <UserActions {...{ userId, favorite_id, onClose, toggleFavoriteTrip, onShare: shareHandler }} />
          )}
        </div>
        {!can_be_paid && <CantBePaidTooltip />}
        <PhotoSlider photos={photos} preview className="w-100 h-100" />
      </div>
      <div className={["vstack", preview ? "gap-1 p-3" : "gap-3 p-4"].join(" ")} style={{ flexBasis: 200, flexGrow: 4 }}>
        <Link
          to={guideView ? oldRoutes.guideTripPath({ id }) : oldRoutes.tripPath({ id })}
          className="link-dark link-underline-opacity-0 text-primary-second-hover"
        >
          <h3 className={["lh-sm", preview ? "h5" : "h4"].join(" ")}>{title || "Draft Trip"}</h3>
        </Link>
        <div className="grid gap-y-2 gap-x-1 mb-auto lh-sm" style={{ gridTemplateColumns: "repeat(auto-fit, minmax(162px, 1fr))" }}>
          <div className="hstack gap-2 align-items-start">
            <Icon iconName="Location" className="flex-shrink-0" width={20} />
            <span className="fs-7">{address || "—"}</span>
          </div>
          {isGuide && (
            <div className="hstack gap-2 align-items-start">
              <Icon iconName="Clock" className="flex-shrink-0" width={20} />
              <span className="fs-7 mt-1">{tripDurationLabel || "—"}</span>
            </div>
          )}
          <div className="hstack gap-2 align-items-start">
            <Icon iconName="Users" className="flex-shrink-0" width={20} />
            <span className="fs-7 mt-1">
              {(min_guests === max_guests
                ? t("trip.card.persons", { count: min_guests })
                : t("trip.card.persons_range", { min_guests, max_guests })) || "—"}
            </span>
          </div>
          {!isGuide && (
            <div className="hstack gap-2 align-items-start">
              <Icon iconName="Clock" className="flex-shrink-0" width={20} />
              <span className="fs-7 mt-1">{tripDurationLabel || "—"}</span>
            </div>
          )}
          {isGuide && <Pricing calculation={calculation} />}
        </div>
        {isGuide ? (
          <GuideButtons
            {...{
              id,
              status,
              publishTripHandler,
              unpublishTripHandler,
              duplicateHandler,
              archiveTripHandler,
              unarchiveTripHandler,
              handleCopyEmbedCode
            }}
          />
        ) : (
          <UserButtons {...{ withRating, calculation, bookTripHandler }} />
        )}
      </div>
    </div>
  )
}

const StatusForGuide = ({ status }) => {
  const { t } = useTranslation()
  return (
    <div className="hstack fs-6 fw-semibold text-white">
      {(status === "published" && (
        <div className="hstack bg-primary rounded-pill p-1 pe-2">
          <Icon iconName="Checked" className="me-1" />
          {t("trip.card.published")}
        </div>
      )) ||
        (status === "archived" && (
          <div className="hstack bg-primary-second rounded-pill p-1 pe-2">
            <Icon iconName="LegalProfessional" className="me-1" />
            {t("trip.card.archived")}
          </div>
        )) || <div className="hstack bg-gray-light rounded-pill py-1 px-25">{t("trip.card.draft")}</div>}
    </div>
  )
}

const CantBePaidTooltip = () => {
  const { t } = useTranslation()
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip className="pointer-events-none">
          <h5>{t("trip.card.cant_be_paid.title")} </h5>
          <p className="m-0">{t("trip.card.cant_be_paid.text")} </p>
        </Tooltip>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <div
          className="position-absolute bottom-0 start-50 translate-middle-x mb-3 px-20 py-10 z-2 rounded-1 border border-danger bg-danger bg-opacity-10 text-danger fs-4 fw-semibold lh-1 text-nowrap"
          {...triggerHandler}
        >
          Can not be paid! <i className="far fa-circle-question" ref={ref} />
        </div>
      )}
    </OverlayTrigger>
  )
}

const UserActions = memo(({ userId, favorite_id, onClose, toggleFavoriteTrip, onShare: shareHandler }) => (
  <div className="hstack gap-10">
    {typeof onClose === "function" && (
      <Button color="icon" onClick={onClose} className="p-0 border-0 me-auto position-relative">
        <div
          className="bg-dark bg-opacity-50 rounded-circle position-absolute top-50 start-50 translate-middle"
          style={{ width: 18, height: 18 }}
        />
        <Icon iconName="CloseFill" className="text-white text-opacity-75-hover position-relative z-1" size={20} block />
      </Button>
    )}
    <div className="hstack gap-1">
      {userId && (
        <Button
          color="ghost"
          className={[
            "p-1",
            favorite_id ? "bg-primary bg-opacity-75-hover text-white" : "bg-white bg-opacity-25 bg-opacity-100-hover text-dark"
          ].join(" ")}
          onClick={toggleFavoriteTrip}
        >
          <Icon iconName="Like" className={["flex-shrink-0"].join(" ")} size={18} block />
        </Button>
      )}
      <Button color="ghost" className="bg-white bg-opacity-25 bg-opacity-100-hover p-1" onClick={shareHandler}>
        <Icon iconName="Share" className="flex-shrink-0" size={18} block />
      </Button>
    </div>
  </div>
))

const Pricing = memo(({ calculation, className }) => {
  const { t } = useTranslation()

  let classes = "hstack gap-2 align-items-start"
  if (className) classes = classes.concat(" ", className)

  if (!calculation.pricingType) return "—"

  return (
    <div className={classes}>
      <Icon iconName="Coin" className="flex-shrink-0" width={20} />
      <small
        className="fs-7"
        dangerouslySetInnerHTML={{
          __html: t(`trip.card.starting_price.${calculation.pricingType}`, {
            flatRate: numberToCurrency(calculation.flatRate),
            minimumRate: numberToCurrency(calculation.minimumRate),
            minimumPersons: pluralize("person", calculation.minimumPersons, true),
            pricePerPerson: numberToCurrency(calculation.pricePerPerson)
          })
        }}
      />
    </div>
  )
})

const UserButtons = memo(({ withRating, calculation, bookTripHandler }) => {
  const { t } = useTranslation()
  return (
    <div className="hstack gap-10 flex-wrap mt-auto">
      {withRating && (
        <div className="mt-auto flex-fill">
          <div className="small text-dark text-opacity-50 mb-1">Top Rated Guide</div>
          <div className="hstack gap-1">
            {Array(5)
              .fill(null)
              .map((_, index) => (
                <Icon key={index} iconName="StarNew" className="text-primary-second" size={18} block />
              ))}
          </div>{" "}
        </div>
      )}
      <div className={`${withRating ? "align-content-end" : ""} justify-content-start hstack flex-fill gap-2 flex-wrap mt-auto`}>
        <div className="vstack gap-1 align-items-end me-auto">
          <div className="hstack gap-1 align-items-end me-auto">
            <span className="fs-3 fw-medium lh-1">
              {(calculation.pricingType === "charge_per_person" && numberToCurrency(calculation.pricePerPerson)) ||
                (calculation.pricingType === "charge_flat_rate" && numberToCurrency(calculation.flatRate)) ||
                numberToCurrency(calculation.minimumRate)}
            </span>
            <span className="fs-7">
              <small>
                {(calculation.pricingType === "charge_per_person" && "/person") ||
                  (calculation.pricingType === "charge_flat_rate" && "/all") ||
                  "/" + pluralize("person", calculation.minimumPersons, true)}
              </small>
            </span>
          </div>
          {calculation.pricingType === "set_minimum_rate" && (
            <div className="hstack gap-1 align-items-end">
              <span className="fs-6 fw-medium lh-1">+{numberToCurrency(calculation.pricePerPerson)}</span>
              <span className="fs-7 lh-1">
                <small>/addition person</small>
              </span>
            </div>
          )}
        </div>
        <Button
          color="primary"
          className="px-4 py-2 fs-7"
          onClick={bookTripHandler}
          onTouchStart={(e) => {
            e.stopPropagation()
            e.preventDefault()
            bookTripHandler()
          }}
        >
          {t("global.book")}
        </Button>
      </div>
    </div>
  )
})
const GuideButtons = memo(
  ({
    id,
    status,
    publishTripHandler,
    unpublishTripHandler,
    duplicateHandler,
    archiveTripHandler,
    unarchiveTripHandler,
    handleCopyEmbedCode
  }) => {
    const { t } = useTranslation()
    return (
      <div className="hstack gap-10 flex-wrap flex-row-reverse mt-auto">
        <Button
          color="link"
          className="link-danger link-underline-opacity-0 link-underline-opacity-75-hover p-0 ms-auto fs-7"
          value={id}
          onClick={status === "archived" ? unarchiveTripHandler : archiveTripHandler}
        >
          <small>{status === "archived" ? t("trip.card.unarchive") : t("global.archive")}</small>
        </Button>
        <div className="hstack gap-10 flex-fil flex-wrap">
          {(status === "unpublished" && (
            <Button color="primary" className="px-5 py-2 fs-7 flex-fill" value={id} onClick={publishTripHandler}>
              {t("global.publish")}
            </Button>
          )) ||
            (status === "published" && (
              <Button color="gray-light" className="px-5 py-2 fs-7 flex-fill text-white" value={id} onClick={unpublishTripHandler}>
                {t("global.unpublish")}
              </Button>
            )) ||
            (status === "published" && (
              <Button color="gray-light" className="px-5 py-2 fs-7 flex-fill text-white" value={id} onClick={unpublishTripHandler}>
                {t("global.unpublish")}
              </Button>
            ))}
          <ButtonGroup>
            <Button
              tag={Link}
              color="primary-second"
              outline
              className="px-5 py-2 fs-7 flex-fill"
              to={oldRoutes.guideTripWizardRootPath({ id: id })}
            >
              {status === "draft" ? t("global.continue") : t("global.edit")}
            </Button>
            {status !== "draft" && (
              <Button color="primary-second" outline className="px-10 py-2 fs-7 flex-fill" onClick={duplicateHandler}>
                <i className="fas fa-clone" />
              </Button>
            )}
            {status === "published" && (
              <Button color="primary-second" outline className="px-10 py-2 fs-7 flex-fill" onClick={() => handleCopyEmbedCode(id)}>
                <i className="fas fa-code" />
              </Button>
            )}
          </ButtonGroup>
        </div>
      </div>
    )
  }
)

Trip.propTypes = {
  trip: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
    min_guests: PropTypes.number,
    max_guests: PropTypes.number,
    start_time: PropTypes.string,
    end_time: PropTypes.string,
    address: PropTypes.string,
    photos: PropTypes.arrayOf(PropTypes.string),
    favorite_id: PropTypes.string,
    can_be_paid: PropTypes.bool
  }).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  onClose: PropTypes.func,
  preview: PropTypes.bool,
  withModal: PropTypes.bool,
  withRating: PropTypes.bool,
  guideView: PropTypes.bool
}

export default memo(Trip)
