import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import { FreeMode, Navigation, Pagination, Thumbs } from "swiper/modules"
import { ChevronLeft, ChevronRight } from "react-feather"
import { VideoPlayer } from "ui/VideoPlayer"
import { MediaPreview } from "ui/MediaPreview"

// styles
import styles from "./GallerySlider.module.css"

const THUMBNAILS_SCALE = 120

export const GallerySlider = ({ mediaItems = [], preview = true, activeSlide, ratio = "16x9" }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const slideLeftRef = useRef(null)
  const slideRightRef = useRef(null)
  const swiperRef = useRef(null)
  const videoRef = useRef(null)

  useEffect(() => {
    if (activeSlide !== null && swiperRef.current) {
      swiperRef.current.slideTo(activeSlide)
    }
  }, [activeSlide])

  const onInit = (swiper) => {
    swiper.params.navigation.prevEl = slideLeftRef.current
    swiper.params.navigation.nextEl = slideRightRef.current
    swiper.navigation.init()
    swiper.navigation.update()
    swiperRef.current = swiper
  }

  const handleNavigationClick = () => {
    if (videoRef.current && !videoRef.current.paused) {
      videoRef.current.pause()
    }
  }

  const isVideo = (url) => {
    return url?.match(/\.(mp4|webm|ogg)$/i)
  }

  const renderMedia = (media) => {
    if (isVideo(media.url)) {
      return (
        <VideoPlayer
          src={media.url}
          thumbnailUrl={media.placeholder_url}
          onPlay={(videoElement) => {
            videoRef.current = videoElement
          }}
        />
      )
    } else {
      return <MediaPreview wrapperClassName="w-full h-full" className="object-cover w-full h-full rounded" media={media} />
    }
  }

  return (
    <div className="flex-column flex-1 justify-between">
      <Swiper
        style={{
          height: `calc(100vh - ${THUMBNAILS_SCALE * 2.25}px)`,
          width: "95%"
        }}
        className="m-auto"
        onInit={onInit}
        spaceBetween={10}
        slidesPerView={1}
        centeredSlides={true}
        navigation
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs, Pagination]}
        pagination={{ horizontalClass: "bottom-0" }}
      >
        {mediaItems.map((photo, index) => (
          <SwiperSlide key={photo.id} index={index} className={`w-full h-auto rounded flex justify-center ratio ratio-${ratio}`}>
            {renderMedia(photo)}
          </SwiperSlide>
        ))}
        {mediaItems.length ? (
          <>
            <div className="swiper-button-prev fixed top-0 left-0 m-0 h-full w-auto" ref={slideLeftRef} onClick={handleNavigationClick}>
              <div className={styles.arrow}>
                <ChevronLeft color="gray" />
              </div>
            </div>
            <div className="swiper-button-next fixed top-0 right-0 m-0 h-full w-auto" ref={slideRightRef} onClick={handleNavigationClick}>
              <div className={styles.arrow}>
                <ChevronRight color="gray" />
              </div>
            </div>
          </>
        ) : null}
      </Swiper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        freeMode={true}
        slidesPerView={mediaItems.length < 10 ? mediaItems.length : 10}
        modules={[FreeMode, Navigation, Thumbs]}
        className={styles.thumbnails}
        style={{
          height: THUMBNAILS_SCALE
        }}
      >
        {mediaItems.map((media, index) => (
          <SwiperSlide
            key={media.id}
            style={{
              minWidth: THUMBNAILS_SCALE
            }}
            index={index}
            className="cursor-pointer rounded"
            onClick={handleNavigationClick}
          >
            <MediaPreview wrapperClassName="w-full h-full" className="object-cover w-full h-full rounded" media={media} playIconSize="sm" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

GallerySlider.propTypes = {
  mediaItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      preview_url: PropTypes.string,
      placeholder_url: PropTypes.string
    })
  ).isRequired,
  preview: PropTypes.bool,
  ratio: PropTypes.string
}
