import React from "react"
import styles from "./MenuItem.module.css"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import { Icon } from "ui/Icon"

export const MenuItem = ({ item }) => {
  const navigate = useNavigate()
  const { activeMenu } = useSelector((state) => state.guideUserInfo)
  const { name, id, complete } = item
  const isActive = activeMenu === id

  const onClickHandler = () => {
    if (item.path) {
      navigate(item.path)
    }
  }

  return (
    <div
      className={classNames(styles.menuGroupItem, isActive && styles.menuGroupItemActive, complete && styles.menuGroupItemCompleted)}
      onClick={onClickHandler}
    >
      {name}
      {complete && <Icon name="complete" />}
    </div>
  )
}
