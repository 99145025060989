import hosts from "constants/hosts"

const getHostsByEnvironment = () => {
  const envHost = process.env.REACT_APP_PRO_HOST

  if (!envHost) {
    console.error("REACT_APP_PRO_HOST is not set.")
    return null
  }

  let environment = null

  for (const env in hosts) {
    if (Object.prototype.hasOwnProperty.call(hosts, env)) {
      const environmentHosts = hosts[env]
      if (Object.values(environmentHosts).includes(envHost)) {
        environment = env
        break
      }
    }
  }

  if (!environment) {
    console.error(`No matching host found for: ${envHost}`)
    return null
  }

  return hosts[environment]
}

export { getHostsByEnvironment }
