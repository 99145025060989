import { Button } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { Text } from "ui/Typography"
import { useGoogleLogin } from "@react-oauth/google"
import { googleSignIn } from "store/auth"
import { useDispatch } from "react-redux"
import googleIcon from "assets/imagesV2/social/google.svg"
import { routes } from "router"
import { useNavigate } from "react-router-dom"
import { getRole } from "utils/app-type"

export const GoogleLoginButton = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const role = getRole()

  const login = useGoogleLogin({
    onSuccess: (response) => {
      const code = response.code
      if (code) {
        dispatch(googleSignIn({ code, role })).then((res) => {
          navigate(routes.confirmInfo({}, { ...res.user, oauth_type: "google" }))
        })
      }
    },
    flow: "auth-code"
  })

  return (
    <Button block color="default" variant="outlined" size="large" onClick={login}>
      <div className="align-center">
        <img className="mr-xs" src={googleIcon} alt={""} />
        <Text bold>{t("sign_in.google_login")}</Text>
      </div>
    </Button>
  )
}
