import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { CloseButton, Offcanvas } from "react-bootstrap"
import Loading from "modules/loader-watchers/Loading"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useOffcanvasHandlers } from "./hooks"

// Redux
import { useSelector } from "react-redux"

OffcanvasWindow.propTypes = {
  container: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
  width: PropTypes.number,
  top: PropTypes.func,
  exitRedirect: PropTypes.string,
  loadingName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
}
export default function OffcanvasWindow({ container, width = 850, top, exitRedirect, loadingName, children, ...rest }) {
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const { isOpen, openHandler, closeHandler, exitHandler, setChanged } = useOffcanvasHandlers({ exitRedirect })

  useEffect(() => openHandler(), []) // eslint-disable-line

  return (
    <Offcanvas
      placement="end"
      className="border-0 shadow"
      backdropClassName="bg-transparent"
      {...rest}
      style={{
        ...rest.style,
        top: (typeof top === "function" ? top?.(headerHeight) : top) || headerHeight || 80,
        width
      }}
      scroll
      show={isOpen}
      onHide={closeHandler}
      onExited={exitHandler}
      container={container}
    >
      <CloseButton
        onClick={closeHandler}
        className="d-block d-md-none position-absolute"
        style={{ zIndex: 2000, top: "10px", right: "10px" }}
      />
      <Loading
        name={loadingName}
        tag={Offcanvas.Body}
        className="vstack p-20 h-100 overflow-y-scroll"
        spinnerProps={{ className: "z-index-overlay" }}
      >
        <DefaultErrorBoundary>{children?.({ closeHandler, setChanged }) || children}</DefaultErrorBoundary>
      </Loading>
    </Offcanvas>
  )
}
