export const downloadFile = (file) => {
  const a = document.createElement("a")
  const fileUrl = file.url || file.preview

  if (fileUrl) {
    if (fileUrl.startsWith("data:")) {
      a.href = fileUrl
    } else {
      fetch(fileUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const blobUrl = URL.createObjectURL(blob)
          a.href = blobUrl
          a.download = file.filename || file.name
          a.click()
          URL.revokeObjectURL(blobUrl)
        })
        .catch((error) => console.error("Error fetching the file:", error))
    }
  } else if (file.originFileObj) {
    const blobUrl = URL.createObjectURL(file.originFileObj)
    a.href = blobUrl
    a.download = file.name
    a.click()
    URL.revokeObjectURL(blobUrl)
  } else {
    console.error("File is not available for download")
  }
}

export const mapLicenses = (documents = []) => {
  const licenses = {
    driver_license: [],
    capitan_license: [],
    guide_license: [],
    fishing_permit: [],
    waiver: [],
    other: []
  }

  documents.forEach((doc) => licenses[doc.file_type].push(doc))

  return licenses
}

export const serializeLicensesData = (documents) => {
  const result = {
    documents: [],
    documents_attributes: []
  }

  Object.keys(documents).forEach((fileType) => {
    const fileGroup = documents[fileType]

    fileGroup.forEach((file) => {
      const document = {}

      if (file.url) {
        document.id = file.id
        document.url = file.url
        document.file_type = file.file_type
        document.filename = file.filename
        result.documents.push(document)
      } else if (file.originFileObj) {
        document.file = file.originFileObj
        document.file_type = fileType
        document._id = file.uid || Date.now()
        result.documents.push(document)
      }

      if (file.id) {
        result.documents_attributes.push({
          id: file.id,
          file_type: file.file_type,
          _destroy: file._destroy
        })
      } else {
        result.documents_attributes.push({
          file: file.originFileObj,
          _destroy: file._destroy,
          file_type: fileType
        })
      }
    })
  })

  return result
}
