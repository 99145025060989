import { Input } from "antd"
import "./CustomInput.css"
import classNames from "classnames"

export const CustomInput = ({ Component = Input, ...props }) => {
  const { borderless, className } = props
  return <Component {...props} className={classNames("custom-input", borderless && "custom-borderless-input", className)} />
}

CustomInput.Password = (props) => <CustomInput Component={Input.Password} {...props} />
