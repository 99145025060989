import React from "react"

export const Fuel = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.2306 1.43626C18.2118 1.43626 18.195 1.43814 18.1781 1.44001H11.04C10.9125 1.44001 10.7906 1.49064 10.7006 1.58064L7.67997 4.60126L6.57934 3.50064C6.39184 3.31314 6.08809 3.31314 5.90059 3.50064L3.98059 5.42064C3.79309 5.60814 3.79309 5.91189 3.98059 6.09939L5.08122 7.20001L3.98059 8.30064C3.89059 8.39064 3.83997 8.51251 3.83997 8.64001V20.64C3.83997 21.6956 4.70434 22.56 5.75997 22.56H18.24C19.2956 22.56 20.16 21.6956 20.16 20.64V3.36001C20.16 2.35314 19.3687 1.52814 18.3806 1.45501C18.3318 1.44001 18.2812 1.43439 18.2306 1.43626ZM11.2387 2.40001H17.0812L14.6812 4.80001H8.83872L11.2387 2.40001ZM18.42 2.41876C18.8681 2.50126 19.2 2.88564 19.2 3.36001V20.64C19.2 21.1763 18.7762 21.6 18.24 21.6H5.75997C5.22372 21.6 4.79997 21.1763 4.79997 20.64V8.83876L7.87872 5.76001H14.88C15.0075 5.76001 15.1293 5.70939 15.2193 5.61939L18.42 2.41876ZM6.23997 4.51876L7.00122 5.28001L5.75997 6.52126L4.99872 5.76001L6.23997 4.51876ZM12.0206 9.12001C11.8762 9.11439 11.7375 9.17251 11.6418 9.27939C11.6418 9.27939 10.905 10.1025 10.1681 11.1544C9.42934 12.2063 8.63997 13.4456 8.63997 14.5931C8.63997 16.3594 10.1737 17.76 12 17.76C13.8262 17.76 15.36 16.3594 15.36 14.5931C15.36 13.4456 14.5706 12.2063 13.8318 11.1544C13.095 10.1025 12.3581 9.27939 12.3581 9.27939C12.27 9.18376 12.15 9.12564 12.0206 9.12001ZM12 10.3744C12.2325 10.6444 12.525 10.9613 13.0481 11.7056C13.7493 12.705 14.4 13.9631 14.4 14.5931C14.4 15.8006 13.3425 16.8 12 16.8C10.6575 16.8 9.59997 15.8006 9.59997 14.5931C9.59997 13.9631 10.2506 12.705 10.9518 11.7056C11.475 10.9613 11.7675 10.6444 12 10.3744Z"
      fill={fill}
    />
  </svg>
)
