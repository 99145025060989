import React, { memo, useEffect } from "react"
import { Table } from "reactstrap"
import Income from "./Income"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import WithEmptyState from "components/common/WithEmptyState"
import Loading from "modules/loader-watchers/Loading"

import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { getGuideBookingIncomes } from "store/bookings"
import { collectionAnySelector, collectionSelector, modelSelector } from "store/selectors"
import { firstLoadingSelector } from "modules/loader-watchers/selectors"

const Incomes = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const booking = useSelector(modelSelector("booking"))
  const incomes = useSelector(collectionSelector("bookings.incomes"))
  const hasIncomes = useSelector(collectionAnySelector("bookings.incomes"))
  const firstLoading = useSelector(firstLoadingSelector("bookings.incomes"))

  useEffect(() => {
    if (firstLoading && booking.id) dispatch(getGuideBookingIncomes(booking.id))
  }, [firstLoading, booking.id]) //eslint-disable-line

  return (
    <FirstLoading name="bookings.incomes">
      <Loading name="bookings.incomes" spinnerProps={{ className: "m-n2" }}>
        <WithEmptyState name="payment" hasItems={hasIncomes} className="vstack gap-20" emptyProps={{ className: "p-0" }} fallbackText="">
          <Table hover>
            <thead>
              <tr className="border-bottom border-gray-lightest">
                <th className="text-dark text-opacity-50 fw-normal ps-0" width="1">
                  {t("global.date")}
                </th>
                <th className="text-dark text-opacity-50 fw-normal text-end">{t("booking.payment.labels.amount")}</th>
                <th className="text-dark text-opacity-50 fw-normal text-center">{t("booking.payment.labels.payment_type")}</th>
                <th className="text-dark text-opacity-50 fw-normal text-center"></th>
                <th className="text-dark text-opacity-50 fw-normal text-end pe-0">{t("global.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {incomes.map((income) => (
                <Income income={income} key={income.id} />
              ))}
            </tbody>
          </Table>
        </WithEmptyState>
      </Loading>
    </FirstLoading>
  )
}

export default memo(Incomes)
