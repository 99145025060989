export const btnBorderRadius = 36
export const lineWidth = 1.5

export const colors = Object.freeze({
  transparent: "transparent",
  textPrimary: "#272A34",
  textSecondary: "#787E80",
  textTertiary: "#B7B7B7",
  sportsmanBrand: "#1D72F4",
  sportswomanBrand: "#D92C9E",
  lines: "#E9ECEF",
  disabled: "#F9FBFC",
  success: "#05A44F",
  error: "#F25330",
  warning: "#F98600",
  blueSurface: "#E9F1FE",
  surface1: "#F9FBFC",
  surface2: "#F9FBFB",
  surfaceLightViolet: "#F2DEF8"
})

export const colorsRGB = Object.freeze({
  sportsmanBrand: "29, 114, 244",
  sportswomanBrand: "179, 36, 130"
})

export const corners = Object.freeze({
  NONE: "0px",
  XS: "4px",
  S: "8px",
  M: "12px",
  L: "32px",
  XL: "300px"
})

export const spacings = Object.freeze({
  NONE: "0px",
  XXS: "4px",
  XS: "8px",
  S: "12px",
  M: "16px",
  L: "20px",
  XL: "24px",
  XXL: "28px",
  "3XL": "32px",
  "4XL": "40px",
  "5XL": "60px",
  "6XL": "80px"
})

export const fontSize = Object.freeze({
  MD: 14,
  LG: 16,
  SM: 12
})
