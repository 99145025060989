import React from "react"
import { useGoogleLogin } from "@react-oauth/google"
import { useDispatch, useSelector } from "react-redux"
import { LinkedAccount } from "./LinkedAccount"
import googleIcon from "assets/imagesV2/social/google.svg"
import { connectOAuthProvider } from "store/user"

const provider = "google_oauth2"

export const GoogleConnect = () => {
  const dispatch = useDispatch()
  const { connected_oauth_providers } = useSelector((state) => state.user)
  const connected = connected_oauth_providers?.includes(provider)

  const connectGoogle = useGoogleLogin({
    onSuccess: (response) => {
      const code = response.code
      if (code) {
        dispatch(connectOAuthProvider({ provider, code }))
      }
    },
    flow: "auth-code"
  })

  return <LinkedAccount icon={googleIcon} account="Google" provider={provider} connectOAuth={connectGoogle} connected={connected} />
}
