import React, { useEffect } from "react"
import { Layout, Input, List } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { useSearchParams } from "react-router-dom"
import { Conversation } from "../Conversation"
import { sumBy, debounce } from "lodash"

import { useSelector, useDispatch } from "react-redux"
import { getConversations, setViewedMessagesCount } from "store/conversations"
import { collectionFirstSelector, collectionSelector } from "store/selectors"
import { firstLoadingSelector } from "modules/loader-watchers/selectors"
import styles from "./ConversationList.module.css"
import classNames from "classnames"
import { NavigationPath } from "layoutsV2/pro/ProHomeLayout/NavigationPath"
const { Sider } = Layout

export const ConversationList = ({ selectedConversationId, isSmallDevice }) => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const role = useSelector((state) => state.user.role)
  const firstLoading = useSelector(firstLoadingSelector("conversations"))
  const conversationsFirst = useSelector(collectionFirstSelector("conversations"))
  const conversationsFirstId = conversationsFirst?.id
  const conversations = useSelector(collectionSelector("conversations"))
  const isGuide = role === "guide_user"
  const filteredConversationsById = conversations.filter((convresation) => convresation.id === selectedConversationId)
  const unviewed = sumBy(conversations, "unviewed")
  let totalUnviewed = null

  if (unviewed > 0 && filteredConversationsById.lenght > 0) totalUnviewed = unviewed - Number(filteredConversationsById.at(0).unviewed)

  useEffect(() => {
    if (!selectedConversationId && conversationsFirstId) searchParams.set("conversation_id", conversationsFirstId)
  }, [selectedConversationId, conversationsFirstId]) //eslint-disable-line

  useEffect(() => {
    if (firstLoading) dispatch(getConversations(null))
  }, [firstLoading]) //eslint-disable-line

  useEffect(() => {
    dispatch(setViewedMessagesCount(totalUnviewed))
  }, [totalUnviewed]) //eslint-disable-line

  const handleSearchChange = debounce((e) => {
    const value = e.target.value
    dispatch(getConversations(null, { q: value }))
  }, 300)

  return (
    <Sider width={isSmallDevice ? "100%" : 428} className={classNames(isSmallDevice && styles.smallDevice, styles.sider)}>
      {isSmallDevice && <NavigationPath className="pl-m pr-m" />}
      <div className={styles.searchBox}>
        <Input
          className={styles.searchInput}
          placeholder="Search conversations"
          prefix={<SearchOutlined />}
          onChange={handleSearchChange}
        />
      </div>
      <List className={styles.list} dataSource={conversations} renderItem={(item) => <Conversation key={item.id} conversation={item} />} />
    </Sider>
  )
}
