import { useDispatch } from "react-redux"
import React from "react"
import { Button } from "antd"
import { useTranslation } from "react-i18next"
import FacebookLogin from "@greatsumini/react-facebook-login"
import { Text } from "ui/Typography"
import facebookIcon from "assets/imagesV2/social/facebook-squared.svg"
import { facebookSignIn } from "store/auth"
import { routes } from "router"
import { useNavigate } from "react-router-dom"
import { getRole } from "utils/app-type"

export const FacebookLoginButton = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const role = getRole()

  const onSuccess = (response) => {
    const code = response.accessToken
    if (code) {
      dispatch(facebookSignIn({ code, role })).then((res) => {
        navigate(routes.confirmInfo({}, { ...res.user, oauth_type: "facebook" }))
      })
    }
  }

  const onFail = (response) => {
    // implement error handling
    console.error(response)
  }
  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      fields="name,email,picture"
      onSuccess={onSuccess}
      onFail={onFail}
      render={({ onClick }) => (
        <Button block color="default" variant="outlined" size="large" onClick={onClick}>
          <div className="align-center">
            <img className="mr-xs" src={facebookIcon} alt={""} />
            <Text bold>{t("sign_in.facebook_login")}</Text>
          </div>
        </Button>
      )}
    />
  )
}
