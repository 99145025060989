import { Button } from "antd"
import { useTranslation } from "react-i18next"

// hooks
import { useDevice } from "hooks/useDevice"

export const ActionButtons = ({
  onCancelClick,
  onSaveClick,
  disabled,
  loading,
  cancelText = "global.cancel",
  saveText = "settings.update",
  saveHtmlType = "submit"
}) => {
  const { t } = useTranslation()
  const { isMobile } = useDevice()

  return (
    <>
      <Button variant="outlined" color="primary" size="large" style={{ width: isMobile ? "50%" : "auto" }} onClick={onCancelClick}>
        {t(cancelText)}
      </Button>
      <Button
        className="mb-xs"
        type="primary"
        size="large"
        onClick={onSaveClick}
        style={{ width: isMobile ? "50%" : "auto" }}
        loading={loading}
        disabled={disabled}
        htmlType={saveHtmlType}
      >
        {t(saveText)}
      </Button>
    </>
  )
}
