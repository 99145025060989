import React, { useEffect } from "react"
import { Navigate, Outlet, useMatch, useParams, useSearchParams } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import { oldRoutes } from "router/old-routes"
import Navigation from "components/bookings/wizard/Navigation"
import Header from "components/layout/Header"
import withDefaultErrorBoundary from "modules/errors/HOCs/withDefaultErrorBoundary"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"

import { useScrollToActive } from "hooks/useScrollToActive"
import { useWizardRedirects } from "hooks/wizard"

import { useDispatch, useSelector } from "react-redux"
import { cleanBooking, getBooking } from "store/bookings"
import { modelSelector } from "store/selectors"

import bookTripImage from "assets/images/devise/book_trip.jpg"

function BookingLayout() {
  useLoaderWatchers({ watchers: "bookings.booking" })
  const dispatch = useDispatch()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const wizardNavigationRef = useScrollToActive()

  const isBookingWizardPage = !!useMatch(oldRoutes.bookingWizardPath())
  const isGuestBookingWizardPage = !!useMatch({ path: oldRoutes.guestBookingWizardRootPath(), end: false })
  const stepsKey = isGuestBookingWizardPage ? "bookingGuest" : "bookingClient"
  const name = "bookings.booking"
  const isEmbedBooking = searchParams.get("mode") === "embed"

  const redirect = useWizardRedirects({
    name,
    stepsKey,
    rootPath: isGuestBookingWizardPage ? oldRoutes.guestBookingWizardRootPath(params) : oldRoutes.clientBookingWizardRootPath(params),
    exitPath: isGuestBookingWizardPage ? oldRoutes.guestBookingWizardStep4Path(params) : oldRoutes.clientBookingWizardStep4Path(params)
  })
  const loading = useSelector(modelSelector("booking")).loading
  const loggedIn = useSelector((state) => state.auth.loggedIn)

  useEffect(() => {
    if ((loggedIn || isGuestBookingWizardPage) && params.uuid) dispatch(getBooking(params.uuid))
    return () => dispatch(cleanBooking())
  }, [loggedIn, isGuestBookingWizardPage, params.uuid]) //eslint-disable-line

  if (!loggedIn && !isBookingWizardPage && !isGuestBookingWizardPage)
    return <Navigate to={oldRoutes.bookingWizardPath({}, searchParams)} replace />

  return (
    ((loggedIn || isGuestBookingWizardPage) && !loading && redirect) || (
      <div className="min-vh-100 bg-light">
        {!isEmbedBooking && <Header />}
        <Container>
          <Row>
            <Col xs={12} lg={isEmbedBooking ? 12 : 7} className="z-0">
              <div className="vstack h-100 py-50">
                <div className="full-window-scroll" ref={wizardNavigationRef}>
                  <div className="py-10 py-sm-20">
                    <Navigation
                      name={name}
                      stepsKey={stepsKey}
                      showAccountLink={!isGuestBookingWizardPage || !params.uuid}
                      isEmbedBooking={isEmbedBooking}
                    />
                  </div>
                </div>
                <div className="py-30 pe-lg-60 my-auto">
                  <FirstLoading name="bookings.booking" new={isBookingWizardPage}>
                    <DefaultErrorBoundary>
                      <Outlet context={{ isGuest: isGuestBookingWizardPage, name, stepsKey }} />
                    </DefaultErrorBoundary>
                  </FirstLoading>
                </div>
              </div>
            </Col>

            {!isEmbedBooking && (
              <Col xs={12} lg={5} className="d-none d-lg-block">
                {bookTripImage ? (
                  <div className="position-fixed top-0 vh-100 z-1" style={{ marginRight: "calc((100% - 100vw) / 2)" }}>
                    <img src={bookTripImage} alt="" className="min-h-100 w-100 object-fit-cover" />
                  </div>
                ) : null}
              </Col>
            )}
          </Row>
        </Container>
      </div>
    )
  )
}

export default withDefaultErrorBoundary(BookingLayout)
