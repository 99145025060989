import React from "react"
import PropTypes from "prop-types"
import { useMatch } from "react-router-dom"
import { Alert } from "reactstrap"

import { useSelector } from "react-redux"
import { routes } from "router"

UnactiveGuideMessage.propTypes = {
  children: PropTypes.node.isRequired
}

export default function UnactiveGuideMessage({ children }) {
  const { guide, role } = useSelector((state) => state.user)
  const isSettingsPage = useMatch({ path: routes.proSettingsRootPath(), end: false })
  const isAccessed = isSettingsPage

  if (role === "user") return children
  if (guide?.active || isAccessed) return children
  return (
    <Alert
      color="primary-second"
      className="bg-primary-second bg-opacity-5 text-primary-second border-primary-second border border-opacity-5 py-20 px-40 m-0"
    >
      <h3 className="alert-heading mb-10">Your account is currently under review.</h3>
      <p className="m-0">
        Once approved, you will get access to all the features of the Digital Sportsman Platform.
        <br />
        You should receive the approval email very shortly.
      </p>
    </Alert>
  )
}
