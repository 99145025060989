import { routes } from "router/routes"

export const proMenuList = [
  {
    groupName: "menu",
    id: "menu",
    items: [
      {
        name: "Dashboard",
        path: routes.guideDashboardPath(),
        id: "dashboard",
        iconName: "dashboard"
      },
      {
        name: "Bookings",
        path: routes.guideBookingsPath(),
        id: "bookings",
        iconName: "booking"
      },
      // {
      //   name: "Calendar",
      //   path: "",
      //   id: "calendar",
      //   iconName: "calendar"
      // },
      {
        name: "Clients",
        path: routes.guideClientsPath(),
        id: "clients",
        iconName: "user"
      },
      {
        name: "Messages",
        path: routes.guideMessagesPath(),
        id: "messages",
        iconName: "messages"
      },
      {
        name: "Marketing",
        path: routes.guideMarketingPath(),
        id: "marketing",
        iconName: "marketing"
      },
      {
        name: "Accounting",
        path: routes.guideAccountingPath(),
        id: "accounting",
        iconName: "finances"
      },
      {
        name: "Resources",
        path: routes.guideBoatsPath(),
        id: "boats",
        iconName: "boat"
      },
      // {
      //   name: "Gallery",
      //   path: "",
      //   id: "gallery",
      //   iconName: "gallery"
      // },
      {
        name: "Experiences",
        path: routes.guideTripsPath(),
        id: "trips",
        iconName: "experiences"
      }
      // {
      //   name: "Notifications",
      //   path: "",
      //   id: "notifications",
      //   iconName: "notification"
      // }
    ]
  },
  {
    groupName: "account",
    id: "account",
    items: [
      {
        name: "Settings",
        path: routes.proSettingsRootPath(),
        id: "settings",
        iconName: "settings"
      },
      {
        name: "Guide Preview",
        path: (params) => routes.publicGuidePath(params),
        id: "preview",
        iconName: "Play",
        iconType: "feather"
      }
      // {
      //   name: "QR Codes",
      //   path: "",
      //   id: "qr-codes",
      //   iconName: "qr"
      // }
    ]
  },
  {
    groupName: "help",
    id: "help",
    items: [
      {
        name: "FAQs",
        path: routes.guideFaqPath(),
        id: "faqs",
        iconName: "faq"
      },
      {
        name: "Contact Support",
        path: routes.contactSupportPath(),
        id: "contact-support",
        iconName: "support"
      }
    ]
  }
]
