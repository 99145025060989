import { createAction, createReducer } from "@reduxjs/toolkit"

import { LOADING_INITIAL_STATE } from "modules/loader-watchers/helpers/loading"

// ACTIONS
export const setProHomeMenuItemActive = createAction("proHomeMenu/setMenuItemActive")
export const setProHomeMenuOpen = createAction("proHomeMenu/setMenuOpen")
export const setProHomeMenuClose = createAction("proHomeMenu/setMenuClose")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  activeMenu: null,
  open: false
}

const proHomeMenuReducer = createReducer(initialState, {
  [setProHomeMenuItemActive.type]: (state, action) => {
    state.activeMenu = action.payload
  },
  [setProHomeMenuOpen.type]: (state) => {
    state.open = true
  },
  [setProHomeMenuClose.type]: (state) => {
    state.open = false
  }
})

export default proHomeMenuReducer
