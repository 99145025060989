import React from "react"

export const Play = ({ height = 17, width = 16, fill = "#ffffff", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.33398 2.5L12.6673 8.5L3.33398 14.5V2.5Z"
      fill={fill}
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
