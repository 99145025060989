import React from "react"
import { Col, Row } from "antd"
import { Check } from "react-feather"

// ui
import { Text } from "ui/Typography"
import { useDispatch } from "react-redux"
import { disconnectOAuthProvider } from "store/user"
import { notification } from "ui/Notification"

export const LinkedAccount = ({ icon, account, provider, connected = false, connectOAuth }) => {
  const dispatch = useDispatch()

  const onClickHandler = () => {
    if (connected) {
      dispatch(disconnectOAuthProvider(provider)).then((res) => {
        notification.success({
          message: res.message
        })
      })
    } else {
      connectOAuth()
    }
  }

  return (
    <Row gutter={[12, 12]} wrap={false} align="middle" className={"px-m py-s bordered rounded-s clickable"} onClick={onClickHandler}>
      <Col>
        <img src={icon} width={40} alt={account} className="object-contain h-auto" />
      </Col>
      <Col className="flex-column mr-xl" style={{ minWidth: 140 }}>
        <Text>{account}</Text>
        <Text fw={500} className="text-color-primary">
          {connected ? "Disconnect" : "Connect"}
        </Text>
      </Col>
      {connected && <Check size={16} className="text-color-primary flex-none" />}
    </Row>
  )
}
