import React from "react"
import { Form, Input, Button } from "antd"
import { useSelector } from "react-redux"
// import { Link, useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Title, Text } from "ui/Typography"

export const NewPassword = () => {
  const { t } = useTranslation()
  // const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.auth)
  // const navigate = useNavigate()
  // const location = useLocation()

  // const from = location.state?.from?.pathname || "/"
  // const loggedInHandler = () => navigate(from, { replace: true })

  const onFinish = () => {
    // Patch user with new password
  }

  return (
    <>
      <Title level={2} className="justify-center mb-3xl">
        {t("global.welcome")}!
      </Title>
      <Text type="body" className="justify-center mb-3xl">
        {t("new_password.info")}
      </Text>
      <Form name="newPassword" layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={t("sign_in.password")}
          name="password"
          required={false}
          rules={[
            { required: true, message: t("form_validation.password_required") },
            { min: 8, message: t("form_validation.password_length") }
          ]}
        >
          <Input.Password placeholder={t("new_password.characters_length")} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block size="large" disabled={loading}>
            {t("new_password.save_password")}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
