import { numberToCurrency } from "helpers/string"
import PropTypes from "prop-types"
import pluralize from "pluralize"
import React, { memo } from "react"

import { useTranslation } from "react-i18next"

const TotalPrice = ({ tripTitle, calculation, className }) => {
  const { t } = useTranslation()

  const classes = ["hstack gap-10 justify-content-between align-items-start"]

  if (!calculation.pricingType) return null

  return (
    <div className={className}>
      <div className={classes.join(" ")}>
        <div>
          <p className="mt-1 mb-0">{tripTitle}</p>
          <p className="fs-7 lh-1 text-gray-light m-0">
            <small>
              {t(`booking.calculation.total.${calculation.pricingType}`, {
                minimumPrice: numberToCurrency(calculation.minimumRate),
                minimumPersons: pluralize("person", calculation.minimumPersons, true),
                pricePerPerson: numberToCurrency(calculation.pricePerPerson)
              })}
            </small>
          </p>
        </div>
        <div>
          <p className="lh-1 mb-0">{numberToCurrency(calculation.subtotal)}</p>
          <p className="fs-7 lh-1 text-gray-light text-end m-0">{/* <small>incl. tax</small> */}</p>
        </div>
      </div>
      <div className="justify-between align-center">
        <p className="mt-1 mb-0">{t("booking.calculation.fees")}</p>
        <p className="mb-0">{numberToCurrency(calculation.taxesAndFees)}</p>
      </div>
      <br />
      <div className="justify-between align-center">
        <p className="h3 fw-bold lh-1 mb-0">{t("booking.calculation.total_label")}</p>
        <p className="h3 fw-bold lh-1 mb-0">{numberToCurrency(calculation.totalPrice)}</p>
      </div>
    </div>
  )
}

TotalPrice.propTypes = {
  calculation: PropTypes.shape({
    pricingType: PropTypes.string.isRequired,
    minimumRate: PropTypes.number.isRequired,
    minimumPersons: PropTypes.number.isRequired,
    pricePerPerson: PropTypes.number.isRequired,
    totalPrice: PropTypes.number.isRequired
  }).isRequired,
  className: PropTypes.string,
  tripTitle: PropTypes.string
}

export default memo(TotalPrice)
